import axios from "axios"
import { decryptString } from "../app.utils";

export const getAllJudgmentsByJudgeAndCourt = async (params: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_NEST_URL}/judge-search/case/all?judge=${params.judge}&court=${params.court}&sort=desc&pageNo=${params.pageNo}&pageSize=${params.pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getSingleBenchJudgments = async (params: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_NEST_URL}/judge-search/case/stand-alone?judge=${params.judge}&court=${params.court}&sort=desc&pageNo=${params.pageNo}&pageSize=${params.pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}