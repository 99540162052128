// React Import
import React, { useEffect, useState } from "react";

// Material import

// contants imports

// Component imports
import axios from "axios";
import { toast } from "react-toastify";
import { Divider } from "@mui/material";
import { SubscriptionCard } from "../../components";

const Plans = () => {
  const [packages, setPackages] = useState<any>([]);

  const getPackages = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_NEST_URL}/users/public-plans`,
      )
      .then((response: any) => {
        if (response.data) {
          setPackages(response.data);
        }
      })
      .catch((error) => {
        toast.error('Failed to load plans.');
      });
  }

  useEffect(() => {
    getPackages();
  }, []);

  return (
    <div className="flex flex-col gap-4 rounded-container">
      <p className="text-2xl font-bold">Subscriptions</p>
      <Divider />
      <div className="flex flex-col justify-center items-center text-center gap-4 pt-5">
        <p className="text-2xl">Upgrade your package today and unlock the clarity and support you deserve.</p>
      </div>
      <div className="flex flex-wrap gap-4 justify-center py-5">
        {packages && packages.map((plan: any) => (
          <SubscriptionCard key={plan.name} plan={plan} />
        ))}
      </div>
    </div>
  );
};

export default Plans;
