import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { formatDateToMonthDayYear } from "../../app.utils";
import { toast } from "react-toastify";
import { fetchBlogById } from "../../services/blogs.service";
import { RemoveRedEye } from "@mui/icons-material";
import { useScrollToTop } from "../../app.hooks";
import { BackButton, SkeletonCardLoading } from "../../components";
import { Divider } from "@mui/material";

const LegalAwarenessNews = () => {
  const location = useLocation();
  useScrollToTop();
  const searchParams = new URLSearchParams(location.search);
  const blogId = searchParams.get('blogId') || '';

  const [isLoading, setIsLoading] = useState(false);
  const [blog, setBlog] = useState<any>([]);

  useEffect(() => {
    if (blogId !== '') {
      setIsLoading(true);
      fetchBlogById(blogId).then((res) => {
        setBlog(res.data);
        setIsLoading(false);
      }).catch((err) => {
        toast.error('Failed to fetch blog.');
        setIsLoading(false);
      });
    }
  }, [blogId]);

  return (
    <div className="rounded-container flex flex-col flex-1 p-4 gap-4 bg-white shadow-md rounded-lg">
      <div className='flex flex-col gap-2'>
        <div className='flex flex-row justify-start items-start'>
          <BackButton />
        </div>
        <div className="flex flex-row gap-4 justify-between items-end">
          <p className='text-2xl font-bold'>{blog.title}</p>
          <p className="min-w-[12.3rem]"><b>Posted On:</b> {formatDateToMonthDayYear(blog.createdAt)}</p>
        </div>
        <Divider />
      </div>
      {isLoading || !blog ? (
        <div className="flex flex-1 justify-center items-center">
          <SkeletonCardLoading rows={1} height={500} />
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <div className="flex flex-row justify-between items-start">
            <img
              src={blog.thumbnail}
              alt="Uploaded"
              className="w-full h-auto max-w-2xl rounded-lg"
            />
            <div className="flex items-center gap-2 text-primary">
              <RemoveRedEye />
              <p className="text-lg">{blog.view_count}</p>
            </div>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: blog.blog_content }}
            className="text-justify"
          ></div>
        </div>
      )}
    </div>
  );
};

export default LegalAwarenessNews;
