import axios from "axios";
import { decryptString } from "../app.utils";

export const createCourt = async (requestBody: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_NEST_URL}/courts/create`,
      requestBody, {
      headers: {
        Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`,
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status === 403) {
      throw (new Error('Unauthorized Access'));
    } else {
      throw (new Error('Failed to save court'));
    }
  }
}