import React from "react";
import { useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { Button, Divider, FormControl, MenuItem, Select, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { PreviewBlog } from "../../dialogs";
import { useLoader, useDialog } from "../../providers";
import { createBlog } from "../../services/blogs.service";
import { constants } from "../../app.constants";
import { BackButton, ImageUpload } from "../../components";

const AddLegalNews = () => {
  const { showLoader, hideLoader } = useLoader();
  const { openDialog, closeDialog } = useDialog();

  const editor: any = Editor;
  const [blogData, setBlogData] = useState('');
  const [blogThumbnail, setBase64Image] = useState<string>('');

  const [formErrors, setFormErrors] = useState<any>({});
  const [touchedFields, setTouchedFields] = useState<any>({});

  const [formValue, setFormValues] = useState({
    blogTitle: '',
    blogCategory: constants.blogCategories[0].id,
  });

  const handleBlogTextChange = (html: any) => {
    setBlogData(html);
  };

  const handleImageUpload = (base64: string) => {
    setBase64Image(base64);
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    let newErrors: any = {};
    let updatedFormValue = { ...formValue, [name]: value };
    switch (name) {
      case 'blogTitle':
        if (!value) {
          newErrors.blogTitle = 'Appeal is required';
        } else {
          delete formErrors.blogTitle;
        }
        break;
      default:
        break;
    }
    setFormValues(updatedFormValue);
    setFormErrors((prevErrors: any) => {
      return { ...prevErrors, ...newErrors };
    });
  };

  const handleFieldBlur = (event: any) => {
    const { name } = event.target;
    setTouchedFields((prevTouched: any) => {
      return { ...prevTouched, [name]: true };
    });
  };

  const handlePreviewBlog = (blog: any) => {
    openDialog(PreviewBlog, {
      onClose: () => {
        closeDialog();
      },
      blogTitle: formValue.blogTitle,
      blogContent: blogData,
      blogThumb: blogThumbnail,
    });
  }

  const isFormInvalid = () => {
    return formValue.blogTitle === '' || blogThumbnail === '' || blogData === '';
  }

  const uploadBlog = async () => {
    showLoader();
    createBlog({ title: formValue.blogTitle, blog_content: blogData, thumbnail: blogThumbnail, category: formValue.blogCategory }).then((res) => {
      toast.success('Blog Created Successfully.');
      setBlogData('');
      hideLoader();
    }).catch((error) => {
      toast.error('Failed to save blog.');
      hideLoader();
    });
  }

  return (
    <div className="flex flex-col gap-6 rounded-container">
      <div className="flex flex-col gap-2">
        <div className='flex flex-row justify-start items-start'>
          <BackButton />
        </div>
        <p className="text-3xl font-bold">Upload Legal News</p>
        <Divider />
      </div>
      <div className="flex flex-col gap-3 w-full">
        <p className="text-xl font-bold pt-1">Title</p>
        <FormControl fullWidth>
          <TextField
            size='small'
            placeholder='Enter Title'
            variant='outlined'
            name='blogTitle'
            type={'text'}
            required
            onChange={handleInputChange}
            value={formValue.blogTitle}
            onBlur={handleFieldBlur}
            error={!!(formErrors.blogTitle && touchedFields.blogTitle)}
            helperText={touchedFields.blogTitle ? formErrors.blogTitle : ""}
          />
        </FormControl>
      </div>
      <div className="flex flex-col gap-3 w-full">
        <p className="text-xl font-bold pt-1">Thumbnail</p>
        <ImageUpload onImageUpload={handleImageUpload} />
      </div>
      <div className="flex flex-col gap-3 w-full">
        <p className="text-xl font-bold pt-1">Category</p>
        <FormControl fullWidth size='small'>
          <Select
            variant='outlined'
            value={formValue.blogCategory}
            name='blogCategory'
            required
            onChange={handleInputChange}
            onBlur={handleFieldBlur}
            error={!!(formErrors.blogCategory && touchedFields.blogCategory)}
          >
            {constants.blogCategories.map((category) => (
              <MenuItem key={category.id} value={category.id}>{category.title}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="flex flex-col gap-3">
        <div className="flex flex-row justify-between items-end">
          <p className="text-xl font-bold">Legal News Content</p>
          <Button
            type="submit"
            variant="outlined"
            disabled={!formValue.blogTitle || !blogData}
            onClick={() => handlePreviewBlog({ title: formValue.blogTitle, blogData })}
          >
            Preview Blog
          </Button>
        </div>
        <CKEditor
          editor={editor}
          data={blogData}
          onChange={(event: any, editor: any) => {
            if (editor) {
              const data = editor.getData();
              handleBlogTextChange(data);
            }
          }}
        />
      </div>
      {/* Submit Button */}
      <Button
        type="submit"
        className="max-w-max"
        disabled={isFormInvalid()}
        variant="contained"
        color='primary'
        onClick={() => uploadBlog()}
      >
        Save
      </Button>

    </div>
  );
};

export default AddLegalNews;