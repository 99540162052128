import React, { useEffect, useState } from "react";
import {
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { isAdmin, isDataOperator, logout } from "../../services/auth.service";
import { TabList } from "../../components";
import { Bookmarks, ForbiddenPrompt, SavedSearches } from "../../containers";

const Home = () => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState('bookmark');
  const [searchTerm, setSearchTerm] = useState('');
  const [openForbiddenPrompt, setOpenForbiddenPrompt] = useState(false);

  const tabs = [
    { id: 'bookmark', label: 'Bookmarks' },
    { id: 'savedSearches', label: 'Saved Searches' },
  ];

  const handleTabChange = (tabId: string) => {
    setSelectedTab(tabId);
  };

  const showSection = () => {
    switch (selectedTab) {
      case 'bookmark':
        return <Bookmarks />;
      case 'savedSearches':
        return <SavedSearches />;
    }
  }

  return (
    <div className="flex flex-col w-full gap-4 h-full">
      <div className="flex flex-col gap-4 rounded-container">
        <p className="text-2xl font-bold">Search Judgments</p>
        <form className="flex flex-row gap-3 text-lg font-bold" onSubmit={
          (e: any) => {
            e.preventDefault();
            navigate('/home/search-result?searchTerm=' + searchTerm);
          }}>
          <TextField
            fullWidth
            size="small"
            type="text"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={searchTerm === '' ? 'Select atleast one filter.' : ''}>
                    <Search color="primary" className="cursor-pointer" onClick={(e: any) => {
                      if (searchTerm !== '') {
                        e.preventDefault();
                        navigate('/home/search-result?searchTerm=' + searchTerm);
                      }
                    }} />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            placeholder="Enter keywords or phrases. For exact matches, please use quotes."
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </form>
      </div>
      <TabList tabs={tabs} activeTab={selectedTab} onTabChange={handleTabChange} />
      {showSection()}
      {openForbiddenPrompt && (<ForbiddenPrompt open={openForbiddenPrompt} showLogout={true} onClose={() => { setOpenForbiddenPrompt(false); logout() }} />)}
    </div>
  );
};

export default Home;