// Router import
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

// Container import
import DataManagement from '../views/secure/data-management';
import Statutes from '../views/secure/statutes';
import UsersManagement from '../views/secure/users-management';
import LegalNewsManagement from '../views/secure/legal-news-management';
import AddJudgment from '../views/secure/add-judgment';
import AddLegalNews from '../views/secure/add-legal-news';
import AddStatute from '../views/secure/add-statute';
import JudgmentsByCourt from '../views/secure/courts';
import EditJudgment from '../views/secure/edit-judgment';
import EditLegalNews from '../views/secure/edit-legal-news';
import JudgmentsByJudge from '../views/secure/judges';
import Judgment from '../views/secure/judgment';
import SearchResult from '../views/secure/search-result';
import Home from '../views/secure/home';
import Cases from '../views/secure/cases';
import Departments from '../views/secure/departments';
import Plans from '../views/secure/plans';
import Dashboard from '../views/secure/dashboard';
import AddBanner from '../views/secure/add-banner';
import BannerManagement from '../views/secure/banner-management';
import EditBanner from '../views/secure/edit-banner';
import LegalAwarenessNewsList from '../views/secure/legal-news-list';
import LegalAwarenessNewsByCategory from '../views/secure/legal-news-by-category';
import LegalAwarenessNews from '../views/secure/legal-news';
import AddCourt from '../views/secure/add-court';
import EditStatute from '../views/secure/edit-statute';

const ProtectedRoutes = () => {
  return (
    <Routes>
      <Route path='/home' element={<Home />} />
      <Route path='/cases' element={<Cases />} />
      <Route path='/departments' element={<Departments />} />
      <Route path='/judges' element={<JudgmentsByJudge />} />
      <Route path='/courts' element={<JudgmentsByCourt />} />
      <Route path='/statutes' element={<Statutes />} />
      <Route path='/plans' element={<Plans />} />
      <Route path='/add/judgment' element={<AddJudgment />} />
      <Route path='/edit/judgment' element={<EditJudgment />} />
      <Route path='/home/search-result' element={<SearchResult />} />
      <Route path='/cases/judgment' element={<Judgment />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/manage/judgments' element={<DataManagement />} />
      <Route path='/manage/users' element={<UsersManagement />} />
      <Route path='/manage/judgments/add' element={<AddJudgment />} />
      <Route path='/manage/courts/add' element={<AddCourt />} />
      <Route path='/manage/statutes/add' element={<AddStatute />} />
      <Route path='/manage/statutes/edit' element={<EditStatute />} />
      <Route path='/manage/legal-news' element={<LegalNewsManagement />} />
      <Route path='/manage/legal-news/add' element={<AddLegalNews />} />
      <Route path='/manage/legal-news/edit' element={<EditLegalNews />} />
      <Route path='/manage/banners' element={<BannerManagement />} />
      <Route path='/manage/banners/add' element={<AddBanner />} />
      <Route path='/manage/banners/edit' element={<EditBanner />} />
      <Route path='/legal-news/list' element={<LegalAwarenessNewsList />} />
      <Route path='/legal-news/category' element={<LegalAwarenessNewsByCategory />} />
      <Route path='/legal-news/view' element={<LegalAwarenessNews />} />
      <Route path='*' element={<Navigate to='/home' />} />
    </Routes>
  );
};

export default ProtectedRoutes;