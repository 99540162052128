// Router import
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

// views imports
import { Auth } from '../views/public/auth';
import PrivacyPolicy from '../views/public/privacy-policy';
import CorporatePricingPolicy from '../views/public/corporate-pricing-policy';
import TermsOfUse from '../views/public/terms-of-use';
import LegalAwarenessNewsList from '../views/public/legal-news-list';
import LegalAwarenessNews from '../views/public/legal-news';
import LegalAwarenessNewsByCategory from '../views/public/legal-news-by-category';
import LandingScreen from '../views/public/landing';
import EmailVerification from '../views/public/email-verification';
import PasswordUpdate from '../views/public/update-password';
import OrganizationSignup from '../views/public/organization-signup';

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<LandingScreen />} />
      <Route path='/auth' element={<Auth />} />
      <Route path='/corporate-pricing-policy' element={<CorporatePricingPolicy />} />
      <Route path='/terms-of-use' element={<TermsOfUse />} />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      <Route path='/legal-news/list' element={<LegalAwarenessNewsList />} />
      <Route path='/legal-news/category' element={<LegalAwarenessNewsByCategory />} />
      <Route path='/legal-news/view' element={<LegalAwarenessNews />} />
      <Route path='/users/verify/:id' element={<EmailVerification />} />
      <Route path='/users/update-password/:id' element={<PasswordUpdate />} />
      <Route path='/:organizationIdParam/signup' element={<OrganizationSignup />} />
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};

export default PublicRoutes;