import React from 'react';
import { RadioButtonChecked } from '@mui/icons-material';
import { Button } from '@mui/material';
import { BlogCard } from '.';

interface BlogSectionProps {
  blogs: any[]; // Define a more specific type based on your blog data structure
  onPreview: (id: string) => void;
  title?: string;
  onSeeMore?: () => void;
  seeMoreAfter?: number;
  showAll?: boolean;
}

export const BlogSection: React.FC<BlogSectionProps> = ({
  blogs,
  onPreview,
  onSeeMore,
  title,
  seeMoreAfter = 4,
  showAll = false,
}) => (
  <div>
    {blogs.length > 0 && (
      <div className="flex flex-col gap-4">
        {title && (
          <div className="flex flex-row gap-2 items-center pt-5">
            <RadioButtonChecked fontSize="medium" />
            <p className="text-xl lg:text-3xl font-bold">{title}</p>
          </div>
        )}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 justify-center items-center">
          {(showAll ? blogs : blogs.slice(0, seeMoreAfter)).map((blog) => (
            <BlogCard key={blog.id} blog={blog} onPreview={onPreview} />
          ))}
        </div>
        {!showAll && blogs.length > seeMoreAfter && (
          <div className="flex justify-start w-full pb-10 pt-5">
            <Button
              className='w-full'
              variant='outlined'
              onClick={onSeeMore}
            >
              See More
            </Button>
          </div>
        )}
      </div>
    )}
  </div>
);
