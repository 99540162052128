import CryptoJS from 'crypto-js';
import { logout } from './services/auth.service';
import { toast } from 'react-toastify';

/**
 * Check if provided string arrays are equal to each other or not
 * @param arr1
 * @param arr2
 * @returns {boolean}
 */
export const areStringArraysEqual = (arr1: string[], arr2: string[]): boolean => {
  if (!arr1 || !arr2) {
    return false;
  }

  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

export const isAuthorised = () => {
  const userType = decryptString(localStorage.getItem('role'));
  if (userType === 'free' || (userType === 'organization' && localStorage.getItem('outOfOrganization') === 'true')) {
    return false;
  } else if (userType === 'organization' || userType === 'premium' || userType === 'admin' || userType === 'data-operator' || userType === 'user-manager') {
    return true;
  } else {
    logout();
  }
}

export const isAdmin = () => {
  const userType = decryptString(localStorage.getItem('role'));
  if (userType === 'admin') {
    return true;
  }
  return false;
}

export const isDataOperator = () => {
  const userType = decryptString(localStorage.getItem('role'));
  if (userType === 'data-operator') {
    return true;
  }
  return false;
}

export const isUserManager = () => {
  const userType = decryptString(localStorage.getItem('role'));
  if (userType === 'user-manager') {
    return true;
  }
  return false;
}

export const isOrgUser = () => {
  const userType = decryptString(localStorage.getItem('role'));
  if (userType === 'organization') {
    return true;
  }
  return false;
}

const encryptionKey = process.env.ENCRYPTION_KEY || 'eastLawPk';

export const encryptString = (str: any) => {
  if (!str) {
    return '';
  }
  const ciphertext = CryptoJS.AES.encrypt(str, encryptionKey);
  return ciphertext.toString();
};

export const decryptString = (ciphertextStr: any) => {
  if (!ciphertextStr) {
    return '';
  }
  const bytes = CryptoJS.AES.decrypt(ciphertextStr, encryptionKey);
  const plaintext = bytes.toString(CryptoJS.enc.Utf8);
  return plaintext;
};

export const encryptHMACString = (str: any) => {
  const salt = `${process.env.REACT_APP_API_NEST_URL}`;
  if (!str) {
    return '';
  }
  const ciphertext = CryptoJS.HmacSHA256(str, salt);
  return ciphertext.toString();
};

export const concatenateStrings = (strings: string[]): string => {
  if (strings && strings.length > 0) {
    return strings.join(', ');
  } else {
    return '';
  }
}

export const attachCacheBustingQueryParam = (url: string): string => {
  const separator = url.includes('?') ? '&' : '?';
  const cacheBustingQueryParam = `_=${new Date().getTime()}`;
  return `${url}${separator}${cacheBustingQueryParam}`;
}

export const changeDateToYYYYMMDD = (inputDate: string): string => {
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const formattedDay = day < 10 ? "0" + day : day.toString();
  const formattedMonth = month < 10 ? "0" + month : month.toString();
  const formattedYear = year.toString();

  return `${formattedDay}/${formattedMonth}/${formattedYear}`;
}

export const formatDate = (dateString: string | null | undefined): string => {
  if (!dateString) {
    return ''; // Return empty string
  }
  if (dateString && dateString === '') {
    return ''; // Return empty string
  }

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export const containerHeight = (heightToBeSubtracted: number) => {
  let height = heightToBeSubtracted;
  if (!isAuthorised()) {
    height = heightToBeSubtracted + 3;
  }
  return `h-[calc(100vh-${height}rem)]`
}

export const formatDateToMonthDayYear = (dateStr: string): string => {
  // Parse the input date string (assuming the date is in UTC)
  const dateObj = new Date(dateStr);

  // Define an array for month names
  const monthsOfYear = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
  ];

  // Extract the month, day, and year
  const month = monthsOfYear[dateObj.getUTCMonth()];
  const day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();

  // Format the date into the desired string format
  const formattedDate = `${month} ${day}, ${year}`;

  return formattedDate;
}

/**
 * Comon function to download file
 * @param {Blob} file
 * @param {string} fileName
 * USAGE: downloadFile(pdfData, 'file-name');
 */
export const downloadFile = (file: Blob, fileName: string) => {
  try {
    const url = window.URL.createObjectURL(file);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    toast.success(`File ${fileName} downloaded Successfully.`);
  } catch (err) {
    // toast.error('Failed to download file.')
    console.log(err);
  }
};

export const calculateDaysLeft = (dateString: string): string => {
  const targetDate = new Date(dateString);
  const currentDate = new Date();

  // Reset time to ensure only date comparison
  targetDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  const diffInTime = targetDate.getTime() - currentDate.getTime();
  const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24));

  if (diffInDays > 0) {
    return `${diffInDays} days left`;
  } else if (diffInDays === 0) {
    return 'Expiring today';
  } else {
    return 'Already expired';
  }
}