import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Divider, FormControl, FormControlLabel, MenuItem, Select, Switch, TextField, ToggleButton } from "@mui/material";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useLoader, useDialog } from "../../providers";
import { constants } from "../../app.constants";
import { fetchBannerById, updateBanner } from "../../services/banners.service";
import { BackButton } from "../../components";

const EditBanner = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const bannerId = searchParams.get('bannerId') || '';

  const { loading, showLoader, hideLoader } = useLoader();

  const [banner, setBanner] = useState<any>(null);

  const [formErrors, setFormErrors] = useState<any>({});
  const [touchedFields, setTouchedFields] = useState<any>({});

  const [formValue, setFormValues] = useState({
    bannerTitle: '',
    bannerType: constants.bannerTypes[0].id,
    bannerStatus: false,
  });

  useEffect(() => {
    reloadBanner();
  }, []);

  const reloadBanner = () => {
    if (bannerId !== '') {
      showLoader();
      fetchBannerById(bannerId, true).then((res) => {
        setBanner(res.data);
        setFormValues({
          bannerTitle: res.data.title,
          bannerType: res.data.type,
          bannerStatus: res.data.isActive,
        });
        hideLoader();
      }).catch((err) => {
        toast.error('Failed to fetch banner.');
        hideLoader();
      })
    }
  }

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    let newErrors: any = {};
    let updatedFormValue = { ...formValue, [name]: value };
    switch (name) {
      case 'bannerTitle':
        if (!value) {
          newErrors.bannerTitle = 'Appeal is required';
        } else {
          delete formErrors.bannerTitle;
        }
        break;
      default:
        break;
    }
    setFormValues(updatedFormValue);
    setFormErrors((prevErrors: any) => {
      return { ...prevErrors, ...newErrors };
    });
  };

  const handleFieldBlur = (event: any) => {
    const { name } = event.target;
    setTouchedFields((prevTouched: any) => {
      return { ...prevTouched, [name]: true };
    });
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormValues({ ...formValue, [name]: checked });
  };

  const editBanner = async () => {
    showLoader();
    updateBanner(bannerId, { title: formValue.bannerTitle, description: formValue.bannerTitle, type: formValue.bannerType, isActive: formValue.bannerStatus }).then((res) => {
      hideLoader();
      reloadBanner();
      if (res.success) {
        toast.success('Banner Updated Successfully.');
      } else {
        toast.error(res.message);
      }
    }).catch((error) => {
      toast.error('Failed to save banner.');
      hideLoader();
    });
  }

  return (
    <>
      {!loading && banner && (
        <div className="flex flex-col gap-6 flex-1 rounded-container">
          <div className="flex flex-col gap-2">
            <div className='flex flex-row justify-start items-start'>
              <BackButton />
            </div>
            <p className="text-3xl font-bold">Edit Banner</p>
            <Divider />
          </div>
          <div className="flex flex-col gap-3 w-full">
            <p className="text-xl font-bold pt-1">Title</p>
            <FormControl fullWidth>
              <TextField
                size='small'
                placeholder='Enter Title'
                variant='outlined'
                name='bannerTitle'
                type={'text'}
                required
                onChange={handleInputChange}
                value={formValue.bannerTitle}
                onBlur={handleFieldBlur}
                error={!!(formErrors.bannerTitle && touchedFields.bannerTitle)}
                helperText={touchedFields.bannerTitle ? formErrors.bannerTitle : ""}
              />
            </FormControl>
          </div>
          <div className="flex flex-col gap-3 w-full">
            <p className="text-xl font-bold pt-1">Type</p>
            <FormControl fullWidth size='small'>
              <Select
                variant='outlined'
                value={formValue.bannerType}
                name='bannerType'
                required
                onChange={handleInputChange}
                onBlur={handleFieldBlur}
                error={!!(formErrors.bannerType && touchedFields.bannerType)}
              >
                {constants.bannerTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>{type.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="flex flex-col gap-3 w-full">
            <p className="text-xl font-bold pt-1">Status</p>
            <FormControlLabel
              control={
                <Switch checked={formValue.bannerStatus} onChange={handleStatusChange} name='bannerStatus' />
              }
              label={formValue.bannerStatus ? 'Active' : 'In Active'}
            />
          </div>
          <div className="flex flex-col gap-3 w-full">
            <p className="text-xl font-bold pt-1">Date Posted</p>
            <FormControl fullWidth>
              <TextField
                size='small'
                value={banner.createdAt.split('T')[0]}
                disabled
              />
            </FormControl>
          </div>

          {/* Submit Button */}
          <Button
            type="submit"
            className="max-w-max"
            disabled={banner.title === formValue.bannerTitle && banner.isActive === formValue.bannerStatus && banner.type === formValue.bannerType}
            variant="contained"
            color='primary'
            onClick={() => editBanner()}
          >
            Save Changes
          </Button>
        </div>
      )}
    </>
  );
};

export default EditBanner;