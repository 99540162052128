import React from "react";

const TermsOfUse = () => {
  return (
    <div className="flex flex-col py-[5rem] lg:px-[15rem] px-[5rem] w-full h-full bg-white text-lg">
      <div className="flex flex-col items-center w-full mt-[1rem] mb-[3rem]">
        <p className="text-6xl font-bold">Terms of Use</p>
        <p className="mt-2">Last Updated: [1st July 2023]</p>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1"><span className="text-2xl font-bold my-3">1. Acceptance of Terms</span>
          <p className="pl-[1.5rem]">Welcome to <a className='font-bold text-primary cursor-pointer hover:underline' onClick={()=>{window.open('/')}}>eastlaw.pk</a> ("the Platform"). These Terms of Use ("Terms") govern your use of the Platform. By accessing or using the Platform, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, please do not use the Platform.</p>
        </div>
        <p className="text-2xl font-bold my-3">2. Use of the Platform</p>
        <div className="flex flex-col gap-1"><span className="text-xl font-bold">2.1. Permitted Use:</span>
          <p className="pl-[2.5rem]">You are granted a limited, non-exclusive, and non-transferable license to access and use the Platform for legal research and related purposes.</p>
        </div>
        <div className="flex flex-col gap-1"><span className="text-xl font-bold">2.2. Compliance: </span>
          <p className="pl-[2.5rem]">You agree to use the Platform in compliance with all applicable laws and regulations.</p>
        </div>
        <div className="flex flex-col gap-1"><span className="text-xl font-bold">2.3. User Accounts: </span>
          <p className="pl-[2.5rem]">If you create an account on the Platform, you are responsible for maintaining the confidentiality of your account credentials and for any activities that occur under your account.</p>
        </div>
        <p className="text-2xl font-bold my-3">3. Subscription and Payment</p>
        <div className="flex flex-col gap-1"><span className="text-xl font-bold">3.1. Subscription Plans:</span>
          <p className="pl-[2.5rem]">Users may subscribe to the Platform based on available subscription plans, subject to the terms and pricing provided.</p>
        </div>
        <div className="flex flex-col gap-1"><span className="text-xl font-bold">3.2. Payment:</span>
          <p className="pl-[2.5rem]">You agree to pay all applicable fees associated with your chosen subscription plan. Payment methods, including but not limited to bank transfer and the Jazz Cash-based card payment system, may be used to process payments.</p>
        </div>
        <div className="flex flex-col gap-1"><span className="text-xl font-bold">3.3. Automatic Renewal:</span>
          <p className="pl-[2.5rem]">Subscriptions may be subject to automatic renewal upon subscription payment and unless canceled by the user.</p>
        </div>
        <p className="text-2xl font-bold my-3">4. Intellectual Property</p>
        <div className="flex flex-col gap-1"><span className="text-xl font-bold">4.1. Ownership:</span>
          <p className="pl-[2.5rem]">All content on the Platform, including legal texts, case laws, and data, is the exclusive property of <a className='font-bold text-primary cursor-pointer hover:underline' onClick={()=>{window.open('/')}}>eastlaw.pk</a> and is protected by copyright and other intellectual property laws of Pakistan.</p>
        </div>
        <div className="flex flex-col gap-1"><span className="text-xl font-bold">4.2. User Contributions:</span>
          <p className="pl-[2.5rem]">Users may contribute content to the Platform, granting <a className='font-bold text-primary cursor-pointer hover:underline' onClick={()=>{window.open('/')}}>eastlaw.pk</a> a non-exclusive license to use, modify, and distribute such content.</p>
        </div>
        <p className="text-2xl font-bold my-3">5. Privacy and Data Security</p>
        <div className="flex flex-col gap-1"><span className="text-xl font-bold">5.1. Privacy Policy:</span>
          <p className="pl-[2.5rem]">Our Privacy Policy governs the collection, use, and protection of personal information. By using the Platform, you consent to the practices outlined in our Privacy Policy.</p>
        </div>
        <div className="flex flex-col gap-1"><span className="text-xl font-bold">5.2. Security Measures:</span>
          <p className="pl-[2.5rem]">We employ industry-standard security measures to safeguard data, but users are responsible for protecting their login credentials.</p>
        </div>
        <p className="text-2xl font-bold my-3">6. Termination</p>
        <div className="flex flex-col gap-1"><span className="text-xl font-bold">6.1. User Termination (Paid Users):</span>
          <p className="pl-[2.5rem]">Paid users may not terminate or cancel the Terms of Use before the expiry of their subscription period. Once a subscription is purchased, the subscription amount becomes non-refundable, and users are obligated to fulfill the subscription term.</p>
        </div>
        <div className="flex flex-col gap-1"><span className="text-xl font-bold">6.2. Trial Users:</span>
          <p className="pl-[2.5rem]">Trial users, who access limited features of the Platform for trial purposes, may terminate their use at any time without any obligation. You may write to info@eastlaw.pk for termination.</p>
        </div>
        <div className="flex flex-col gap-1"><span className="text-xl font-bold">6.3. Platform Termination:</span>
          <p className="pl-[2.5rem]"><a className='font-bold text-primary cursor-pointer hover:underline' onClick={()=>{window.open('/')}}>eastlaw.pk</a> reserves the right to suspend or terminate user accounts for violations of these Terms or applicable laws.</p>
        </div>
        <p className="text-2xl font-bold my-3">7. Disclaimers and Limitations of Liability</p>
        <div className="flex flex-col gap-1"><span className="text-xl font-bold">7.1. Content Accuracy:</span>
          <p className="pl-[2.5rem]">While every effort has been made to ensure the accuracy and currency, we do not guarantee completeness, correctness, or applicability to specific cases of court judgments available on this website. The court judgments may be subject to updates, modifications, or revisions by competent authorities.
            Therefore, it is advisable to consult official sources or legal professionals for the most up-to-date and accurate information. The court judgments available on this website are not official publications of the Government of Pakistan. They may not reflect the most recent or authoritative version of the judgment.
            For official and legally binding versions, please refer to official sources, authorized publications, or certified copies of court orders. The use of the documents available on this website is at your own risk. We shall not be held liable for any loss, damage, or inconvenience arising from the use or reliance on
            the information contained in the data. We disclaim any responsibility for errors, omissions, or inaccuracies in the court judgment or any consequences resulting from its use.</p>
        </div>
        <div className="flex flex-col gap-1"><span className="text-xl font-bold">7.2. Limitation of Liability:</span>
          <p className="pl-[2.5rem]"><a className='font-bold text-primary cursor-pointer hover:underline' onClick={()=>{window.open('/')}}>eastlaw.pk</a> shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use or inability to use the Platform.</p>
        </div>
        <div className="flex flex-col gap-1"><span className="text-2xl font-bold my-3">8. Modifications and Updates</span>
          <p className="pl-[2rem]"><a className='font-bold text-primary cursor-pointer hover:underline' onClick={()=>{window.open('/')}}>eastlaw.pk</a> reserves the right to modify, update, or change these Terms at any time. Users will be notified of any material changes, and continued use of the Platform after such changes constitutes acceptance of the updated Terms.</p>
        </div>
        <div className="flex flex-col gap-1"><span className="text-2xl font-bold my-3">9. Governing Law and Dispute Resolution</span>
          <p className="pl-[2rem]">These Terms are governed by the laws of Pakistan. Any disputes or claims arising from or relating to these Terms shall be resolved through arbitration in accordance with the rules of the Arbitration Act 1940, with judgments enforceable in Pakistani courts.</p>
        </div>
        <div className="flex flex-col gap-1"><span className="text-2xl font-bold my-3">10. Contact Information:</span>
          <p className="pl-[2.5rem]">For any questions or concerns regarding these Terms or the Platform, please contact us at <a className='font-bold text-primary cursor-pointer hover:underline'>info@eastlaw.pk</a>.</p>
        </div>
        <p className="mt-4">By using the Platform, you acknowledge that you have read, understood, and agree to these comprehensive Terms of Use.</p>
      </div>
    </div>
  );
};

export default TermsOfUse;