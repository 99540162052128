import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material';
import Login from './login';
import SignUp from './signup';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { encryptString } from '../../app.utils';
import { authOrg } from '../../services/auth.service';
import { useLoader } from '../../providers';

export const Auth = () => {
	const { showLoader, hideLoader } = useLoader();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const [selectedOption, setSelectedOption] = useState(searchParams.get('current') || 'signin');
	const orgId = searchParams.get('orgId') || '';
	const navigate = useNavigate();

	const signinOrg = () => {
		showLoader();
		authOrg(orgId).then((res: any) => {
			hideLoader();
			if (res.user && res.token) {
				localStorage.setItem('isAuthenticated', encryptString('true'));
				localStorage.setItem('username', encryptString(res.user.name));
				localStorage.setItem('userId', encryptString(res.user.id));
				localStorage.setItem('role', encryptString(res.user.role));
				localStorage.setItem('token', encryptString(res.token));
				localStorage.setItem('isVerified', encryptString((res.user.emailVerified).toString()));
				if (res.user.emailVerified === true) {
					toast.success('Logged in successfully!');
					window.location.href = '/home';
				}
			}
		}).catch((err: any) => {
			hideLoader();
			navigate('/');
		});
	}

	useEffect(() => {
		if (orgId !== '') {
			signinOrg();
		}
	}, []);

	return (
		<>
			<div className='hidden lg:flex'>
				<div className='flex flex-row w-full h-[calc(100vh-6rem)]'>
					<div className='flex flex-col w-1/2 justify-center items-center h-full bg-gradient-to-t from-[#41090b] to-[#150303]'>
						{selectedOption === 'signin' ? (
							<div className='flex flex-col gap-4 justify-center items-center'>
								<p className='text-white text-3xl text-center'>Don't have an account?<br />Register your account to get started.</p>
								<Button variant='contained' color='primary'
									className='w-[10rem] mt-4' onClick={() => setSelectedOption('signup')}>Register</Button>
							</div>
						) : (
							<div className='flex flex-col gap-4 justify-center items-center'>
								<p className='text-white text-3xl text-center'>Already have an account?<br />Login to continue.</p>
								<Button variant='contained' color='primary' className='w-[10rem] mt-4' onClick={() => setSelectedOption('signin')}>Login</Button>
							</div>
						)}
					</div>
					<div className='flex flex-col w-1/2 bg-[#f3f2f2] justify-center items-center relative'>
						<div className='flex flex-col h-full justify-center w-4/5 mx-auto'>
							<div className='px-8 pt-8 pb-6 bg-white rounded-xl overflow-auto shadow-xl'>
								{selectedOption === 'signup' ? (
									<SignUp />
								) : (
									<Login />
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='flex lg:hidden bg-gradient-to-t from-[#41090b] to-[#150303] min-h-[calc(100vh-6rem)] flex-col gap-20 py-20 items-center'>
				{selectedOption === 'signin' ? (
					<div className='flex flex-col gap-4 justify-center items-center px-2'>
						<p className='text-white text-3xl text-center'>Don't have an account?<br />Register your account to get started.</p>
						<Button variant='contained' color='primary'
							className='w-[10rem] mt-4' onClick={() => setSelectedOption('signup')}>Register</Button>
					</div>
				) : (
					<div className='flex flex-col gap-4 justify-center items-center px-2'>
						<p className='text-white text-3xl text-center'>Already have an account?<br />Login to continue.</p>
						<Button variant='contained' color='primary' className='w-[10rem] mt-4' onClick={() => setSelectedOption('signin')}>Login</Button>
					</div>
				)}
				<div className='px-4 pt-4 pb-3 bg-white rounded-xl overflow-auto shadow-xl'>
					{selectedOption === 'signup' ? (
						<SignUp />
					) : (
						<Login />
					)}
				</div>
			</div>
		</>
	);
};
