import React from "react";
import { Check } from "@mui/icons-material";
import { Button } from "@mui/material";

interface PublicSubscriptionPlanCardProps {
  plan: {
    id: string;
    name: string;
    features: string[];
    type: string;
    price?: number;
    activationPeriod?: number;
  }
  action: () => void;
  actionLabel: string;
  isActionDisabled: boolean;
}

export const PublicSubscriptionCard = ({ plan, action, actionLabel, isActionDisabled }: PublicSubscriptionPlanCardProps) => {

  return (
    <div className='flex flex-col w-[23rem] mb-3'>
      <div className='flex flex-col gap-2 items-center bg-white h-[29rem] border border-slate-200 rounded-xl pt-3 shadow-lg'>
        <div className="flex flex-col gap-2 px-4 py-3 items-center w-full">
          {
            'free' === plan.type && (
              <p className="text-[2rem] font-bold py-8 gradient-text-red">
                Free Tier
              </p>
            )
          }
          {
            'other' === plan.type && (
              <>
                <p className="text-[1.5rem] font-bold gradient-text-red">
                  {plan.name}
                </p>
                <p className="text-4xl font-normal text-center tracking-tight">
                  <span className="text-lg font-light">Rs.</span> {plan.price}
                </p>
                <span className="text-sm font-light">for {plan.activationPeriod} days.</span>
              </>
            )
          }
          {
            'corporate' === plan.type && (
              <>
                <p className="text-[1.5rem] font-bold gradient-text-red">
                  {plan.name}
                </p>
                <span className="text-center">Law Firm packages, Institutional, University, Libraries, Data Centers Level Access.</span>
              </>
            )
          }
          <Button color='primary' disabled={isActionDisabled} variant='outlined' onClick={action} className='w-full'>{actionLabel}</Button>
        </div>
        <div className="w-full h-full rounded-b-xl bg-[#f6f8fa] px-6 py-4">
          <p className="mb-3 font-semibold">Includes</p>
          <ul >
            {plan.features.map((feature: any) => (
              <li key={feature} className="flex flex-row justify-start mb-3">
                <Check fontSize="small" className="mr-2 text-green-600" />
                <p className="font-normal text-sm">{feature}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};