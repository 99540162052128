import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchAllBlogsByCategory } from '../../services/blogs.service';
import { toast } from 'react-toastify';
import { useScrollToTop } from '../../app.hooks';
import { constants } from '../../app.constants';
import { BlogSection, SkeletonCardLoading } from '../../components';

const LegalAwarenessNewsByCategory: React.FC = () => {
  const location = useLocation();
  useScrollToTop();
  const searchParams = new URLSearchParams(location.search);
  const category = searchParams.get('category') || '';
  const [isLoading, setIsLoading] = useState(false);

  useScrollToTop();

  const navigate = useNavigate();
  const [blogs, setBlogs] = useState<any[]>([]);

  useEffect(() => {
    reloadBlogs();
  }, []);

  const reloadBlogs = () => {
    if (category) {
      setIsLoading(true);
      fetchAllBlogsByCategory(category, 1, 100)
        .then((res) => {
          const blogs = res.data.slice();
          setBlogs(getFilteredBlogs(blogs, category));
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error('Failed to fetch blogs.');
          setIsLoading(false);
        });
    }
  };

  const handlePreviewBlog = (blogId: string) => {
    navigate(`/legal-news/view?blogId=${blogId}`);
  };

  const getFilteredBlogs = (blogsTemp: any[], category: string) => {
    return blogsTemp.filter((blog) => blog.category === category);
  };

  function getTitleById(id: string): string {
    const category = constants.blogCategories.find(category => category.id === id);
    return category ? category.title : '';
  }

  return (
    <div className="flex flex-col bg-white w-screen min-h-screen">
      <div className="flex flex-col gap-4 w-full">
        <div className="flex flex-col gap-8 bg-gradient-to-t from-[#41090b] to-[#150303] h-96 items-center justify-center">
          <p className="text-6xl font-bold text-white">Legal Awareness</p>
        </div>
        {isLoading ? (
          <div className="px-20 pt-5">
            <SkeletonCardLoading rows={3} height={300} />
          </div>
        ) : (
          <div className="flex flex-col gap-4 p-20">
            <p className="gradient-text-red text-center text-5xl font-bold py-5">
              Checkout our latest Legal Awareness posts
            </p>
            <BlogSection
              title={getTitleById(category)}
              blogs={blogs}
              onPreview={handlePreviewBlog}
              showAll={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default LegalAwarenessNewsByCategory;
