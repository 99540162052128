import React, { useEffect, useState } from "react";
import { RemoveRedEye, Delete } from "@mui/icons-material";
import { Tooltip, TextField, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Stack, Pagination } from "@mui/material";
import { BookmarkTypes } from "../app.enums";
import { decryptString, isAuthorised } from "../app.utils";
import { SkeletonLoading } from "../components";
import { constants } from "../app.constants";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { logout } from "../services/auth.service";
import { toast } from "react-toastify";
import { useDialog } from "../providers";
import { DepartmentDocumentPreview, StatuteDocumentPreview } from "../dialogs";
import { ForbiddenPrompt } from ".";

export const Bookmarks = () => {
  const { openDialog, closeDialog } = useDialog();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [bookmarks, setBookmarks] = useState([]);
  const [bookmarkPageNo, setBookmarkPageNo] = useState(1);
  const [totalBookmarkPages, setTotalBookmarkPages] = useState(1);
  const [filteredBookmarks, setFilteredBookmarks] = useState([]);
  const [bookmarkSearchInput, setBookmarkSearchInput] = useState("");
  const [openForbiddenPrompt, setOpenForbiddenPrompt] = useState(false);

  /**
   * Function to handle bookmark page change
   * @param {Event} event
   * @param {number} value
   */
  const handleBookmarkPageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setBookmarkPageNo(value);
  };

  const searchBookmarkFilter = (searchValue: any) => {
    setBookmarkSearchInput(searchValue);
    if (bookmarkSearchInput !== "") {
      const filteredData = bookmarks.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(bookmarkSearchInput.toLowerCase());
      });
      setFilteredBookmarks(filteredData);
    }
  };

  const getBookmarks = () => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_NEST_URL}/bookmark`,
        {
          params: {
            pageNo: bookmarkPageNo,
            pageSize: constants.pageSize,
            userId: decryptString(localStorage.getItem('userId'))
          },
          headers: {
            Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
          },
        }
      )
      .then((response: any) => {
        setIsLoading(false);
        if (response.data) {
          setBookmarks(response.data?.data);
          setTotalBookmarkPages(response.data?.totalPages);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response.status === 401) {
          logout();
        }
        if (error.response.status === 403) {
          localStorage.setItem('outOfOrganization', 'true');
          setOpenForbiddenPrompt(true);
        }
      });
  };

  const openJudgment = (caseId: any) => {
    navigate('/cases/judgment?judgmentId=' + caseId);
  };

  const openStatuteDoc = (item: any) => {
    if (item.targetId !== '' && item.header !== '') {
      openDialog(StatuteDocumentPreview, {
        onClose: () => {
          closeDialog();
        },
        statute: {
          selectedStatute: item.targetId,
          selectedStatuteTitle: item.header,
          selectedStatuteAct: item.detail || '',
        }
      });
    }
  }

  const openDepartmentFile = (item: any) => {
    if (item.targetId !== '' && item.detail !== '' && item.header !== '') {
      openDialog(DepartmentDocumentPreview, {
        onClose: () => {
          closeDialog();
        },
        departmentData: {
          selectedFile: item.detail,
          selectedFileId: item.targetId,
          selectedDepartTitle: item.header,
        }
      });
    }
  }

  const deleteBookmarkHandler = async (item: any) => {
    const userId = decryptString(localStorage.getItem('userId'));
    const bookmarkId = item.id;
    console.log('Bookmark deleted successfully');
    const URL = `${process.env.REACT_APP_API_NEST_URL}/bookmark?userId=${userId}&bookmarkIds=${bookmarkId}`;
    const response = await axios.delete(URL, {
      headers: {
        Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
      },
    }).catch(err => {
      toast.error('Failed to delete bookmark.');
    });
    if (response) {
      toast.success('Bookmark removed successfully.');
      getBookmarks();
    }
  }

  useEffect(() => {
    getBookmarks();
  }, [bookmarkPageNo]);

  useEffect(() => {
    setFilteredBookmarks(bookmarks);
  }, [bookmarks]);

  return (
    <>
      <div className="flex flex-col gap-4 rounded-container">
        <div className="flex flex-row justify-between items-center pb-0">
          <p className="text-2xl font-bold">Bookmarks</p>
          <Tooltip title={!isAuthorised() ? constants.tooltips.upgradePlan : ''} placement={'left'}>
            <span>
              <TextField
                sx={{
                  minWidth: 400,
                }}
                disabled={!isAuthorised()}
                size="small"
                type="text"
                placeholder="Search here"
                onChange={(e) => searchBookmarkFilter(e.target.value)}
              />
            </span>
          </Tooltip>
        </div>
        <div className="flex flex-col justify-between h-full">
          <div className={`${!isAuthorised() ? 'h-[calc(100vh-26.2rem)]' : 'h-[calc(100vh-24rem)]'} overflow-auto border rounded-md`}>
            {isLoading ? (<SkeletonLoading isLoading={isLoading} />) : (
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <span className="font-bold text-md">Type</span>
                    </TableCell>
                    <TableCell>
                      <span className="font-bold text-md">Date</span>
                    </TableCell>
                    <TableCell>
                      <span className="font-bold text-md">Title</span>
                    </TableCell>
                    <TableCell colSpan={3}>
                      <span className="font-bold text-md">Details</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredBookmarks.length > 0 ? (
                    filteredBookmarks.map((item: any, index) => (
                      <TableRow key={item._id} className="border-b-0 show-actions-on-hover" hover={true}>
                        <TableCell className="w-[7rem] capitalize">
                          {item.typeName}
                        </TableCell>
                        <TableCell className="min-w-[8rem]">
                          {(item.createdOn?.split("T")[0]) || 'N/A'}
                        </TableCell>
                        <TableCell className="max-w-[23rem]">
                          <p className="truncate">{item.header || "N/A"}</p>
                        </TableCell>
                        <TableCell className="max-w-[20rem]">
                          <p className="truncate">{item.detail || "N/A"}</p>
                        </TableCell>
                        <TableCell align='right'>
                          <div className="flex flex-row justify-end gap-3">
                            <Tooltip title={(!isAuthorised() && index > 2) ? constants.tooltips.upgradePlan : 'View Bookmark'} placement='bottom-start'>
                              <span>
                                <IconButton size="small"
                                  disabled={!isAuthorised() && index > 2}
                                  className="action"
                                  sx={{
                                    zIndex: '10',
                                    color: "#D91E25",
                                    ":hover": {
                                      color: "#D91E25",
                                    },
                                  }}
                                  onClick={(event: any) => {
                                    switch (item.typeName) {
                                      case BookmarkTypes.STATUTE: {
                                        openStatuteDoc(item);
                                        break;
                                      }
                                      case BookmarkTypes.CASE: {
                                        openJudgment(item.targetId);
                                        break;
                                      }
                                      case BookmarkTypes.DEPARTMENT: {
                                        openDepartmentFile(item);
                                        break;
                                      }
                                    }
                                  }}
                                >
                                  <RemoveRedEye fontSize='small' />
                                </IconButton>
                              </span>
                            </Tooltip>
                            <Tooltip title={(!isAuthorised()) ? constants.tooltips.upgradePlan : 'Delete Bookmark'} placement='bottom-start'>
                              <span>
                                <IconButton size="small"
                                  disabled={!isAuthorised()}
                                  className="action"
                                  sx={{
                                    zIndex: '10',
                                    color: "#D91E25",
                                    ":hover": {
                                      color: "#D91E25",
                                    },
                                  }}
                                  onClick={(event: any) => {
                                    deleteBookmarkHandler(item)
                                  }}
                                >
                                  <Delete fontSize='small' />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align={"center"}>
                        No Bookmarks found!
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </div>
          <div className="flex flex-row justify-end">
            <Stack spacing={2} className="flex flex-row mt-4 justify-end">
              <Tooltip title={(!isAuthorised()) ? constants.tooltips.upgradePlan : ''} placement='left'>
                <span>
                  <Pagination
                    color="primary"
                    page={bookmarkPageNo}
                    disabled={!isAuthorised()}
                    count={totalBookmarkPages}
                    siblingCount={0}
                    boundaryCount={1}
                    onChange={handleBookmarkPageChange}
                    shape={"rounded"}
                  />
                </span>
              </Tooltip>
            </Stack>
          </div>
        </div>
      </div>
      {openForbiddenPrompt && (<ForbiddenPrompt open={openForbiddenPrompt} showLogout={true} onClose={() => { setOpenForbiddenPrompt(false); logout() }} />)}
    </>
  );
}