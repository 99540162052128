import { Article, Domain, Newspaper, NoteAlt, ManageAccounts, Balance, AccountBalance } from "@mui/icons-material";
import { Divider } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { isAdmin, isUserManager, isDataOperator } from "../app.utils";
import { useDialog } from "../providers";
import { OrganizationManagement } from "../dialogs";

export const ManagementSection = () => {
  const navigate = useNavigate();
  const { openDialog, closeDialog } = useDialog();
  const openOrganizationManagementDialog = () => {
    if (isAdmin()) {
      openDialog(OrganizationManagement, {
        onClose: () => {
          closeDialog();
        }
      });
    }
  };
  const actions = [
    {
      name: 'User Management',
      action: () => { navigate('/manage/users') },
      icon: <ManageAccounts fontSize="large" />,
      isVisible: isAdmin() || isUserManager(),
    },
    {
      name: 'Judgment Management',
      action: () => navigate('/manage/judgments'),
      icon: <NoteAlt fontSize="large" />,
      isVisible: isAdmin() || isDataOperator(),
    },
    {
      name: 'Legal News Management',
      action: () => navigate('/manage/legal-news'),
      icon: <Article fontSize="large" />,
      isVisible: isAdmin() || isDataOperator(),
    },
    {
      name: 'Banner Management',
      action: () => navigate('/manage/banners'),
      icon: <Newspaper fontSize="large" />,
      isVisible: isAdmin() || isDataOperator(),
    },
    {
      name: 'Court Management',
      action: () => navigate('/manage/courts/add'),
      icon: <AccountBalance fontSize="large" />,
      isVisible: isAdmin() || isDataOperator(),
    },
    {
      name: 'Statute Management',
      action: () => navigate('/manage/statutes/add'),
      icon: <Balance fontSize="large" />,
      isVisible: isAdmin() || isDataOperator(),
    },
    {
      name: 'Organization Management',
      action: () => openOrganizationManagementDialog(),
      icon: <Domain fontSize="large" />,
      isVisible: isAdmin(),
    },
  ];
  return (
    <div className="flex flex-col gap-4 rounded-container h-full">
      <p className="text-2xl font-bold">Management</p>
      <Divider />
      <div className="flex flex-wrap justify-start gap-8 p-5">
        {actions.filter(action => action.isVisible).map((action, index) => (
          <div
            key={index}
            className="cursor-pointer bg-white shadow-md rounded-md p-6 text-center transition-transform transform hover:scale-105 border border-gray-100 hover:border-gray-300 w-72 h-64 flex flex-col items-center justify-center"
            onClick={action.action}
          >
            <div className="mb-2 text-gray-700">{action.icon}</div>
            <h3 className="text-2xl font-semibold text-gray-700">{action.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};
