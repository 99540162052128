import { Bookmark, BookmarkAddOutlined, HighlightOff } from "@mui/icons-material";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { BookmarkTypes } from "../app.enums";
import { decryptString } from "../app.utils";
import { useLoader } from "../providers";
import { getStatuteFile } from "../services/statutes.service";
import { PDFViewer } from "../components";

type StatuteDocumentPreviewDialogProps = {
  open: boolean;
  onClose: () => void;
  statute: {
    selectedStatute: string,
    selectedStatuteTitle: string,
    selectedStatuteAct: string,
  }
};

export const StatuteDocumentPreview = ({ open, onClose, statute }: StatuteDocumentPreviewDialogProps) => {
  const { showLoader, hideLoader } = useLoader();
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [bookmark, setBookmark] = useState('');
  const [pdfData, setPdfData] = useState<any>(null);

  useEffect(() => {
    if (open) {
      getBookmarkState();
      showLoader();
      getStatuteFile(statute.selectedStatute).then((res: any) => {
        if (res.data) {
          setPdfData(res.data);
        } else {
          toast.error('Failed to fetch statute document.');
          onClose();
        }
      }).catch((error) => {
        toast.error('Failed to fetch statute document.');
        onClose();
      }).finally(() => {
        hideLoader();
      });
    }
  }, []);

  const bookmarkStatute = () => {
    showLoader();
    axios.post(`${process.env.REACT_APP_API_NEST_URL}/bookmark`,
      {
        header: statute.selectedStatuteTitle,
        detail: statute.selectedStatuteAct,
        targetId: statute.selectedStatute,
        userId: decryptString(localStorage.getItem('userId')),
        typeName: BookmarkTypes.STATUTE,
      }, {
      headers: {
        Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
      },
    }).then((res) => {
      hideLoader();
      toast.success('Bookmark created successfully.');
      setIsBookmarked(true);
    }).catch((err) => {
      hideLoader();
      toast.error("Failed to bookmark statute.");
    });
  };

  const deleteBookmark = async () => {
    const userId = decryptString(localStorage.getItem('userId'));
    const URL = `${process.env.REACT_APP_API_NEST_URL}/bookmark?userId=${userId}&bookmarkIds=${bookmark}`;
    showLoader();
    const response = await axios.delete(URL, {
      headers: {
        Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
      },
    }).catch(err => {
      toast.error('Failed to delete bookmark.');
    }).finally(() => hideLoader());
    if (response) {
      toast.success('Bookmark removed successfully.');
      setIsBookmarked(false);
    }
  };

  const getBookmarkState = async () => {
    showLoader();
    await axios.get(`${process.env.REACT_APP_API_NEST_URL}/bookmark?targetId=${statute.selectedStatute}&typeName=${BookmarkTypes.STATUTE}&userId=${decryptString(localStorage.getItem('userId'))}`, {
      headers: {
        Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
      },
    }).catch(err => {
      setIsBookmarked(false);
    }).then(response => {
      if (response) {
        if (response.data.data[0]) {
          setIsBookmarked(true);
          setBookmark(response.data.data[0].id);
        } else {
          setIsBookmarked(false);
        }
      }
    }).finally(() => hideLoader());
  }

  return (
    <Dialog
      maxWidth={false}
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <div className='flex flex-row bg-gradient-to-b from-[#41090b] to-[#150303] justify-between p-2 gap-2 items-center border-b-[0.1px] border-b-black border-opacity-50'>
        {/* Todo: Will replace This header with file name */}
        <p className='font-bold text-primary mx-3'>Statute Document</p>
        <div className='flex flex-row gap-2 items-center justify-center'>
          {isBookmarked ? (
            <IconButton
              sx={{ color: '#D91E25' }}
              onClick={() => {
                deleteBookmark();
              }}
              size='small'
              className='action'
              color='primary'>
              <Bookmark fontSize='small' />
            </IconButton>
          ) : (
            <IconButton
              sx={{ color: '#D91E25' }}
              onClick={() => {
                bookmarkStatute();
              }}
              size='small'
              className='action'
              color='primary'>
              <BookmarkAddOutlined fontSize='small' />
            </IconButton>
          )}
          <IconButton
            sx={{ color: '#D91E25' }}
            onClick={onClose}
            size='small'
            className='action'
            color='primary'>
            <HighlightOff fontSize='small' />
          </IconButton>
        </div>
      </div>
      <DialogContent>
        {pdfData ? (
          <PDFViewer blob={pdfData} />
        ) : null}
      </DialogContent>
    </Dialog>
  );
};