import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { formatDateToMonthDayYear } from "../../app.utils";
import { toast } from "react-toastify";
import { fetchBlogById } from "../../services/blogs.service";
import { RemoveRedEye } from "@mui/icons-material";
import { useScrollToTop } from "../../app.hooks";
import { SkeletonCardLoading } from "../../components";

const LegalAwarenessNews = () => {
  const location = useLocation();
  useScrollToTop();
  const searchParams = new URLSearchParams(location.search);
  const blogId = searchParams.get('blogId') || '';

  const [isLoading, setIsLoading] = useState(false);

  const [blog, setBlog] = useState<any>([]);

  useEffect(() => {
    if (blogId !== '') {
      setIsLoading(true);
      fetchBlogById(blogId).then((res) => {
        setBlog(res.data);
        setIsLoading(false);
      }).catch((err) => {
        toast.error('Failed to fetch blog.');
        setIsLoading(false);
      })
    }
  }, []);

  return (
    <div className="flex flex-col bg-white">
      {(isLoading || !blog) ? (
        <div className="p-10">
          <SkeletonCardLoading rows={1} height={500} />
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-8 bg-gradient-to-t from-[#41090b] to-[#150303] h-96 items-center justify-center relative">
            <div className="p-3 flex flex-col lg:flex-row gap-4 w-3/4 lg:min-w-[70%] lg:max-w-5xl bg-white rounded-md shadow-xl absolute top-10 lg:top-40">
              <img src={blog.thumbnail} alt="Uploaded" className="w-72 h-auto rounded-md" />
              <div className="flex flex-col gap-2 justify-between">
                <p className="text-xl lg:text-3xl font-semibold">
                  {blog.title}
                </p>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-row items-center gap-1 text-primary">
                    <p>{blog.view_count}</p>
                    <RemoveRedEye />
                  </div>
                  <p>Posted On: {formatDateToMonthDayYear(blog.createdAt)}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 py-20 px-5">
            <div className="max-w-6xl mx-auto">
              <div dangerouslySetInnerHTML={{
                __html: blog.blog_content,
              }}></div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LegalAwarenessNews;