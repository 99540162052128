import { RemoveRedEye, Delete } from "@mui/icons-material";
import { Tooltip, TextField, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Stack, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { decryptString, isAdmin, isAuthorised, isDataOperator } from "../app.utils";
import { SkeletonLoading } from "../components";
import { constants } from "../app.constants";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logout } from "../services/auth.service";

export const SavedSearches = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [savedSearches, setSavedSearches] = useState([]);
  const [historyPageNo, setHistoryPageNo] = useState(1);
  const [totalHistoryPages, setTotalHistoryPages] = useState(1);
  const [filteredHistory, setFilteredHistory] = useState([]);
  const [historySearchInput, setHistorySearchInput] = useState("");

  /**
   * Function to handle savedSearches page change
   * @param {Event} event
   * @param {number} value
   */
  const handleHistoryPageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setHistoryPageNo(value);
  };
  const searchHistoryFilter = (searchValue: any) => {
    setHistorySearchInput(searchValue);
    if (historySearchInput !== "") {
      const filteredData = savedSearches.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(historySearchInput.toLowerCase());
      });
      setFilteredHistory(filteredData);
    }
  };

  const getHistory = () => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_NEST_URL}/search-history`,
        {
          params: {
            pageNo: historyPageNo,
            pageSize: constants.pageSize,
            userId: decryptString(localStorage.getItem('userId'))
          },
          headers: {
            Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
          },
        }
      )
      .then((response: any) => {
        setIsLoading(false);
        if (response.data) {
          setSavedSearches(response.data?.data);
          setTotalHistoryPages(response.data?.totalPages);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response.status === 401) {
          logout();
        }
      });
  };

  const deleteHistorykHandler = async (item: any) => {
    const userId = decryptString(localStorage.getItem('userId'));
    const historyId = item.id;
    const URL = `${process.env.REACT_APP_API_NEST_URL}/search-savedSearches?userId=${userId}&searchHistoryIds=${historyId}`;
    const response = await axios.delete(URL, {
      headers: {
        Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
      },
    }).catch(err => {
      toast.error('Failed to delete savedSearches.');
    });
    if (response) {
      toast.success('Saved Searches deleted successfully.');
      getHistory();
    }
  }

  useEffect(() => {
    getHistory();
  }, [historyPageNo]);

  useEffect(() => {
    setFilteredHistory(savedSearches);
  }, [savedSearches]);

  return (
    <div className="flex flex-col gap-4 rounded-container">
      <div className="flex flex-row justify-between items-center pb-0">
        <p className="text-2xl font-bold">Saved Searches</p>
        <Tooltip title={!isAuthorised() ? constants.tooltips.upgradePlan : ''} placement={'left'}>
          <span>
            <TextField
              sx={{
                minWidth: 400,
              }}
              disabled={!isAuthorised()}
              size="small"
              type="text"
              placeholder="Search here"
              onChange={(e) => searchHistoryFilter(e.target.value)}
            />
          </span>
        </Tooltip>
      </div>
      <div className="flex flex-col justify-between h-full">
        <div className={`${!isAuthorised() ? 'h-[calc(100vh-26.2rem)]' : 'h-[calc(100vh-24rem)]'} overflow-auto border rounded-md`}>
          {isLoading ? (<SkeletonLoading isLoading={isLoading} />) : (
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span className="font-bold text-md">Screen</span>
                  </TableCell>
                  <TableCell>
                    <span className="font-bold text-md">Date</span>
                  </TableCell>
                  <TableCell>
                    <span className="font-bold text-md">Search label</span>
                  </TableCell>
                  <TableCell colSpan={3}>
                    <span className="font-bold text-md">Search</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredHistory.length > 0 ? (
                  filteredHistory.map((item: any, index) => (
                    <TableRow key={item._id} className="border-b-0 show-actions-on-hover" hover={true}>
                      <TableCell className="w-[7rem] capitalize">
                        {item.typeName || 'N/A'}
                      </TableCell>
                      <TableCell className="w-[8rem]">
                        {(item.createdOn?.split("T")[0]) || 'N/A'}
                      </TableCell>
                      <TableCell className="max-w-[22rem]">
                        <p className="truncate">{item.label || "-"}</p>
                      </TableCell>
                      <TableCell className="max-w-[30rem]">
                        <Tooltip title={item.url} placement='bottom-start'>
                          <p className="truncate select-none">
                            {item.url || 'N/A'}
                          </p>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='right'>
                        <div className="flex flex-row justify-end gap-3">
                          <Tooltip title={(!isAuthorised() && index > 2) ? constants.tooltips.upgradePlan : 'View savedSearches'}>
                            <span>
                              <IconButton size="small"
                                disabled={!isAuthorised() && index > 2}
                                className="action"
                                sx={{
                                  zIndex: '10',
                                  color: "#D91E25",
                                  ":hover": {
                                    color: "#D91E25",
                                  },
                                }}
                                onClick={(event: any) => {
                                  navigate(item.url);
                                }}
                              >
                                <RemoveRedEye fontSize='small' />
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip title={(!isAuthorised()) ? constants.tooltips.upgradePlan : 'Delete Saved Searches'} placement='bottom-start'>
                            <span>
                              <IconButton size="small"
                                disabled={!isAuthorised()}
                                className="action"
                                sx={{
                                  zIndex: '10',
                                  color: "#D91E25",
                                  ":hover": {
                                    color: "#D91E25",
                                  },
                                }}
                                onClick={(event: any) => {
                                  deleteHistorykHandler(item)
                                }}
                              >
                                <Delete fontSize='small' />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align={"center"}>
                      No Saved Searches found!
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </div>
        <div className="flex flex-row justify-end">
          <Stack spacing={2} className="flex flex-row mt-4 justify-end">
            <Tooltip title={(!isAuthorised()) ? constants.tooltips.upgradePlan : ''} placement='left'>
              <span>
                <Pagination
                  color="primary"
                  page={historyPageNo}
                  disabled={!isAuthorised()}
                  count={totalHistoryPages}
                  siblingCount={0}
                  boundaryCount={1}
                  onChange={handleHistoryPageChange}
                  shape={"rounded"}
                />
              </span>
            </Tooltip>
          </Stack>
        </div>
      </div>
    </div>
  );
}