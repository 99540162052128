// Material imports
import { createTheme } from "@mui/material/styles";

// Customising mui theme
const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '0.65rem',
        }
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: "#FAF9F6",
          position: "sticky",
          top: 0,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          userSelect: 'none',
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          left: '8rem', // shift dialog to 8rem (side nav width) left
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '0.65rem',
        },
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          borderRadius: '0.65rem',
        },
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#D91E25',
            backgroundColor: 'white',
          },
        },
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          '&.Mui-focused': {
            color: '#D91E25',
            backgroundColor: 'white',
          }
        },
      }
    },
  },
  palette: {
    primary: {
      main: '#D91E25',
    },
    success: {
      main: '#50C878',
      contrastText: 'white',
    },
  },
});

export default theme;