import axios from "axios";

export const fetchOrganizationTypes = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_NEST_URL}/organization/types`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export const fetchOrganizationDetails = async (organizationId: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_NEST_URL}/organization/${organizationId}/details`);

    return response.data;
  } catch (error: any) {
    if (error.response.status === 403) {
      throw(new Error('Unauthorized Access'));
    } else {
      throw (new Error('Failed to fetch organization details'));
    }
  }
}