// React import
import React, { useEffect, useState } from 'react';


// Material imports
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

// Router import
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, ButtonBase, IconButton, Tooltip } from '@mui/material';

import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { logout } from '../services/auth.service';
import { AccountBalance, AccountCircle, Balance, CurrencyExchangeOutlined, Dashboard, Domain, Gavel, HistoryEdu, Home, KeyboardArrowLeft, KeyboardArrowRight, Newspaper, ReportProblemOutlined } from '@mui/icons-material';
import { decryptString, isAdmin, isDataOperator, isOrgUser, isUserManager } from '../app.utils';
import { useDialog } from '../providers/dialog.provider';
import { constants } from '../app.constants';
import { ReportProblem } from '../dialogs';


export const NavigationMap: { [key: string]: string } = {
  '/home': 'home',
  '/cases': 'Judgments',
  '/home/search-result': 'home',
  '/cases/judgment': 'Judgments',
  '/departments': 'departments',
  '/judges': 'judges',
  '/statutes': 'statutes',
  '/statutes/:id': 'statutes',
  '/courts': 'courts',
  '/legal-news/list': 'legal news',
  '/legal-news/category': 'legal news',
  '/legal-news/view': 'legal news',
};

export const Navigation = () => {
  const { openDialog, closeDialog } = useDialog();

  let navigate = useNavigate();
  let location = useLocation();

  const NavigationItemsList = [
    {
      name: 'home',
      path: '/home',
      icon: (<Home />)
    },
    {
      name: 'Judgments',
      path: '/cases',
      icon: (<HistoryEdu />)
    },
    {
      name: 'judges',
      path: '/judges',
      icon: (<Gavel />)
    },
    {
      name: 'courts',
      path: '/courts',
      icon: (<AccountBalance />)
    },
    {
      name: 'statutes',
      path: '/statutes',
      icon: (<Balance />)
    },
    {
      name: 'departments',
      path: '/departments',
      icon: (<Domain />)
    },
    {
      name: 'legal news',
      path: '/legal-news/list',
      icon: (<Newspaper />)
    },
  ];

  const ActionItemsList = [
    {
      name: 'Subscriptions',
      action: () => { navigate('/plans') },
      icon: (<CurrencyExchangeOutlined />),
      isVisible: !(isAdmin() || isOrgUser() || isUserManager() || isDataOperator()),
    },
    {
      name: 'Dashboard',
      action: () => { navigate('/dashboard') },
      icon: (<Dashboard />),
      isVisible: isAdmin() || isUserManager() || isDataOperator(),
    },
    {
      name: 'Report Problem',
      action: () => openReportProblemDialog(),
      icon: (<ReportProblemOutlined />),
      isVisible: !isAdmin(),
    },
    {
      name: 'Logout',
      action: () => logout(),
      icon: (<PowerSettingsNewIcon />),
      isVisible: true,
    },
  ];

  // Manages selectedScreen state
  const [selectedScreen, setSelectedScreen] = useState(
    NavigationMap[location.pathname]
  );

  /**
   * Toggles selected screen flag
   * @param {string} screen
   * @returns {boolean}
   */
  const isSelected = (screen: string) => screen === selectedScreen;

  useEffect(() => {
    if (location.pathname.includes('judgement')) {
      setSelectedScreen(NavigationMap['/cases']);
    } else {
      setSelectedScreen(NavigationMap[location.pathname]);
    }
  }, [selectedScreen, navigate, location]);

  const openReportProblemDialog = () => {
    openDialog(ReportProblem, {
      onClose: () => {
        closeDialog();
      }
    });
  };

  const [open, setOpen] = useState(true);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <div className='flex flex-row z-50 h-full relative'>
      <div className='flex flex-col flex-1 bg-gradient-to-b from-[#560c0e] to-[#150303] shadow-lg text-white h-full overflow-x-hidden overflow-y-auto'>
        {!open && (
          <List className='h-full w-full'>
            <div className='flex flex-col h-full w-full justify-between'>
              <div className='flex flex-col w-full'>
                <ListItem className='flex flex-col'>
                  <img className='w-24 h-auto py-3' src={constants.svgs.whiteBalance} alt="EastLaw" />
                </ListItem>
                <div className='mt-2 w-full'>
                  {NavigationItemsList.map((navItem) => (
                    <ListItem
                      key={navItem.name}
                      onClick={() => {
                        navigate(navItem.path);
                      }}>
                      <div className={'flex flex-row items-center gap-2 px-2 py-2 cursor-pointer rounded-md w-full' + (isSelected(navItem.name) ? ' bg-primary text-white font-bold' : 'font-semibold')}>
                        {navItem.icon}
                      </div>
                    </ListItem>
                  ))}
                </div>
              </div>
              <div className='mb-5 ml-1'>
                <ListItem
                  key='Profile'
                >
                  <ButtonBase className='flex flex-row gap-2 items-center'>
                    <AccountCircle className='border-2 border-primary rounded-full text-red-100' fontSize='large' />
                  </ButtonBase>
                </ListItem>
                {
                  ActionItemsList.map((actionItem) => (
                    (
                      actionItem.isVisible && (
                        <ListItem
                          key={actionItem.name}
                        >
                          <Tooltip title={actionItem.name} placement='right'>
                            <IconButton color='primary' onClick={actionItem.action}>
                              {actionItem.icon}
                            </IconButton>
                          </Tooltip>
                        </ListItem>
                      ))
                  ))
                }
              </div>
            </div>
          </List>
        )}
        {open && (
          <List className='h-full w-52'>
            <div className='flex flex-col h-full w-full justify-between'>
              <div className='flex flex-col w-full'>
                <ListItem className='flex flex-col'>
                  <img className='w-[11rem] h-auto py-3' src={constants.svgs.eastLawLogoWhite} alt="EastLaw" />
                </ListItem>
                <div className='mt-2 w-full'>
                  {NavigationItemsList.map((navItem) => (
                    <ListItem
                      key={navItem.name}
                      onClick={() => {
                        navigate(navItem.path);
                      }}>
                      <div className={'flex flex-row items-center gap-2 px-2 py-2 cursor-pointer rounded-md w-full' + (isSelected(navItem.name) ? ' bg-primary text-white font-bold' : 'font-semibold')}>
                        {navItem.icon}
                        <p className='capitalize'>{navItem.name}</p>
                        <p>{isSelected(navItem.name)}</p>
                      </div>
                    </ListItem>
                  ))}
                </div>
              </div>
              <div className='mb-5 ml-1'>
                <ListItem
                  className='min-w-[12rem]'
                  key='Profile'
                >
                  <ButtonBase className='flex flex-row gap-2 items-center'>
                    <AccountCircle className='border-2 border-primary rounded-full text-red-100' fontSize='large' />
                    <div className='flex flex-col justify-start items-start'>
                      <p className='text-red-100 font-semibold max-w-[7rem] truncate'>
                        {decryptString(localStorage.getItem('username'))}
                      </p>
                      <p className='small-badge'>
                        {decryptString(localStorage.getItem('role'))}
                      </p>
                    </div>
                  </ButtonBase>
                </ListItem>
                {
                  ActionItemsList.map((actionItem) => (
                    (
                      actionItem.isVisible && (
                        <ListItem
                          className='min-w-[12rem]'
                          key={actionItem.name}
                        >
                          <Button variant="text" onClick={actionItem.action} sx={{
                            color: '#D91E25',
                            fontWeight: '700',
                          }} startIcon={actionItem.icon}>
                            {actionItem.name}
                          </Button>
                        </ListItem>
                      ))
                  ))
                }
              </div>
            </div>
          </List>
        )}
      </div>
      <div className='absolute -right-2 top-0 cursor-pointer' onClick={handleDrawerToggle}>
        {open ? (<KeyboardArrowLeft className='border bg-white border-primary text-primary rounded-full shadow-md' fontSize='small' />) : (<KeyboardArrowRight className='border bg-white border-primary text-primary rounded-full shadow-md' fontSize='small' />)}
      </div>
    </div>
  );
};