import { Button, Divider, FormControl, InputLabel, MenuItem, Pagination, Select, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ControlPoint, Edit, Publish } from "@mui/icons-material";
import { constants } from "../../app.constants";
import { isAuthorised, concatenateStrings, decryptString, formatDate } from "../../app.utils";
import axios from "axios";
import { toast } from "react-toastify";
import { logout } from "../../services/auth.service";
import { Role } from "../../enums";
import { withAccess } from "../../services/role-guard.service";
import { useNavigate } from "react-router-dom";
import { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { BackButton, ContainerLoader, RecordCount, SkeletonLoading, TabList } from "../../components";

const DataManagement = () => {
  const navigate = useNavigate();
  const [isCaseSearching, setIsCaseSearching] = useState(false);
  const [journals, setJournals] = useState([]);
  const [cases, setCases] = useState([]);
  const [searchCitationYear, setSearchCitationYear] = useState('');
  const [searchCitationJournal, setSearchCitationJournal] = useState('');
  const [searchCitationPageNo, setSearchCitationPageNo] = useState('');
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isJournalSearching, setIsJournalSearching] = useState(true);

  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const tabs = [
    { id: 'searchByCitation', label: 'By Citation' },
    { id: 'searchByDate', label: 'By Date' },
  ];
  const [selectedTab, setSelectedTab] = useState('searchByCitation');

  const handleStartDateChange = (value: any) => {
    setStartDate(value);
  }

  const handleEndDateChange = (value: any) => {
    setEndDate(value);
  }

  const handleTabChange = (tabId: string) => {
    resetCases();
    setPageNo(1);
    setStartDate(null);
    setEndDate(null);
    setSearchCitationYear('');
    setSearchCitationJournal('');
    setSearchCitationPageNo('');
    setSelectedTab(tabId);
  };

  useEffect(() => {
    fetchJournals();
  }, [])
  const fetchJournals = () => {
    setIsJournalSearching(true);
    axios
      .get(
        `${process.env.REACT_APP_API_NEST_URL}/citation-search/journals`,
        {
          headers: {
            Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
          },
        }
      )
      .then((response: any) => {
        setIsJournalSearching(false);
        setJournals(response.data?.data);
      })
      .catch((error) => {
        setIsJournalSearching(false);
        // logout; if unauthorised
        if (error.response.status === 401) {
          logout();
        }
        toast.error('Failed to fetch judgments.');
      });
  }

  const searchCases = (casesPageNo = pageNo) => {
    if (searchCitationYear || searchCitationJournal || searchCitationPageNo) {
      setIsCaseSearching(true);
      axios
        .get(
          `${process.env.REACT_APP_API_NEST_URL}/citation-search/case-by-citation`,
          {
            params: {
              citationYear: searchCitationYear.toString() || '',
              citationJournel: searchCitationJournal || '',
              citationPageNo: searchCitationPageNo.toString() || '',
              pageNo: casesPageNo,
              pageSize: 25,
            },
            headers: {
              Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
            },
          }
        )
        .then((response: any) => {
          setIsCaseSearching(false);
          if (response.data) {
            if (response.data?.totalPages !== 0 && response.data?.totalPages < casesPageNo) {
              setPageNo(1);
            } else {
              if (response.data?.data.length === 0) {
                toast.warning('No judgments found for this citation.');
              } else {
                setCases(response.data?.data);
                setTotalPages(response.data?.totalPages);
                setTotalRecords(response.data?.totalRecords);
              }
            }
          }
        })
        .catch((error) => {
          setIsCaseSearching(false);
          // logout; if unauthorised
          if (error.response.status === 401) {
            logout();
          }
          toast.error('Failed to fetch judgments.');
        });
    }
  };

  const searchCasesByDate = (casesPageNo = pageNo) => {
    let requestBody: any = {
      pageNo: casesPageNo,
      requestedCount: constants.pageSize,
    }
    if (startDate) {
      requestBody['from'] = formatDate(startDate.toString());
    }
    if (endDate) {
      requestBody['to'] = formatDate(endDate.toString());
    }
    setIsCaseSearching(true);
    axios.post(`${process.env.REACT_APP_API_NEST_URL}/case-search/filter-date-es`,
      requestBody, {
      headers: {
        Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
      },
    }).then((response: any) => {
      setIsCaseSearching(false);
      if (response.data) {
        if (response.data?.data.length === 0) {
          toast.warning('No judgments found');
          resetCases();
        } else {
          let fetchedCases = response.data?.data.filter(
            (caseDetail: any) => (caseDetail.id !== null)
          );
          setCases(fetchedCases);
          setTotalPages(response.data?.totalPages);
          setTotalRecords(response.data?.totalRecords);
        }
      }
    })
      .catch((error) => {
        setIsCaseSearching(false);
        if (error.response.status === 401) {
          logout();
        }
        toast.error('Failed to fetch judgments.');
      });
  };

  // Function to reset cases selection
  const resetCases = () => {
    setCases([]);
    setTotalPages(1);
    setTotalRecords(0);
  }

  /**
   * Function to handle page change
   * @param {Event} event
   * @param {number} value
   */
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageNo(value);
  };

  useEffect(() => {
    searchCases(pageNo);
  }, [pageNo]);

  // Open judgment dialog
  const openJudgment = (item: any) => {
    const url = '/edit/judgment?judgmentId=' + item?._id?.$oid;
    window.open(url, '_blank');
  };

  return (
    <>
      <div className="flex flex-1 flex-col gap-4 w-full h-full">
        <div className="flex flex-col flex-1 bg-white w-full p-4 rounded-xl shadow-xl gap-6">
          <div className="flex flex-col gap-2">
            <div className='flex flex-row justify-start items-start'>
              <BackButton />
            </div>
            <div className="flex flex-row justify-between items-center">
              <p className="text-3xl font-bold">Judgment Management</p>
              <div className="flex flex-row gap-6">
                <div className="flex flex-row gap-1 justify-center items-center px-3 py-2 border border-slate-300 hover:border-primary shadow rounded-lg cursor-pointer" onClick={() => navigate('/manage/judgments/add')}>
                  <ControlPoint color="primary" />
                  <p className="text-primary font-bold">Add Judgment</p>
                </div>
              </div>
            </div>
            <Divider />
          </div>
          <div className='flex h-full flex-col gap-4 w-full'>
            <div className="flex flex-row justify-between items-end">
              <p className='text-2xl font-bold'>Search Judgments</p>
              <div className="max-w-max">
                <TabList tabs={tabs} activeTab={selectedTab} onTabChange={handleTabChange} />
              </div>
            </div>
            {selectedTab === 'searchByCitation' ? (
              <div className="flex flex-row gap-4 w-full">
                <FormControl fullWidth>
                  <TextField size='small'
                    type='number'
                    placeholder='Year'
                    value={searchCitationYear}
                    onChange={(e) => setSearchCitationYear(e.target.value)}
                  />
                </FormControl>
                <FormControl fullWidth size='small'>
                  <InputLabel id='select-journal'>
                    Journal
                  </InputLabel>
                  <Select
                    labelId='select-journal'
                    MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                    variant='outlined'
                    defaultValue={''}
                    value={journals?.find((journal: any) => journal.name === searchCitationJournal) || ''}
                    label='Journal'
                    placeholder='ajkdhas'
                    onChange={(e: any) => {
                      setSearchCitationJournal((e.target.value).name);
                    }}
                  >
                    {journals.map((item: any, index) => (
                      <MenuItem value={item} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <TextField size='small'
                    type='text'
                    placeholder='Page No.'
                    value={searchCitationPageNo}
                    onChange={(e) => setSearchCitationPageNo(e.target.value)}
                  />
                </FormControl>
                <div className='max-w-max'>
                  <Button
                    className='w-full h-full'
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      setPageNo(1);
                      searchCases();
                    }}
                  >
                    Search
                  </Button>
                </div>
              </div>
            ) : (
              <div className="flex flex-row gap-4 w-full">
                <FormControl fullWidth>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    format="YYYY-MM-DD"
                    slotProps={{ textField: { size: 'small' } }}
                    onChange={handleStartDateChange}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    format="YYYY-MM-DD"
                    slotProps={{ textField: { size: 'small' } }}
                    onChange={handleEndDateChange}
                  />
                </FormControl>
                <div className='max-w-max'>
                  <Button
                    className='w-full h-full'
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      setPageNo(1);
                      searchCasesByDate();
                    }}
                  >
                    Search
                  </Button>
                </div>
              </div>
            )}
            <div className='flex flex-col flex-1 justify-between w-full h-full'>
              <div className={`overflow-auto border w-full rounded-md h-[calc(100vh-27rem)]`}>
                {isCaseSearching ? (<SkeletonLoading isLoading={isCaseSearching} />) : (
                  <Table aria-label='simple table' size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <span className='font-bold text-md'>Party Name</span>
                        </TableCell>
                        <TableCell>
                          <span className='font-bold text-md'>Appeal</span>
                        </TableCell>
                        <TableCell>
                          <span className='font-bold text-md'>Reported as</span>
                        </TableCell>
                        <TableCell>
                          <span className='font-bold text-md'>Court</span>
                        </TableCell>
                        <TableCell className='min-w-[10rem]'>
                          <span className='font-bold text-md'>Judgment Date</span>
                        </TableCell>
                        <TableCell colSpan={2}>
                          <span className='font-bold text-md'>Result</span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cases.length > 0 ? (
                        cases.map((item: any, index) => (
                          <TableRow key={item._id?.$oid} className='border-b-0 show-actions-on-hover' hover={true}>
                            <TableCell>
                              <div className='flex flex-row items-center gap-1'>
                                <Tooltip title={(item.appeallant || 'N/A') + ' vs ' + (item.respondant || 'N/A')} placement='bottom-start'>
                                  <p className='max-w-[25rem] truncate'>
                                    {(item.appeallant || 'N/A') + ' vs ' + (item.respondant || 'N/A')}
                                  </p>
                                </Tooltip>
                                <Tooltip title={'Edit Jugdment'}>
                                  <div className='cursor-pointer action' onClick={(e) => {
                                    openJudgment(item);
                                  }}>
                                    <Edit className='text-primary' sx={{ fontSize: '1.2rem' }} />
                                  </div>
                                </Tooltip>
                              </div>
                            </TableCell>
                            <TableCell>
                              <Tooltip placement='bottom-start' title={item.appeal}>
                                <p className='max-w-[18rem] truncate'>
                                  {item.appeal || 'N/A'}
                                </p>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              <Tooltip placement='bottom-start' title={concatenateStrings(item.citationNames) || 'N/A'}>
                                <p className='max-w-[18rem] truncate'>
                                  {concatenateStrings(item.citationNames) || 'N/A'}
                                </p>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              <Tooltip placement='bottom-start' title={item.court}>
                                <p className='max-w-[18rem] truncate'>
                                  {item.court || 'N/A'}
                                </p>
                              </Tooltip>
                            </TableCell>
                            <TableCell >
                              {formatDate(item.judgment_date) || 'N/A'}
                            </TableCell>
                            <TableCell className='min-w-[10rem]'>
                              {item.result || 'N/A'}
                            </TableCell>
                            <TableCell align='right'>
                              <Tooltip title={(!isAuthorised() && index > 2) ? constants.tooltips.upgradePlan : ''} placement='bottom-start'>
                                <span>
                                  <Button
                                    disabled={!isAuthorised() && index > 2}
                                    className='max-w-max action'
                                    variant='contained'
                                    size='small'
                                    color='primary'
                                    onClick={(e) => {
                                      openJudgment(item);
                                    }}
                                  >
                                    Edit
                                  </Button>
                                </span>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))) : (
                        <TableRow>
                          <TableCell colSpan={6} align={'center'}>
                            No Judgments found.
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                )}
              </div>
              <div className={'flex flex-row justify-end items-center'}>
                <div className='mt-4'>
                  <RecordCount pageNo={pageNo} pageSize={Number.parseInt('25')} totalRecords={totalRecords} />
                </div>
                <Stack spacing={2} className='flex flex-row mt-4 justify-end'>
                  <Tooltip title={(!isAuthorised()) ? constants.tooltips.upgradePlan : ''} placement='left'>
                    <span>
                      <Pagination
                        color="primary"
                        page={pageNo}
                        disabled={!isAuthorised()}
                        count={totalPages}
                        siblingCount={0}
                        boundaryCount={1}
                        onChange={handlePageChange}
                        shape={'rounded'}
                      />
                    </span>
                  </Tooltip>
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContainerLoader isLoading={isJournalSearching} />
    </>
  );
}

export default withAccess(DataManagement, [Role.ADMIN, Role.DATA_OPERATOR]);