import React from 'react'
import { FeaturesScroller } from '../components';

export const ApplicationTourSection = () => {
  return (
    <div className="flex flex-col gap-12 mx-auto max-w-6xl py-20 justify-center items-center text-center">
      <p className="text-4xl">Explore <span className='font-bold gradient-text-red text-4xl'>EastLaw</span> features.</p>
      <div className='hidden xl:flex'>
        <FeaturesScroller />
      </div>

      <div className='flex xl:hidden'>
        <FeaturesScroller width={1000} />
      </div>
    </div>
  )
};