import { Divider, Tooltip } from "@mui/material";
import React, {  } from "react";
import { formatDate, isAuthorised } from "../app.utils";
import { toast } from "react-toastify";

interface SearchResultCardProps {
  caseDetail: any;
  isCaseSummaryNull: boolean;
  index: number;
  searchTerm?: string;
}

export const SearchResultCard: React.FC<SearchResultCardProps> = ({ caseDetail, isCaseSummaryNull, index, searchTerm }) => {
  // const [doc, setDoc] = useState({ highlighter: false, bookmark: false, id: '', type: BookmarkTypes.CASE, header: '', detail: '' });

  // useEffect(() => {
  //   if (caseDetail) {
  //     console.log(caseDetail);
  //     setDoc({
  //       ...doc,
  //       id: caseDetail.id,
  //       header: caseDetail.appeallant + ' vs ' + caseDetail.respondant,
  //       detail: caseDetail.result || 'N/A'
  //     });
  //   }
  // }, []);

  const openJudgment = () => {
    if (!isAuthorised() && index > 2) {
      toast.warning('Please upgrade your plan to view the details of this case.');
    } else {
      const url = `/cases/judgment?judgmentId=${caseDetail?.id}&searchTerm=${searchTerm}`;
      window.open(url, '_blank');
    }
  };

  return (
    <div className="flex flex-col rounded-md gap-2 bg-stone-50 border border-slate-300 shadow p-3 w-full">
      <div className="flex flex-row gap-2 justify-between items-start">
        <div className="flex flex-row justify-start items-start gap-1">
          <Tooltip title="Click to open case details in new tab." placement="right">
            <p className="font-bold text-lg hover:text-primary cursor-pointer" onClick={openJudgment}>
              {caseDetail.appeallant} vs {caseDetail.respondant}
            </p>
          </Tooltip>
        </div>
        {/* <TopPanel doc={doc} setDoc={setDoc} justIcon={true}></TopPanel> */}
      </div>
      <p className="text-sm line-clamp-1">{caseDetail.appeal}</p>
      <Divider />
      <div className="max-h-[6.6rem] overflow-auto py-1"
        dangerouslySetInnerHTML={{
          __html: isCaseSummaryNull ? (caseDetail.judgment === `<p style=\"line-height: 1.2rem\"></p>` ? '<i>No exact match found. Click to see more details.</i>' : caseDetail.judgment) : (caseDetail.case_summary),
        }}
      ></div>
      <Divider />

      <div className="flex flex-row justify-between items-center text-sm">
        <p><b>Court: </b>{caseDetail.court || 'N/A'}</p>
        <p><b>Cited by: </b>{caseDetail.referenced_judgments?.length || '0'}</p>
      </div>
      <div className="flex flex-row justify-between items-center text-sm">
        <p><b>Dated: </b>{formatDate(caseDetail.judgment_date) || 'N/A'}</p>
        <p><b>Coram: </b>{caseDetail.judges?.length || '0'}</p>
      </div>
    </div>
  );
};