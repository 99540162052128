import { Skeleton } from "@mui/material";
import React from "react";

export const SkeletonCardLoading = ({ rows = 1, height = 250 }) => {
  return (
    <div className="flex flex-col w-full">
      {
        Array.from({ length: rows }).map((i) => (
          <Skeleton className="w-full" height={height} />
        ))
      }
    </div>
  );
};