import React from 'react';
import { Button } from '@mui/material';
import { useScrollToTop } from '../../app.hooks';
import { useDialog } from '../../providers';
import { CorporatePlanRequest } from '../../dialogs';

export const CorporatePricingPolicy = () => {
  const { openDialog, closeDialog } = useDialog();
  useScrollToTop();

  const openCorporatePlanRequestDialog = (selectedPlan: string) => {
    openDialog(CorporatePlanRequest, {
      selectedPlan,
      onClose: () => {
        closeDialog();
      }
    });
  };

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex min-h-screen flex-col py-20 gap-10 lg:px-[15rem] px-20 w-full h-full bg-white text-lg'>
        <div className='flex flex-col gap-12 text-center items-center'>
          <p className='text-6xl gradient-text-red font-bold'>Checkout our Corporate Plans</p>
          <p className='text-4xl'>Law Firm Plans, Institutional, University, Libraries, Data Centers Level Access.</p>
        </div>

        <div className='flex flex-col justify-center items-center gap-4 text-xl'>
          <p>By subscribing to a EastLaw plan, you agree to <a className='font-bold text-primary cursor-pointer hover:underline' onClick={() => { window.open('/terms-of-use', '_blank', 'noopener,noreferrer') }}>our terms</a> and <a className='font-bold text-primary cursor-pointer hover:underline' onClick={() => { window.open('/privacy-policy', '_blank', 'noopener,noreferrer') }}>privacy policy</a>.</p>
        </div>

        <div className='flex flex-col gap-3 border border-slate-200 rounded-xl pt-3 shadow-lg p-4'>
          <p className='text-2xl font-bold'>Law Firm, In-house Departments & Companies etc</p>
          <p>Starts from <b>Rs. 120,000*</b></p>
          <p className=''>Tailored access for law firms requiring comprehensive legal resources. Special conditions available for law firms seeking specific resources.</p>
          <p className='text-sm break-words bg-yellow-100 border border-yellow-400 rounded py-1 px-2 mb-2'>*Upto 3 Accounts only, additional accounts maybe requested, subject to terms and conditions and privacy policy.</p>
          <Button
            className='max-w-max'
            variant='contained'
            color='primary'
            onClick={() => openCorporatePlanRequestDialog('Law Firm, In-house Departments & Companies etc')}
          >
            Click here to contact us
          </Button>
        </div>

        <div className='flex flex-col gap-3 border border-slate-200 rounded-xl pt-3 shadow-lg p-4'>
          <p className='text-2xl font-bold'>International Law Firms, Libraries, Research, Organizations & Institutes</p>
          <p>Start from <b>$1000*</b></p>
          <p className=''>Global access to legal resources for international organizations, libraries, research institutions, NGOs, associations, and specialized user groups seeking diverse legal resources.</p>
          <Button
            className='max-w-max'
            variant='contained'
            color='primary'
            onClick={() => openCorporatePlanRequestDialog('International Law Firms, Libraries, Research, Organizations & Institutes')}
          >
            Click here to contact us
          </Button>
        </div>

        <div className='flex flex-col gap-3 border border-slate-200 rounded-xl pt-3 shadow-lg p-4'>
          <p className='text-2xl font-bold'>Universities, Government Departments, INGO’s, UN or EU and USA organizations</p>
          <p className='italic underline'>Contact us for Pricing</p>
          <p className=''>Tailored Pricing & packages for multiple organization users seeking IP-based integration and case management systems. Contact us for personalized pricing and features tailored to your organization's needs</p>
          <Button
            className='max-w-max'
            variant='contained'
            color='primary'
            onClick={() => openCorporatePlanRequestDialog('Universities, Government Departments, INGO’s, UN or EU and USA organizations')}
          >
            Click here to contact us
          </Button>
        </div>

      </div>
    </div>
  );
};

export default CorporatePricingPolicy;