// React import
import React, { useEffect, useState } from 'react';

// Material import
import {
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

// Router imports
import { useLocation, useNavigate } from 'react-router-dom';

// Contants import
import { constants } from '../../app.constants';

// Container import

// Component import

// Service import
import { logout } from '../../services/auth.service';

// Third party imports
import axios from 'axios';
import { toast } from 'react-toastify';
import { areStringArraysEqual, concatenateStrings, decryptString, formatDate, isAuthorised } from '../../app.utils';
import { Cancel, Launch } from '@mui/icons-material';
import { useLoader } from '../../providers';
import { getAllJudgmentsByJudgeAndCourt, getSingleBenchJudgments } from '../../services/judge.service';
import { RecordCount, SaveSearchButton, TabList } from '../../components';


const JudgmentsByJudge = () => {
  const { showLoader, hideLoader } = useLoader();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [judge, setJudge] = useState<any>('');
  const [judges, setJudges] = useState([]);
  const [panel, setPanel] = useState<any>([]);
  const [Court, setCourt] = useState('');
  const [Courts, setCourts] = useState([]);
  const [benchCases, setBenchCases] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [aloneCases, setAloneCases] = useState([]);
  const [casesPageNo, setCasesPageNo] = useState(1);
  const [casesTotalRecords, setCasesTotalRecords] = useState(0);
  const [totalCasesPages, setCasesTotalPages] = useState(1);
  const [totalStandaloneCasesPages, setTotalStandaloneCasesPages] = useState(1);
  const [totalStandaloneCases, setTotalStandaloneCases] = useState(0);
  const [selectedPanel, setSelectedPanel] = useState<any>();
  const [selectedPanelIds, setSelectedPanelIds] = useState<any>();

  const [allJudgments, setAllJudgments] = useState([]);
  const [totalAllJudgmentPages, setTotalAllJudgmentPages] = useState(1);
  const [totalAllJudgments, setTotalAllJudgments] = useState(0);

  const tabs = [
    { id: 'all', label: 'All' },
    { id: 'alone', label: 'Single Bench' },
    { id: 'bench', label: 'D.B. or Larger Bench' },
  ];

  const [selectedTab, setSelectedTab] = useState('all');

  const handleTabChange = (tabId: string) => {
    setSelectedTab(tabId);
    setBenchCases([]);
    setCasesPageNo(1);
    setSelectedPanel(null);
    setSelectedPanelIds(null);
    searchParams.set('viewAs', tabId.toString());
    searchParams.delete('panelIds');
    searchParams.set('casesPageNo', '1');
    navigate({ search: searchParams.toString() });
  };

  useEffect(() => {
    fetchJudges();
  }, []);

  const fetchCourts = async (judgeName = judge) => {
    showLoader();
    await axios
      .get(
        `${process.env.REACT_APP_API_NEST_URL}/court-search/judge-name?judgeName=${judgeName}`,
        {
          headers: {
            Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
          },
        }
      )
      .then((response: any) => {
        console.log('see this ==> ', response.data.courts);
        setCourts(response.data.courts);
        hideLoader();
      })
      .catch((error) => {
        hideLoader();
        // logout; if unauthorised
        if (error.response.status === 401) {
          logout();
        }
        toast.error('Failed to fetch courts.');
      });
  }

  const fetchJudges = async () => {
    showLoader();
    axios
      .get(
        `${process.env.REACT_APP_API_NEST_URL}/judge-search`,
        {
          headers: {
            Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
          },
        }
      )
      .then((response) => {
        setJudges(response.data);
        hideLoader();
      })
      .catch((error) => {
        hideLoader();
        if (error.response.status === 401) {
          logout();
        }
        toast.error('Failed to fetch judges of selected court.');
      });
  }

  const handleChangeCourt = (courtId: any) => {
    resetSelections();
    setCourt(courtId);
    fetchPanels(judge, courtId);
    fetchStandAloneCases(judge, courtId);
    fetchAllJudgments(judge, courtId);
  };

  const handleChangeJudge = (event: any, selectedCourt = Court) => {
    resetSelections();
    setJudge(event);
    fetchCourts(event);
  };

  const fetchPanels = (selectedJudge = judge, selectedCourt = Court) => {
    showLoader();
    axios
      .post(
        `${process.env.REACT_APP_API_NEST_URL}/judge-search/judges-panels`,
        {
          judge: selectedJudge,
          court: selectedCourt,
        },
        {
          headers: {
            Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
          },
        }
      )
      .then((res) => {
        hideLoader();
        if (res.data) {
          setPanel(res.data);
          if (Object.keys(res.data).length > 0 && searchParams.get('panelIds')) {
            const panelIdsParams = searchParams.get('panelIds');
            const panelIds = panelIdsParams ? panelIdsParams.split(',') : [];
            setSelectedPanelIds(panelIds);
            if (panelIds.length > 0) {
              fetchBenchCases(panelIds);
            }
          }
        }
      })
      .catch((err) => {
        hideLoader();
        if (err.response.status === 401) {
          logout();
        }
        toast.error('Failed to fetch panels.');
      });
  }

  const fetchStandAloneCases = async (selectedJudge = judge, selectedCourt = Court) => {
    showLoader();
    await getSingleBenchJudgments({
      judge: selectedJudge,
      court: selectedCourt,
      pageNo: casesPageNo,
      pageSize: constants.pageSize,
    }).then((res: any) => {
      if (res.data && res.totalPages && res.totalRecords) {
        if (res.data.length === 0) {
          toast.warning('No judgments found for the selected Judge.');
          setAloneCases([]);
          setTotalStandaloneCasesPages(1);
          setTotalStandaloneCases(0);
        } else {
          setAloneCases(res.data);
          setTotalStandaloneCasesPages(res.totalPages);
          setTotalStandaloneCases(res.totalRecords);
        }
      }
      hideLoader();
    })
      .catch((err) => {
        hideLoader();
        if (err.response.status === 401) {
          logout();
        }
        toast.error('Failed to fetch single bench cases.');
      });
  }

  const fetchBenchCases = (panel = selectedPanelIds) => {
    if (panel) {
      showLoader();
      axios
        .post(`${process.env.REACT_APP_API_NEST_URL}/judge-search/panel-cases`, {
          panel_case: panel,
          pageNo: casesPageNo.toString(),
          pageSize: constants.pageSize,
        },
          {
            headers: {
              Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
            },
          })
        .then((response) => {
          hideLoader();
          if (response.data?.data.length === 0) {
            toast.warning('No judgments found for the selected Judges Panel.');
            setBenchCases([]);
            setCasesTotalPages(1);
            setCasesTotalRecords(0);
          } else {
            setBenchCases(response.data?.data);
            setCasesTotalPages(response.data?.totalPages);
            setCasesTotalRecords(response.data?.totalRecords);
          }
        })
        .catch((error) => {
          hideLoader();
          if (error.response.status === 401) {
            logout();
          }
          toast.error('Failed to fetch panel cases.');
        });
    }
  }

  const fetchAllJudgments = async (selectedJudge = judge, selectedCourt = Court, selectedPageNo = casesPageNo) => {
    showLoader();
    await getAllJudgmentsByJudgeAndCourt({
      judge: selectedJudge,
      court: selectedCourt,
      pageNo: selectedPageNo,
      pageSize: constants.pageSize,
    }).then(
      (res) => {
        if (res.data && res.totalPages && res.totalRecords) {
          if (res.data.length === 0) {
            toast.warning('No judgments found for the selected Judge.');
            setAllJudgments([]);
            setTotalAllJudgmentPages(0);
            setTotalAllJudgments(0);
          } else {
            setAllJudgments(res.data);
            setTotalAllJudgmentPages(res.totalPages);
            setTotalAllJudgments(res.totalRecords);
          }
        }
        hideLoader();
      }
    ).catch((error) => {
      hideLoader();
    })
  }

  /**
   * Function to handle page change
   * @param {Event} event
   * @param {number} value
   */
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    searchParams.set('casesPageNo', value.toString());
    navigate({ search: searchParams.toString() });
    setCasesPageNo(value);
  };

  const openJudgment = (item: any) => {
    const url = '/cases/judgment?judgmentId=' + item?._id?.$oid;
    window.open(url, '_blank');
  };

  useEffect(() => {
    if (judge && selectedTab === 'alone') {
      fetchStandAloneCases();
    }
    if (selectedPanelIds && selectedTab === 'bench') {
      fetchBenchCases();
    }
    if (judge && selectedTab === 'all') {
      fetchAllJudgments();
    }
  }, [judge, casesPageNo, selectedTab, selectedPanelIds]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const casesPageNoParam = !isAuthorised() ? 1 : Number.parseInt(searchParams.get('casesPageNo') || '1');
    setCasesPageNo(casesPageNoParam);
    if (panel.length > 0 || aloneCases.length > 0) {
      const viewAsParam = searchParams.get('viewAs') || 'alone';
      setSelectedTab(viewAsParam);
    }
  }, [location.search, panel, aloneCases]);

  const getTotalPages = () => {
    switch (selectedTab) {
      case 'all':
        return totalAllJudgmentPages;
      case 'bench':
        return totalCasesPages;
      case 'alone':
        return totalStandaloneCasesPages;
      default:
        return 0;
    }
  }

  const getTotalRecords = () => {
    switch (selectedTab) {
      case 'all':
        return totalAllJudgments;
      case 'bench':
        return casesTotalRecords;
      case 'alone':
        return totalStandaloneCases;
      default:
        return 0;
    }
  }

  const casesToDisplay = () => {
    switch (selectedTab) {
      case 'all':
        return allJudgments;
      case 'bench':
        return benchCases;
      case 'alone':
        return aloneCases;
      default:
        return [];
    }
  }

  const resetSelections = () => {
    setBenchCases([]);
    setAloneCases([]);
    setSelectedTab('all');
    setSelectedPanel(null);
    setSelectedPanelIds(null);
    setTotalStandaloneCases(0);
    setCasesTotalPages(1);
    setTotalStandaloneCasesPages(1);
    setTotalStandaloneCases(0);
    setSearchInput('');
    setPanel([]);
  }

  const getContainerHeight = () => {
    if (isAuthorised()) {
      if (selectedTab == 'bench') {
        return 'h-[calc(100vh-32.5rem)]'
      }
      return 'h-[calc(100vh-23.5rem)]'
    } else {
      if (selectedTab == 'bench') {
        return 'h-[calc(100vh-34.8rem)]'
      }
      return 'h-[calc(100vh-25.8rem)]'
    }
  }

  return (
    <div className='flex flex-col gap-4 w-full'>
      <div className='flex flex-col gap-5 rounded-container'>
        <div className='flex flex-row justify-between items-center'>
          <p className='text-2xl font-bold'>Search Judgments by Judge</p>
          <SaveSearchButton />
        </div>
        <div className='flex flex-row w-100 gap-4'>
          <FormControl fullWidth size='small'>
            <Autocomplete
              size='small'
              disablePortal
              disabled={judges.length === 0}
              value={judges.length > 0 ? judges.find((c: any) => c.name === judge) : ''}
              onChange={(e: any, selectedValue: any) => {
                if (selectedValue) {
                  searchParams.set('judge', selectedValue.name.toString());
                  navigate({ search: searchParams.toString() });
                  handleChangeJudge(selectedValue.name.toString());
                } else {
                  searchParams.delete('judge');
                  setJudge('');
                }
              }}
              options={judges}
              getOptionLabel={(option: any) => option.name || ''}
              renderOption={(props: any, option: any) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={Court !== '' && judges.length === 0 ? 'No judges found' : 'Select Judge'}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth size='small'>
            <Autocomplete
              size='small'
              disablePortal
              disabled={Courts.length === 0}
              value={Courts.length > 0 ? Courts.find((c: any) => c.name === Court) : ''}
              onChange={(e: any, selectedValue: any) => {
                if (selectedValue) {
                  searchParams.set('court', selectedValue.name.toString());
                  navigate({ search: searchParams.toString() });
                  handleChangeCourt(selectedValue.name.toString());
                } else {
                  searchParams.delete('court');
                  setCourt('');
                }
              }}
              options={Courts}
              getOptionLabel={(option: any) => option.name || ''}
              renderOption={(props: any, option: any) => {
                return (
                  <li {...props} key={option.name}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={Courts.length === 0 ? 'Please Select Judge' : 'Select Profile'}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </FormControl>
        </div>
      </div>
      {(Court === '' || judge === '') ? (
        <div className='rounded-container'>
          <div>
            <p className=''>Please select Judge to view Panel/Single Judge cases.</p>
          </div>
        </div>
      ) : (
        <div className='flex flex-col gap-4'>
          <TabList tabs={tabs} activeTab={selectedTab} onTabChange={handleTabChange} />
          <div className='flex flex-col gap-4 w-full'>
            {selectedTab === 'bench' && (
              <div className='h-full gap-4 grow rounded-container'>
                <div className='flex flex-row justify-between items-center pb-0'>
                  <p className='text-2xl font-bold'>D.B or Larger Bench</p>
                </div>
                <div className='flex flex-col justify-between mt-5'>
                  {selectedPanelIds ? (
                    <div className={`overflow-auto border rounded-md`}>
                      <Table aria-label='simple table' size='small'>
                        <TableBody>
                          <TableRow key={1} className='border-b-0 show-actions-on-hover'>
                            <TableCell className='max-w-[20rem]'>
                              {selectedPanel || 'N/A'}
                            </TableCell>
                            <TableCell align='right'>
                              <IconButton
                                size='small'
                                color='primary'
                                onClick={
                                  (e) => {
                                    setBenchCases([]);
                                    setSelectedPanel(null);
                                    setSelectedPanelIds(null);
                                    setCasesPageNo(1);
                                    searchParams.delete('panelIds');
                                    searchParams.set('casesPageNo', '1');
                                    navigate({ search: searchParams.toString() });
                                  }
                                }>
                                <Cancel />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  ) : (
                    <div className={`overflow-auto border rounded-md ${!isAuthorised() ? 'h-[calc(100vh-22.8rem)]' : 'h-[calc(100vh-20.5rem)]'}`}>
                      <Table aria-label='simple table' size='small'>
                        <TableBody>
                          {Object.keys(panel).length > 0 ? (
                            Object.keys(panel).map((item: any, index) => (
                              <TableRow key={index} className='border-b-0 show-actions-on-hover' hover={true} selected={areStringArraysEqual(selectedPanelIds, panel[item])}>
                                <TableCell className='max-w-[20rem]'>
                                  {item || 'N/A'}
                                </TableCell>
                                <TableCell align='right'>
                                  <Tooltip title={(!isAuthorised() && index > 2) ? constants.tooltips.upgradePlan : ''} >
                                    <span>
                                      <Button
                                        endIcon={areStringArraysEqual(selectedPanelIds, panel[item]) ? (<ArrowBackIosNewIcon fontSize='small' />) : (<ArrowForwardIosIcon fontSize='small' />)}
                                        disabled={!isAuthorised() && index > 2}
                                        className={areStringArraysEqual(selectedPanelIds, panel[item]) ? '' : 'action'}
                                        variant='contained'
                                        size='small'
                                        color='primary'
                                        onClick={
                                          (e) => {
                                            if (areStringArraysEqual(selectedPanelIds, panel[item])) {
                                              setBenchCases([]);
                                              setSelectedPanel(null);
                                              setSelectedPanelIds(null);
                                              setCasesPageNo(1);
                                              searchParams.delete('panelIds');
                                              searchParams.set('casesPageNo', '1');
                                              navigate({ search: searchParams.toString() });
                                            } else {
                                              searchParams.set('panelIds', panel[item].join(',').toString());
                                              searchParams.set('casesPageNo', '1');
                                              navigate({ search: searchParams.toString() });
                                              setSelectedPanel(item);
                                              setSelectedPanelIds(panel[item]);
                                              fetchBenchCases(panel[item]);
                                            }
                                          }
                                        }
                                      >
                                        {
                                          (areStringArraysEqual(selectedPanelIds, panel[item])) ? 'Hide' : 'View'
                                        }
                                      </Button>
                                    </span>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            )))
                            : (
                              <TableRow><TableCell colSpan={5} align='center'>No Panels found!</TableCell></TableRow>
                            )}
                        </TableBody>
                      </Table>
                    </div>
                  )}
                </div>
              </div>
            )}
            {(benchCases.length > 0 || (selectedTab === 'alone' || selectedTab === 'all')) && (
              <div className={`flex flex-col h-full rounded-container gap-4 w-full`}>
                <div className='flex flex-row justify-between items-center pb-0'>
                  <p className='text-2xl font-bold'>Judgments</p>
                </div>
                <div className='flex flex-col justify-between'>
                  <div className={`overflow-auto border rounded-md ${getContainerHeight()}`}>
                    <Table aria-label='simple table' size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <span className='font-bold text-md'>Party Name</span>
                          </TableCell>
                          <TableCell>
                            <span className='font-bold text-md'>Appeal</span>
                          </TableCell>
                          <TableCell>
                            <span className='font-bold text-md'>Reported as</span>
                          </TableCell>
                          <TableCell>
                            <span className='font-bold text-md'>Court</span>
                          </TableCell>
                          <TableCell className='min-w-[10rem]'>
                            <span className='font-bold text-md'>Judgment Date</span>
                          </TableCell>
                          <TableCell colSpan={2}>
                            <span className='font-bold text-md'>Result</span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {casesToDisplay().length > 0 ?
                          casesToDisplay().map((item: any, index) => (
                            <TableRow key={item._id?.$oid} className='border-b-0 show-actions-on-hover' hover={true}>
                              <TableCell>
                                <div className='flex flex-row items-center gap-1'>
                                  <Tooltip title={(item.appeallant || 'N/A') + ' vs ' + (item.respondant || 'N/A')} placement='bottom-start'>
                                    <p className='max-w-[28rem] truncate'>
                                      {(item.appeallant || 'N/A') + ' vs ' + (item.respondant || 'N/A')}
                                    </p>
                                  </Tooltip>
                                  {
                                    (isAuthorised() || index <= 2) && (
                                      <Tooltip title={'View Case details'}>
                                        <div className='cursor-pointer action' onClick={(e) => {
                                          openJudgment(item);
                                        }}>
                                          <Launch className='text-primary' sx={{ fontSize: '1.2rem' }} />
                                        </div>
                                      </Tooltip>
                                    )
                                  }
                                </div>
                              </TableCell>
                              <TableCell>
                                <Tooltip placement='bottom-start' title={item.appeal}>
                                  <p className='max-w-[18rem] truncate'>
                                    {item.appeal || 'N/A'}
                                  </p>
                                </Tooltip>
                              </TableCell>
                              <TableCell>
                                <Tooltip placement='bottom-start' title={concatenateStrings(item.citationNames) || 'N/A'}>
                                  <p className='max-w-[18rem] truncate'>
                                    {item.reported ? (
                                      concatenateStrings(item.citationNames)
                                    ) : ('Unreported')}
                                  </p>
                                </Tooltip>
                              </TableCell>
                              <TableCell>
                                <Tooltip placement='bottom-start' title={item.court}>
                                  <p className='max-w-[18rem] truncate'>
                                    {item.court || 'N/A'}
                                  </p>
                                </Tooltip>
                              </TableCell>
                              <TableCell >
                                {formatDate(item.judgment_date) || 'N/A'}
                              </TableCell>
                              <TableCell className='min-w-[10rem]'>
                                {item.result || 'N/A'}
                              </TableCell>
                              <TableCell align='right'>
                                <Tooltip title={(!isAuthorised() && index > 2) ? constants.tooltips.upgradePlan : ''} placement='bottom-start'>
                                  <span>
                                    <Button
                                      disabled={!isAuthorised() && index > 2}
                                      className='w-[5.6rem] action'
                                      variant='contained'
                                      size='small'
                                      color='primary'
                                      onClick={(e) => {
                                        openJudgment(item);
                                      }}
                                    >
                                      View Detail
                                    </Button>
                                  </span>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          )) : (
                            <TableRow>
                              <TableCell colSpan={6} align={'center'}>
                                No Judgments found!
                              </TableCell>
                            </TableRow>
                          )}
                      </TableBody>
                    </Table>
                  </div>
                  <div className={searchInput === '' ? 'flex flex-row mt-4 justify-between' : 'flex flex-row mt-4 justify-end'}>
                    {searchInput === '' && (
                      <div>
                        <RecordCount pageNo={casesPageNo} pageSize={Number.parseInt(constants.pageSize)} totalRecords={getTotalRecords()} />
                      </div>
                    )}
                    <Stack spacing={2} className='flex flex-row justify-end'>
                      <Tooltip title={(!isAuthorised()) ? constants.tooltips.upgradePlan : ''} placement='left'>
                        <span>
                          <Pagination
                            color="primary"
                            disabled={!isAuthorised()}
                            page={casesPageNo}
                            count={getTotalPages()}
                            siblingCount={0}
                            boundaryCount={1}
                            onChange={handlePageChange}
                            shape={'rounded'}
                          />
                        </span>
                      </Tooltip>
                    </Stack>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default JudgmentsByJudge;