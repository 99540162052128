import React from 'react'

export const AboutSection = () => {
  return (
    <div id="about" className="flex flex-col gap-6 justify-center items-center text-center my-10 lg:my-20">
      {/* <p className="text-4xl max-w-max pb-3 border-b-2 border-b-primary">About Us</p> */}
      <p className="text-2xl lg:text-4xl">Your Journey to Legal Understanding Begins at <b className='text-primary font-bold'>EastLaw</b></p>
      <div className='flex flex-col justify-center gap-4 items-center px-5 lg:w-9/12 text-center text-md lg:text-lg'>
        <p>
          We believe in empowering individuals with clear, accessible legal knowledge, serving as your compass in navigating the complexities of law.
          At Eastlaw, we unravel the intricacies of law, providing anytime, anywhere access to legal insights. Our platform is designed to be your guide through legal labyrinths, offering clarity and solutions that unlock the understanding of legal matters.
          From simplified law at your fingertips to unveiling the essence of legal concepts, we strive to make the law more accessible, ensuring legal clarity is simplified for everyone.
        </p>
        <p>
          Join us on a journey where legal insights are made accessible, empowering your understanding of the law.
        </p>
      </div>
    </div>
  )
};