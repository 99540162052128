import React from "react";

export const ContainerLoader = ({ text = 'Loading...', isLoading = false }) => {
  return (
    <>
      {isLoading && (
        <div className="absolute inset-0 bg-black opacity-70 z-40">
          <div className="flex flex-col justify-center items-center gap-2 h-full">
            <div className="w-16 h-16 bg-white rounded-full animate-ping"></div>
          </div>
        </div>
      )}
    </>
  );
};