import React from 'react'
import { useNavigate } from 'react-router-dom';
import { constants } from '../../app.constants';
import { PublicPlansSection } from '../../containers/';
import { AboutSection } from '../../containers/AboutSection';
import { ApplicationTourSection } from '../../containers/ApplicationTourSection';

const LandingScreen = () => {
  const navigate = useNavigate();
  return (
    <div className='flex flex-col bg-white'>
      <div id='home' className='flex flex-col bg-gradient-to-t from-[#41090b] to-[#150303] py-16 md:py-32'>
        <div className='flex flex-col md:flex-row items-center justify-center gap-8 md:gap-16 md:px-5 lg:px-12 xl:px-24'>
          <div className='flex flex-col w-full md:w-1/2 gap-4 text-white px-4 md:px-0'>
            <div className='flex flex-row gap-2 items-center bg-slate-800 rounded-full shadow-lg max-w-max px-4 py-2'>
              <div className='h-4 w-4 bg-primary animate-pulse rounded-full' />
              <span className='text-sm font-semibold'>Legal Insights, Made Accessible.</span>
            </div>
            <p className='text-3xl md:text-4xl lg:text-6xl'>Pakistan's Largest Digital Legal Library</p>
            <p className='text-base md:text-lg'>A one-stop resource for legal researchers.</p>
            <button className='max-w-max bg-white text-black flex flex-row gap-4 rounded-full items-center px-4 py-2' onClick={() => navigate('/auth')}>
              <span className='font-semibold'>Get Started</span>
              <img src={constants.svgs.rightArrow} className='w-8 h-8' />
            </button>
          </div>
          <img className='w-full md:w-1/2 h-auto' src={constants.svgs.landingPageJudge} alt="Mobile Svg" />
        </div>
      </div>
      <div className='hidden lg:flex'>
        <ApplicationTourSection />
      </div>
      <div className='gap-8 py-8'>
        <PublicPlansSection />
        <AboutSection />
      </div>
    </div>
  )
}

export default LandingScreen;