import axios from "axios";
import { attachCacheBustingQueryParam, decryptString } from "../app.utils";
import { logout } from "./auth.service";

/**
 * Method for getting statutes
 * @param {string} searchTerm
 * @param {srting} pageNo 
 * @param {string} type 
 * @returns {Object}
 */
export const getStatutes = async (searchTerm: string, pageNo: number, type: string, pageSize: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_NEST_URL}/statutes-search/`, {
      params: {
        searchTerm,
        pageNo,
        type,
        pageSize,
      },
      headers: {
        Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status === 401) {
      logout();
    }
    return error;
  }
}

/**
 * Method for getting statutes by title/year
 * @param {string} searchTerm
 * @returns {Object}
 */
export const getStatutesByTitleorYear = async (search: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_NEST_URL}/statutes-search/statutes-by-title/`, {
      params: {
        searchTerm: search,
      },
      headers: {
        Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status === 401) {
      logout();
    }
    return error;
  }
};

export const getStatuteFile = async (id: any) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_NEST_URL}/statutes-search/statutes-file/${id}`,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
      },
    });
    if (response) {
      return response;
    }
  } catch (error: any) {
    if (error.response.status === 401) {
      logout();
    }
    return error;
  }
}

export const getStatuteSections = async (id: any) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_NEST_URL}/statutes-search/statutes-sections/${id}`,
      headers: {
        Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status === 401) {
      logout();
    }
    return error;
  }
}

export const getStatuteById = async (id: any) => {
  try {
    let url = attachCacheBustingQueryParam(`${process.env.REACT_APP_API_NEST_URL}/statutes-search/retrieve-whole-statute-by-id/${id}`);
    const response = await axios({
      method: 'GET',
      url: url, headers: {
        Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
      },
    });
    return response;
  } catch (error: any) {
    if (error.response.status === 401) {
      logout();
    }
    return error;
  }
}

export const getStatuteCasesById = async (id: any, pageNo: any, pageSize: any) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_NEST_URL}/case-search/statute/${id}`,
      params: {
        pageNo,
        pageSize
      },
      headers: {
        Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status === 401) {
      logout();
    }
    return error;
  }
}

export const uploadStatute = async (requestBody: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_NEST_URL}/statutes-search`, requestBody, {
      headers: {
        Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`,
        'Content-Type': 'multipart/form-data',
      }
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status === 401) {
      logout();
    }
    return error;
  }
}
