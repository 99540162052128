import { CheckCircle } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useLoader } from "../../providers";

const EmailVerification = () => {
  const { id } = useParams();
  const { showLoader, hideLoader } = useLoader();
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);
  const [formValue, setFormValues] = useState({
    id: '',
    password: '',
    cPassword: '',
  });
  const [formErrors, setFormErrors] = useState<any>({});
  const [touchedFields, setTouchedFields] = useState<any>({});

  const handleFieldBlur = (event: any) => {
    const { name } = event.target;
    setTouchedFields((prevTouched: any) => {
      return { ...prevTouched, [name]: true };
    });
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    let newErrors: any = {};
    let updatedFormValue = { ...formValue, [name]: value };
    switch (name) {
      case 'password':
        if (!value) {
          newErrors.password = 'Password is required';
        } else if (value.length < 8) {
          newErrors.password = 'Password length must have atleast 8 characters';
        } else {
          delete formErrors.password;
        }
        if (formValue.cPassword) {
          formValue.cPassword += '';
        }
        break;
      case 'cPassword':
        if (!value) {
          newErrors.cPassword = 'Confirmation password is required';
        } else if (value !== formValue.password) {
          newErrors.cPassword = 'Password not matched';
        } else {
          delete formErrors.cPassword;
        }
        break;
      default:
        break;
    }

    setFormValues(updatedFormValue);
    setFormErrors((prevErrors: any) => {
      return { ...prevErrors, ...newErrors };
    });
  };

  const verifyUser = () => {
    showLoader();
    axios.post(`${process.env.REACT_APP_API_NEST_URL}/users/verify/${id}`).then(
      (res: any) => {
        if (res.status === 201) {
          setIsVerified(true);
        }
        hideLoader();
      }
    ).catch(
      (err: any) => {
        hideLoader();
        setIsVerified(false);
      }
    );
  }
  const resendEmail = () => {
    showLoader();
    axios.post(`${process.env.REACT_APP_API_NEST_URL}/users/resend-email/${id}`).then(
      (res: any) => {
        hideLoader();
        if (res.status === 201) {
          toast.success('Verification email sent successfully.');
          navigate('/auth');
        }
      }
    ).catch(
      (err: any) => {
        hideLoader();
        toast.error('Failed to send verification email.');
      }
    );
  }
  const updatePassword = () => {
    showLoader();
    axios.put(`${process.env.REACT_APP_API_NEST_URL}/auth/set-password`, { id, password: formValue.password }).then(
      (res: any) => {
        if (res.status === 201 || res.status === 200) {
          verifyUser();
        }
        hideLoader();
      }
    ).catch(
      (err: any) => {
        hideLoader();
        setIsVerified(false);
      }
    );
  }

  return (
    <div className="flex flex-col w-full h-[calc(100vh-6rem)] justify-center items-center bg-gradient-to-t from-[#41090b] to-[#150303]">
      <div className="px-8 py-6 rounded-container w-2/3 lg:w-[30rem]">
        {isVerified ? (<CheckCircle sx={{ fontSize: '4rem', color: 'MediumSeaGreen' }} />) : null}
        <p className="text-lg">{isVerified ? 'Password updated successfully' : null}</p>
        {isVerified ? (
          <Button variant='contained' color='primary' className='w-[10rem] mt-4' onClick={() => navigate('/auth')}>Sign in</Button>
        ) : (
          <>
            <form className="flex flex-col gap-4">
              <div className="flex flex-col gap-2 w-full h-full">
                <p className="text-2xl font-bold my-2 text-primary">Set Password</p>
                <label className='text-gray-500'>Password<b className="text-primary ml-1">*</b></label>
                <TextField
                  size="small"
                  name="password"
                  type="password"
                  placeholder="Enter your password"
                  value={formValue.password}
                  onChange={handleInputChange}
                  onBlur={handleFieldBlur}
                  error={!!(formErrors.password && touchedFields.password)}
                  helperText={touchedFields.password ? formErrors.password : ""}
                  required
                />
                <label className='text-gray-500'>Confirm Password<b className="text-primary ml-1">*</b></label>
                <TextField
                  size="small"
                  required
                  id="userPass"
                  name="cPassword"
                  type="password"
                  placeholder="Confirm your password"
                  value={formValue.cPassword}
                  onChange={handleInputChange}
                  onBlur={handleFieldBlur}
                  error={!!(formErrors.cPassword && touchedFields.cPassword)}
                  helperText={touchedFields.cPassword ? formErrors.cPassword : ""}
                />

              </div>
              <Button
                className="w-full"
                disabled={(formValue.cPassword.length === 0 || formValue.password.length === 0) || Object.keys(formErrors).length > 0}
                variant="contained"
                color='primary'
                onClick={updatePassword}>Update Password</Button>
              <Button
                className="w-full"
                variant="contained"
                color='primary'
                onClick={() => resendEmail()}>Re-send Verification Email</Button>
            </form>
          </>)
        }

      </div>
    </div>
  );
};

export default EmailVerification;