import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import CloseIcon from '@mui/icons-material/Close';
import LocalOfferIcon from '@mui/icons-material/LocalOffer'; // Icon for Promotion
import AnnouncementIcon from '@mui/icons-material/Announcement'; // Icon for News
import { Newspaper } from "@mui/icons-material";
import { fetchActiveBanners } from "../services/banners.service";

// Mapper object to map types to icons
const typeIconMapper: { [key: string]: JSX.Element } = {
  'Promotion': <LocalOfferIcon className="text-white" />,
  'News': <Newspaper className="text-white" />,
};

export const Banner = () => {
  const [show, setShow] = useState(false);
  const [bannerItems, setBannerItems] = useState<any>([]);

  useEffect(() => {
    fetchActiveBanners().then((res) => {
      if (res.data.length > 0) {
        setBannerItems(res.data);
        setShow(true);
      }
    }).catch((err) => {})
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      {show && bannerItems && (
        <div className='bg-red-600 flex flex-row px-3 py-4 text-white items-center z-[100] shadow-lg w-full'>
          <Marquee className='flex-1' autoFill>
            {bannerItems.map((banner: any) => (
              <div key={banner.id} className="flex flex-row gap-2 px-5 items-center">
                {typeIconMapper[banner.type] || <AnnouncementIcon className="text-white" />} {/* Default to News icon if type not found */}
                <span>{banner.title}</span>
              </div>
            ))}
          </Marquee>
          <button onClick={handleClose} className="ml-4">
            <CloseIcon className="text-white" />
          </button>
        </div>
      )}
    </>
  );
}
