// React import
import React, { useEffect } from 'react';

// Material import
import Box from '@mui/material/Box';
import ProtectedRoutes from '../routes/ProtectedRoutes';
import { Info } from '@mui/icons-material';
import { decryptString, isAuthorised } from '../app.utils';
import { useNavigate } from 'react-router-dom';
import { Navigation } from '../components';

// Main Container
export const SecureLayout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const requestLink = localStorage.getItem('requestLink');
    if (requestLink) {
      navigate(requestLink);
      setTimeout(() => localStorage.removeItem('requestLink'), 500);
    }
  }, []);
  const upgradeRequestSent = localStorage.getItem('upgradeRequestSent') === 'true';
  const user = decryptString(localStorage.getItem('username') || '');
  const role = decryptString(localStorage.getItem('role'));
  return (
    <div className='flex flex-col w-full min-h-screen bg-[#ececec] font-inter'>
      {!isAuthorised() && (
        <div className='bg-primary flex flex-row px-3 py-2 text-sm text-white items-center z-[100] shadow-lg w-full'>
          <Info fontSize='small' />
          {role === 'organization' && localStorage.getItem('outOfOrganization') === 'true' ? (
            <span className='mx-1'>
              You do not have permission to access this resource. You might be accessing outside of your organization’s network.
            </span>
          ) : (
            upgradeRequestSent ? (
              <span className='mx-1'>
                You have requested an upgrade to Premium. Please follow instructions in the email received.
              </span>
            ) : (
              <span className='mx-1'>
                Upgrade to our premium plan for full access to all features. <a className='underline cursor-pointer' onClick={() => { navigate('/plans') }}>Click here</a> to upgrade to premium.
              </span>
            )
          )}
        </div>
        )
      }
      <div className={`flex flex-row ${!isAuthorised() ? 'h-[calc(100vh-2.25rem)]' : 'h-screen'} w-full`}>
        <div className='flex-row w-full flex'>
          <div className='flex-1 z-50'>
            <Navigation />
          </div>
          <div className='flex flex-col w-full overflow-x-auto relative'>
            <div className='w-full h-screen overflow-auto'>
              <Box component='main' className='flex flex-1 h-full flex-col w-full p-4 z-10'>
                <ProtectedRoutes />
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};