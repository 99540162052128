import { Tooltip, TextField, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Stack, Pagination, Divider, DialogContent, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { constants } from '../../app.constants';
import { calculateDaysLeft, isAuthorised } from '../../app.utils';
import { Edit, EmailRounded } from '@mui/icons-material';
import { getAllUsers } from '../../services/user.service';
import { withAccess } from '../../services/role-guard.service';
import { Role } from '../../enums';
import { sendVerificationEmail } from '../../services/auth.service';
import { toast } from 'react-toastify';
import { useDialog } from '../../providers';
import { UpdatedUserPlan } from '../../dialogs';
import { BackButton, SkeletonLoading } from '../../components';

const UserManagement = () => {
  const { openDialog, closeDialog } = useDialog();
  const [users, setUsers] = useState<any>([]);

  const [filteredUsers, setFilteredUsers] = useState<any>([]);
  const [userSearchInput, setUsersearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userPageNo, setUserPageNo] = useState(1);
  const [totalUserPages, setTotalUserPages] = useState(1);

  const [selectedUserRole, setSelectedUserRole] = useState('');

  const [selectedUser, setSelectedUser] = useState<any>({ email: '', planId: '' });

  const searchUserFilter = (e: any) => {
    e.preventDefault();
    fetchUsers(userSearchInput);
  };

  useEffect(() => {
    const tempUsers = structuredClone(users);
    setFilteredUsers(tempUsers);
  }, [users]);

  useEffect(() => {
    fetchUsers();
  }, [userPageNo, selectedUserRole]);

  const fetchUsers = async (searchTerm = '', role = selectedUserRole, page = userPageNo) => {
    setIsLoading(true);
    await getAllUsers(searchTerm, role, page, 100).then(
      (res) => {
        setIsLoading(false);
        if (res.data) {
          setUsers(res.data);
          setTotalUserPages(res.totalPages);
        }
      }
    ).catch((err) => setIsLoading(false));
  }

  /**
   * Function to handle bookmark page change
   * @param {Event} event
   * @param {number} value
   */
  const handleUserPageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setUserPageNo(value);
  };


  const dispatchVerificationEmail = (userData: { id: string, email: string, loginFirstName: string }) => {
    sendVerificationEmail(userData).then((res) => {
      toast.success(`Verification email sent successfully to ${userData.email}`);
    }).catch(
      (err) => {
        toast.success(`Failed to send verification email to ${userData.email}`);
      }
    );
  }

  const openUpdateUserPlanDialog = (email: string, planId: string) => {
    if (email !== '' && planId !== '') {
      openDialog(UpdatedUserPlan, {
        onClose: () => {
          closeDialog();
          fetchUsers();
        },
        data: {
          email,
          planId,
        }
      });
    }
  }

  return (
    <>
      <div className='flex flex-col gap-2 w-full'>
        <div className='flex flex-col justify-between rounded-container gap-3'>
          <div className='flex flex-row justify-start items-start'>
            <BackButton />
          </div>
          <p className='text-3xl font-bold'>User Managment</p>
          <Divider />
          <div className='flex flex-row items-center mt-4 pb-0'>
            <form className='flex flex-row gap-2 items-center w-full' onSubmit={searchUserFilter}>
              <TextField
                className='w-full'
                size='small'
                type='text'
                placeholder='Type user email'
                value={userSearchInput}
                onChange={(e) => {
                  setUsersearchInput(e.target.value)
                }}
              />
              <Button
                type='submit'
                variant='contained'
                color='primary'
                onClick={searchUserFilter}
              >
                Search
              </Button>
            </form>
          </div>
          <div className='flex flex-row gap-4 items-center justify-end'>
            <b>Show: </b>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="user-types-label"
                defaultValue=""
                name="user-types"
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 16,
                  },
                }}
                onChange={(event) => setSelectedUserRole(event.target.value)}
              >
                <FormControlLabel value="" control={<Radio />} label="All" />
                <FormControlLabel value="premium" control={<Radio />} label="Only Premium" />
                <FormControlLabel value="free" control={<Radio />} label="Only Free" />
              </RadioGroup>
            </FormControl>

          </div>
          <div className='flex flex-col justify-between h-full'>
            <div className={`h-[calc(100vh-21.3rem)] overflow-auto border rounded-md`}>
              {isLoading ? (<SkeletonLoading isLoading={isLoading} />) : (
                <Table aria-label='simple table' size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <span className='font-bold text-md'>Name</span>
                      </TableCell>
                      <TableCell>
                        <span className='font-bold text-md'>Email</span>
                      </TableCell>
                      <TableCell className='min-w-[8rem]'>
                        <span className='font-bold text-md'>Email Verified</span>
                      </TableCell>
                      <TableCell className='min-w-[8rem]'>
                        <span className='font-bold text-md'>Phone Number</span>
                      </TableCell>
                      <TableCell>
                        <span className='font-bold text-md'>City</span>
                      </TableCell>
                      <TableCell>
                        <span className='font-bold text-md'>Country</span>
                      </TableCell>
                      <TableCell>
                        <span className='font-bold text-md'>Role</span>
                      </TableCell>
                      <TableCell>
                        <span className='font-bold text-md'>Plan</span>
                      </TableCell>
                      <TableCell className='min-w-[10rem]'>
                        <span className='font-bold text-md'>Plan Expiry</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredUsers.length > 0 ? (
                      filteredUsers.map((item: any, index: number) => (
                        <TableRow key={item.id} className='border-b-0 show-actions-on-hover' hover={true}>
                          <TableCell className='max-w-[12rem] truncate'>
                            {item.name || 'N/A'}
                          </TableCell>
                          <TableCell className='max-w-max'>
                            {item.email || 'N/A'}
                          </TableCell>
                          <TableCell className='max-w-max'>
                            <div className='flex flex-row gap-1 items-center'>
                              <span className={item.emailVerified ? 'bagde-email-verified-yes' : 'bagde-email-verified-no'}>{item.emailVerified ? 'Yes' : 'No'}</span>
                              {!item.emailVerified && (
                                <Tooltip title={'Send Verfication email to ' + item.email}>
                                  <EmailRounded className='cursor-pointer' onClick={() => dispatchVerificationEmail({ id: item.id, email: item.email, loginFirstName: item.name })} color='success' fontSize='small' />
                                </Tooltip>
                              )}
                            </div>
                          </TableCell>
                          <TableCell className='max-w-[12rem] truncate'>
                            {item.phone || 'N/A'}
                          </TableCell>
                          <TableCell className='max-w-[12rem] truncate capitalize'>
                            {item.city || 'N/A'}
                          </TableCell>
                          <TableCell className='max-w-[12rem] truncate capitalize'>
                            {item.country || 'N/A'}
                          </TableCell>
                          <TableCell className='max-w-max truncate capitalize'>
                            <Tooltip title={item.role === 'organization' && item.orgDetails ? (item.orgDetails.orgName + ' - ' + item.orgDetails.orgType) : ''}>
                              <span className={item.role === 'organization' ? 'cursor-pointer' : ''}>{item.role || 'N/A'}</span>
                            </Tooltip>
                          </TableCell>
                          <TableCell className='max-w-max truncate'>
                            <div className='flex flex-row items-center gap-2'>
                              <span>{item.plan || 'N/A'}</span>
                              <Tooltip title={'Update User Plan'} placement='bottom'>
                                <span className='action'>
                                  <IconButton size='small'
                                    onClick={() => openUpdateUserPlanDialog(item.email, item.planId)}
                                    sx={{
                                      zIndex: '10',
                                      color: '#D91E25',
                                      ':hover': {
                                        color: '#D91E25',
                                      },
                                    }}
                                  >
                                    <Edit fontSize='small' />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </div>
                          </TableCell>
                          <TableCell className='max-w-max truncate'>
                            {calculateDaysLeft(item.planExpiring)}
                          </TableCell>

                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={12} align={'center'}>
                          No Users found!
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </div>
            <div className='flex flex-row justify-end'>
              <Stack spacing={2} className='flex flex-row mt-4 justify-end'>
                <Tooltip title={(!isAuthorised()) ? constants.tooltips.upgradePlan : ''} placement='left'>
                  <span>
                    <Pagination
                      color='primary'
                      page={userPageNo}
                      disabled={!isAuthorised()}
                      count={totalUserPages}
                      siblingCount={0}
                      boundaryCount={1}
                      onChange={handleUserPageChange}
                      shape={'rounded'}
                    />
                  </span>
                </Tooltip>
              </Stack>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default withAccess(UserManagement, [Role.ADMIN, Role.USER_MANAGER]);