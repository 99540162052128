import { Autocomplete, Button, Checkbox, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, createFilterOptions } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { decryptString } from '../../app.utils';
import { BackButton } from '../../components/BackButton';
import { useDialog, useLoader } from '../../providers';
import { getPracticeAreas } from '../../services/practice-areas.service';
import { constants } from '../../app.constants';
import { FileUploader } from '../../components';
import { useLocation } from 'react-router-dom';
import { getStatuteById } from '../../services/statutes.service';
import { StatuteDocumentPreview } from '../../dialogs';

const EditStatute = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const statuteId = searchParams.get('statuteId') || '';

  const [statute, setStatute] = useState<any>(null);
  const [formErrors, setFormErrors] = useState<any>({});
  const [touchedFields, setTouchedFields] = useState<any>({});
  const [selectedPracticeAreas, setSelectedPracticeAreas] = useState<any>([]);
  const [practiceAreas, setPracticeAreas] = useState<any>([]);

  const { showLoader, hideLoader } = useLoader();
  const { openDialog, closeDialog } = useDialog();

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    limit: 10,
  });

  const [formValue, setFormValues] = useState({
    title: '',
    date: '',
    act: '',
    type: '',
    group: '',
    subGroup: '',
  });

  useEffect(() => {
    fetchPracticeAreas();
  }, []);

  const fetchPracticeAreas = async () => {
    showLoader();
    await getPracticeAreas().then((res) => {
      setPracticeAreas(res);
      reloadStatute(res);
    }).catch(() => {
      toast.error('Failed to fetch Practice Areas.');
    }).finally(() => hideLoader());
  }

  const reloadStatute = async (practiceAreasTemp = practiceAreas) => {
    showLoader();
    await getStatuteById(statuteId)
      .then((res) => {
        setStatute(res.data);
        setFormValues({
          title: res.data.title,
          date: res.data.date,
          act: res.data.act,
          type: res.data.type,
          group: res.data.group,
          subGroup: res.data.sub_group,
        });
        if (practiceAreasTemp.length > 0 && res.data.practice_area.length > 0) {
          const practiceAreasMap = res.data.practice_area.map((area: string) =>
            practiceAreasTemp.find((practiceArea: any) => practiceArea.id === area)
          );
          setSelectedPracticeAreas(practiceAreasMap);
        }
      })
      .catch(() => toast.error('Failed to fetch Statute.'))
      .finally(() => hideLoader());
  }

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    let newErrors: any = {};
    let updatedFormValue = { ...formValue, [name]: value };
    switch (name) {
      case 'title':
        if (!value) {
          newErrors.title = 'Title is required';
        } else {
          delete formErrors.title;
        }
        break;
      case 'act':
        if (!value) {
          newErrors.act = 'Act is required';
        } else {
          delete formErrors.act;
        }
        break;
      case 'date':
        if (!value) {
          newErrors.date = 'Date is required';
        } else {
          delete formErrors.date;
        }
        break;
      case 'type':
        if (!value) {
          newErrors.type = 'type is required';
        } else {
          delete formErrors.type;
        }
        break;
      case 'group':
        if (!value) {
          newErrors.group = 'group is required';
        } else {
          delete formErrors.group;
        }
        break;
      case 'subGroup':
        if (!value) {
          newErrors.subGroup = 'subGroup is required';
        } else {
          delete formErrors.subGroup;
        }
        break;
      default:
        break;
    }
    setFormValues(updatedFormValue);
    setFormErrors((prevErrors: any) => {
      return { ...prevErrors, ...newErrors };
    });
  };

  const handleFieldBlur = (event: any) => {
    const { name } = event.target;
    setTouchedFields((prevTouched: any) => {
      return { ...prevTouched, [name]: true };
    });
  };

  const [uploadedFiles, setUploadedFiles] = useState<any>(null);

  // Callback function to receive selected files from FileUploader
  const handleFilesUploaded = (files: any) => {
    setUploadedFiles(files);
  };

  const isStatuteSelected = (statute: any) => {
    return selectedPracticeAreas.some((selectedStatute: any) => selectedStatute.id === statute.id);
  };

  const handleStatuteSelection = (event: any, value: any) => {
    if (!event.target.checked) {
      setSelectedPracticeAreas((prevSelectedPracticeAreas: any) =>
        prevSelectedPracticeAreas.filter((statute: any) => statute.id !== value.id)
      );
    }
  };

  const previewStatuteDoc = async () => {
    openDialog(StatuteDocumentPreview, {
      onClose: () => {
        closeDialog();
      },
      statute: { selectedStatute: statuteId, selectedStatuteTitle: statute?.title, selectedStatuteAct: statute?.act || '' }
    });
  }

  const UpdateStatute = () => {
    let practiceAreasIds = selectedPracticeAreas.map((area: any) => area.id);
    const formData = new FormData();
    formData.append('title', formValue.title);
    formData.append('act', formValue.act);
    formData.append('type', formValue.type);
    formData.append('group', formValue.group);
    formData.append('sub_group', formValue.subGroup);
    // Append each practice area ID separately
    practiceAreasIds.forEach((id: string) => {
      formData.append('practice_area[]', id);
    });
    if (uploadedFiles !== null) {
      formData.append('files', uploadedFiles[0]);
    }
    axios.patch(`${process.env.REACT_APP_API_NEST_URL}/statutes-search/${statuteId}`, formData, {
      headers: {
        Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`,
        'Content-Type': 'multipart/form-data',
      }
    }).then(() => {
      toast.success('Statute Updated Successfully.');
      reloadStatute();
    }).catch(() => {
      toast.error('Failed to update Statute Data.');
    });
  }

  return (
    <div className='flex flex-1 flex-col gap-4 w-full h-full'>
      <div className='flex flex-col flex-1 rounded-container gap-6'>
        <div className='flex flex-col gap-2'>
          <div className='flex flex-row justify-start items-start'>
            <BackButton />
          </div>
          <p className='text-2xl font-bold'>Edit Statute</p>
          <Divider />
        </div>
        <div className='flex flex-col gap-4 max-w-[40rem]'>
          <div className='flex flex-col gap-2 w-full'>
            <p className='font-bold pt-1'>Title <span className='mr-1 text-red-600 font-semibold'>*</span></p>
            <FormControl fullWidth>
              <TextField
                size='small'
                placeholder='Enter Statute Title'
                variant='outlined'
                name='title'
                type={'text'}
                required
                onChange={handleInputChange}
                value={formValue.title}
                onBlur={handleFieldBlur}
                error={!!(formErrors.title && touchedFields.title)}
                helperText={touchedFields.title ? formErrors.title : ''}
              />
            </FormControl>
          </div>

          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full'>
              <p className='font-bold pt-1'>Act <span className='mr-1 text-red-600 font-semibold'>*</span></p>
              <FormControl fullWidth>
                <TextField
                  size='small'
                  placeholder='Enter Statute Act'
                  variant='outlined'
                  name='act'
                  type={'text'}
                  required
                  onChange={handleInputChange}
                  value={formValue.act}
                  onBlur={handleFieldBlur}
                  error={!!(formErrors.act && touchedFields.act)}
                  helperText={touchedFields.act ? formErrors.act : ''}
                />
              </FormControl>
            </div>
            <div className='flex flex-col gap-2 w-full'>
              <p className='font-bold pt-1'>Date</p>
              <FormControl fullWidth>
                <TextField
                  size='small'
                  placeholder='Enter Date'
                  variant='outlined'
                  name='date'
                  type={'text'}
                  required
                  disabled
                  onChange={handleInputChange}
                  value={formValue.date}
                  onBlur={handleFieldBlur}
                  error={!!(formErrors.date && touchedFields.date)}
                  helperText={touchedFields.date ? formErrors.date : ''}
                />
              </FormControl>
            </div>
          </div>

          <div className='flex flex-row gap-4 my-4'>
            <FormControl fullWidth size='small'>
              <InputLabel id='select-type'>Select Type <span className='mr-1 text-red-600 font-semibold'>*</span></InputLabel>
              <Select
                labelId='select-type'
                id='demo-simple-select-helper'
                variant='outlined'
                value={formValue.type}
                label='Select Type'
                name='type'
                required
                onChange={handleInputChange}
                onBlur={handleFieldBlur}
                error={!!(formErrors.type && touchedFields.type)}
              >
                {constants.statuteTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>{type.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth size='small'>
              <InputLabel id='select-type'>Select Group <span className='mr-1 text-red-600 font-semibold'>*</span></InputLabel>
              <Select
                labelId='select-type'
                id='demo-simple-select-helper'
                variant='outlined'
                value={formValue.group}
                label='Select Group'
                name='group'
                required
                onChange={handleInputChange}
                onBlur={handleFieldBlur}
                error={!!(formErrors.group && touchedFields.group)}
              >
                {constants.statuteGroups.map((group) => (
                  <MenuItem key={group.title} value={group.title}>{group.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth size='small'>
              <InputLabel id='select-type'>Select Sub-Group <span className='mr-1 text-red-600 font-semibold'>*</span></InputLabel>
              <Select
                labelId='select-type'
                id='demo-simple-select-helper'
                variant='outlined'
                value={formValue.subGroup}
                label='Select Sub-Group'
                required
                name='subGroup'
                onChange={handleInputChange}
                onBlur={handleFieldBlur}
                error={!!(formErrors.subGroup && touchedFields.subGroup)}
              >
                {constants.statuteSubGroups.map((group) => (
                  <MenuItem key={group.title} value={group.title}>{group.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {/* Practice Areas */}
          <div className={`flex flex-col gap-3 w-[40rem]`}>
            <p className='font-bold'>Practive Areas(s) <span className='mr-1 text-red-600 font-semibold'>*</span></p>

            <FormControl fullWidth size='small'>
              <Autocomplete
                multiple
                filterOptions={filterOptions}
                options={practiceAreas || []}
                getOptionLabel={(option) => option.title || ''}
                value={selectedPracticeAreas}
                onChange={(event, value) => setSelectedPracticeAreas(value)}
                size='small'
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={selectedPracticeAreas.length === 0 ? 'Select Practice Areas' : ''}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} className='border-[0.5px] border-gray-200 py-1 px-2'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isStatuteSelected(option)}
                          color='primary'
                          onChange={(event) => handleStatuteSelection(event, option)}
                        />
                      }
                      label={option.title}
                    />
                  </li>
                )}
              />
            </FormControl>
          </div>



          {/* Use the FileUploader component and pass the callback function */}
          <div className='flex flex-col gap-2 w-full'>
            <p className='font-bold pt-1'>Statute Document</p>
            <div className='flex flex-row gap-2 items-center'>
              <b>Current File:</b>
              <p>{statute?.pdf_file_name} <span className='underline text-blue-900 text-sm cursor-pointer' onClick={previewStatuteDoc}>Click to Preview</span></p>
            </div>
            <FileUploader onFilesUploaded={handleFilesUploaded} containerLabel='Change Document' multiple={false} />
          </div>
        </div>

        <Divider />

        <Button
          type='submit'
          className='w-60'
          variant='contained'
          color='primary'
          onClick={UpdateStatute}
        >
          Save Changes
        </Button>

      </div>
    </div>
  );
};

export default EditStatute;