import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import {
  Badge,
  Button,
  Divider,
  Tooltip,
} from "@mui/material";
import { logout } from '../../services/auth.service';
import { attachCacheBustingQueryParam, decryptString, formatDate, isAdmin, isAuthorised, isDataOperator } from '../../app.utils';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowCircleLeft, Edit, ExpandLess, ExpandMore, Launch, SaveAs } from '@mui/icons-material';
import { BookmarkTypes } from '../../app.enums';
import ReactToPrint from "react-to-print";

import * as html2pdf from "html2pdf.js"
import { toast } from 'react-toastify';
import { useLoader } from '../../providers';
import { TopPanel } from '../../components';
import { JudgmentPrint } from '../../containers';

const Judgment = () => {
  const { loading, showLoader, hideLoader } = useLoader();
  const componentRef = useRef(null);
  const location = useLocation();
  const hasPreviousPage = location.key !== 'default';
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = React.useState('citations');
  const [doc, setDoc] = useState({ highlighter: false, bookmark: false, id: '', type: BookmarkTypes.CASE, header: '', detail: '' });
  const [judgementData, setJudgementData] = useState<any>(
    {
      id: '',
      appeal: '',
      judgment: '',
      result: '',
      case_summary: ''
    }
  );
  const [subsequentReferences, setSubsequentReferences] = useState([]);
  const [subsequentReferencesOpen, setSubsequentReferencesOpen] = useState(false);
  const [judgmentTextOpen, setJudgmentTextOpen] = useState(true);
  const [summaryTextOpen, setSummaryTextOpen] = useState(false);


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const judgmentId = searchParams.get('judgmentId') || '';
    const judgmentSearchTerm = searchParams.get('searchTerm') || ''; // TODO: Send in Get Case detail request

    let getCaseDetailURL = judgmentSearchTerm === '' ? `${process.env.REACT_APP_API_NEST_URL}/case-search/search-by-id/${judgmentId}` : `${process.env.REACT_APP_API_NEST_URL}/case-search/search-by-id/${judgmentId}?searchQuery=${judgmentSearchTerm}`;
    if (isAdmin() || isDataOperator()) {
      getCaseDetailURL = attachCacheBustingQueryParam(getCaseDetailURL);
    }
    showLoader();
    axios
      .get(
        getCaseDetailURL, {
        headers: {
          Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`,
        },
      }
      )
      .then((res) => {
        if (res.data) {
          setJudgementData(res.data)
          if (res.data.referenced_judgments) {
            getSubsequentReferences(res.data.referenced_judgments, judgmentId);
          }
          hideLoader();
        }
      })
      .catch((err) => {
        hideLoader();
        if (err.response.status === 401) {
          logout();
        }
      });
  }, [])

  const getSubsequentReferences = (referenced_judgments: any, judgmentId: any) => {
    showLoader();
    let requestURL = `${process.env.REACT_APP_API_NEST_URL}/case-search/get-subsequent-references`;
    if (isAdmin() || isDataOperator()) {
      requestURL = attachCacheBustingQueryParam(requestURL);
    }
    axios
      .post(
        requestURL, {
        judgment_ids: referenced_judgments,
        citation: judgmentId,
      }, {
        headers: {
          Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`,
        },
      }
      )
      .then((res) => {
        hideLoader();
        if (res.data?.length > 0) {
          setSubsequentReferences(res.data)
        }
      })
      .catch((err) => {
        hideLoader();
        if (err.response.status === 401) {
          logout();
        }
      });
  }

  useEffect(() => {
    setDoc({
      ...doc,
      id: judgementData?.id,
      header: judgementData.appeallant + ' vs ' + judgementData.respondant,
      detail: judgementData.result || 'N/A'
    });
  }, [judgementData]);

  const checkIfJudgeIsAuthor = (authorId: string, judgeId: string) => {
    if (authorId && judgeId !== '') {
      return authorId === judgeId;
    }
    return false;
  }

  const handleDownloadPDF = async () => {
    const element = document.getElementById('html-content');

    if (element) {
      const opt = {
        margin: 17.018,
        jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait' },
        html2canvas: { scale: 4, useCORS: true, dpi: 192, letterRendering: true },
      };
      try {
        html2pdf().set(opt).from(element)
          .toPdf()
          .get('pdf')
          .then((pdf: any) => {
            const totalPages = pdf.internal.getNumberOfPages();

            for (let i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(10);
              pdf.setTextColor(150);
              pdf.text(`${i}/${totalPages}`, pdf.internal.pageSize.getWidth() - 21, pdf.internal.pageSize.getHeight() - 6);
            }
            pdf.save(`${(judgementData.appeallant || 'N/A') + ' vs ' + (judgementData.respondant || 'N/A')}.pdf`);
          });
      } catch (err) {
        console.log('Failed to generate pdf => ', err)
      }
    }
  };

  const openJudgment = (index: number, caseId: string) => {
    if (!isAuthorised() && index > 2) {
      toast.warning('Please upgrade your plan to view the details of this case.');
    } else {
      const url = `/cases/judgment?judgmentId=${caseId}`;
      window.open(url, '_blank');
    }
  };

  return (
    <div className='flex flex-col flex-1 w-full rounded-container relative'>
      <div className="flex flex-row justify-between items-center mx-1 mb-2">
        <Button
          disabled={!hasPreviousPage}
          color='primary'
          startIcon={<ArrowCircleLeft fontSize='small' />}
          onClick={() => navigate(-1)}>
          <p className='text-lg'>Back</p>
        </Button>
        {
          isAdmin() || isDataOperator() ? (
            <div className='flex flex-row gap-4 items-center'>
              <Button variant="outlined" endIcon={<Edit />} onClick={() => navigate('/edit/judgment?judgmentId=' + judgementData.id)}>
                Edit Judgment
              </Button>
              <TopPanel doc={doc} setDoc={setDoc}></TopPanel>
            </div>
          ) : (<TopPanel doc={doc} setDoc={setDoc}></TopPanel>)
        }
      </div>
      {!loading && (
        <div className="px-3">
          <p className="text-2xl font-bold mb-5">{(judgementData.appeallant || 'N/A') + ' vs ' + (judgementData.respondant || 'N/A')}<br /><span className='mb-5 text-lg font-normal'>{judgementData.appeal || 'N/A'}</span></p>
          <div className='float-right flex flex-col gap-2 mb-3 ml-4 bg-stone-50 border border-slate-300 rounded-xl shadow-lg p-4 w-[26rem] min-h-max'>
            <p className='text-xl text-slate-500 text-center my-4'><b>Judgment</b> Details</p>
            <div className='flex flex-row gap-3 justify-between items-center mb-4 p-3 bg-slate-200 rounded-xl shadow-lg'>
              <div className='flex flex-col bg-black text-center font-bold py-3 w-full rounded-xl'>
                <p className='text-slate-300 font-light text-sm'>Cited by</p>
                <p className='text-white text-2xl'>{judgementData.referenced_judgments?.length || '0'}</p>
              </div>
              <div className='flex flex-col bg-black text-center font-bold py-3 w-full rounded-xl'>
                <p className='text-slate-300 font-light text-sm '>Citing</p>
                <p className='text-white text-2xl'>{judgementData.hyperlinking?.length || '0'}</p>
              </div>
              <div className='flex flex-col bg-black text-center font-bold py-3 w-full rounded-xl'>
                <p className='text-slate-300 font-light text-sm'>Coram</p>
                <p className='text-white text-2xl'>{judgementData.judgesId?.length || '0'}</p>
              </div>
            </div>
            <div className='flex flex-col mb-3 text-sm'>
              <p className='mb-2'>
                <b>Appeallant:</b>{" "}
                {judgementData.appeallant || 'N/A'}
              </p>
              <p className='mb-2'>
                <b>Respondant:</b>{" "}
                {judgementData.respondant || 'N/A'}
              </p>
              <p className='mb-2'>
                <b>Result:</b>{" "}
                {judgementData.result || "N/A"}
              </p>
              <p className='mb-2'>
                <b>Judgment Date:</b>{" "}
                {formatDate(judgementData.judgment_date) || 'N/A'}
              </p>
              <p className='mb-2'>
                <b>Court:</b>{" "} {judgementData.court || 'N/A'}
              </p>
              <b>Judges:</b>
              <ul className='list-disc ml-4'>
                {judgementData.judgesId?.length ? judgementData.judgesId?.map((item: any) => (
                  <li className='my-1 gap-1'>
                    <p>{item.name} {checkIfJudgeIsAuthor(judgementData.author, item.id) && (<span className='text-xs bg-primary py-[1px] px-[4px] text-white font-semibold rounded-full'>Author</span>)}</p>
                  </li>
                )) : (<p>No judges found.</p>)}
              </ul>
            </div>
            <Divider />
            <div className="flex flex-row gap-1 justify-start text-sm font-bold">
              <span className={
                selectedTab === 'citations' ? "text-white py-2 px-4 rounded-md shadow cursor-pointer min-w-max text-center bg-primary"
                  : "bg-stone-50 py-2 px-4 min-w-max text-center text-primary cursor-pointer"}
                onClick={() => {
                  setSelectedTab('citations')
                }}>
                Citations
              </span>
              <span className={
                selectedTab === 'advocates' ? "text-white py-2 px-4 rounded-md shadow cursor-pointer min-w-max text-center bg-primary"
                  : "bg-stone-50 py-2 px-4 min-w-max text-center text-primary cursor-pointer"}
                onClick={() => {
                  setSelectedTab('advocates')
                }}>
                Advocates
              </span>
              <span className={
                selectedTab === 'statutes' ? "text-white py-2 px-4 rounded-md shadow cursor-pointer min-w-max text-center bg-primary"
                  : "bg-stone-50 py-2 px-4 min-w-max text-center text-primary cursor-pointer"}
                onClick={() => {
                  setSelectedTab('statutes')
                }}>
                Statutes
              </span>
            </div>
            <Divider />
            {selectedTab === 'statutes' && (
              judgementData.statutesId?.length > 0 ? judgementData.statutesId?.map((item: any) => (
                <>
                  <p className='text-sm'>{item.title}</p>
                  <Divider />
                </>
              )) : (<p className='text-sm'>No statutes found.</p>)
            )}
            {selectedTab === 'citations' && (
              judgementData.citationsId?.length > 0 ? judgementData.citationsId?.map((item: any) => (
                <>
                  <p className='text-sm'>{item.citation}</p>
                  <Divider />
                </>
              )) : (<p>No citations found.</p>)
            )}
            {selectedTab === 'advocates' && (
              (judgementData.adv_respondant || judgementData.adv_appeallant) ? (
                <>
                  <div className='flex flex-col gap-2 justify-start'>
                    <span className='text-xs bg-primary py-1 px-2 text-white font-semibold rounded-full max-w-min'>Appeallant</span>
                    <p className='text-sm'>{judgementData.adv_appeallant || 'N/A'}</p>
                  </div>
                  <Divider />
                  <div className='flex flex-col gap-2 justify-start'>
                    <span className='text-xs bg-primary py-1 px-2 text-white font-semibold rounded-full max-w-min'>Respondant</span>
                    <p className='text-sm'>{judgementData.adv_respondant || 'N/A'}</p>
                  </div>
                  <Divider />
                </>
              ) : (<p>No advocates found.</p>)
            )}
          </div>
          <div className="item-body">
            {subsequentReferences.length > 0 && (
              <>
                <div className='mb-2 cursor-pointer flex flex-row items-center justify-start gap-1' onClick={() => setSubsequentReferencesOpen(!subsequentReferencesOpen)}>
                  <b className="text-xl">Subsequent References</b> {subsequentReferencesOpen ? (<ExpandLess sx={{ color: '#D91E25', fontSize: '1.8rem' }} />) : (<ExpandMore sx={{ color: '#D91E25', fontSize: '1.8rem' }} />)}
                </div>
                <div className='flex flex-col gap-2'>
                  {subsequentReferencesOpen && subsequentReferences.map((item: any, index: number) => (
                    <div className='mt-2'>
                      <Badge color="primary"
                        badgeContent={index + 1}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        className="w-full">
                        <div className="flex flex-col rounded-md gap-2 bg-stone-50 border border-slate-300 shadow p-3 w-full">
                          <div className='flex flex-row gap-3 items-center cursor-pointer show-actions-on-hover' onClick={(e) => {
                            openJudgment(index, item.id);
                          }}>
                            <p className="font-bold hover:text-primary">{item.party}</p>
                            <Tooltip title={'Click to view case details in new tab.'} placement='right'>
                              <div className='action'>
                                <Launch className='text-primary' fontSize='small' />
                              </div>
                            </Tooltip>
                          </div>
                          <p className='text-sm'>{item.appeal}</p>
                          {item.text !== '' && (
                            <>
                              <Divider />
                              <div className="max-h-[8rem] overflow-auto text-sm"
                                dangerouslySetInnerHTML={{
                                  __html: item.text,
                                }}
                              ></div>
                              <Divider />
                            </>
                          )}
                          <div className="flex flex-row justify-between items-center text-sm">
                            <p><b>Court: </b>{item.court}</p>
                            <p><b>Date: </b>{formatDate(item.date) || 'N/A'}</p>
                          </div>
                        </div>
                      </Badge>
                    </div>
                  ))
                  }
                </div>
                <div className='my-4'>
                  <Divider />
                </div>
              </>
            )}
            {judgementData.case_summary ? (
              <div className='mb-2'>
                <div className='mb-2 cursor-pointer flex flex-row items-center justify-start gap-1' onClick={() => setSummaryTextOpen(!summaryTextOpen)}>
                  <b className="text-xl">Summary</b> {summaryTextOpen ? (<ExpandLess sx={{ color: '#D91E25', fontSize: '1.8rem' }} />) : (<ExpandMore sx={{ color: '#D91E25', fontSize: '1.8rem' }} />)}
                </div>
                {summaryTextOpen && (
                  <div className='judgment-text text-justify text-lg'
                    dangerouslySetInnerHTML={{
                      __html: judgementData.case_summary,
                    }}
                  ></div>
                )}
              </div>
            ) : (<p><b className="text-xl">Summary:</b> N/A</p>)
            }
            <div className='my-4'>
              <Divider />
            </div>
            {judgementData.judgment ? (
              <>
                <div className='mb-2'>
                  <div className='flex flex-row justify-between items-center '>
                    <div className='mb-2 cursor-pointer flex flex-row items-center justify-start gap-1' onClick={() => setJudgmentTextOpen(!judgmentTextOpen)}>
                      <b className="text-xl">Judgment</b> {judgmentTextOpen ? (<ExpandLess sx={{ color: '#D91E25', fontSize: '1.8rem' }} />) : (<ExpandMore sx={{ color: '#D91E25', fontSize: '1.8rem' }} />)}
                    </div>
                    {isAuthorised() && judgementData && (
                      <div className='flex flex-row gap-3'>
                        {/* <Tooltip title={'Click to download the Judgment as PDF'} placement={'bottom'}><Download className='cursor-pointer hover:text-primary' onClick={handleDownloadPDF} /></Tooltip> */}
                        <ReactToPrint
                          trigger={() => (<Tooltip title={'Click to print or save Judgment as PDF.'} placement={'left'}><SaveAs className='cursor-pointer hover:text-primary' /></Tooltip>)}
                          content={() => componentRef.current}
                          documentTitle={(judgementData.appeallant || 'N/A') + ' vs ' + (judgementData.respondant || 'N/A')}
                          pageStyle={`
                                @page {
                                  size: A4;
                                  margin: 2.5rem !important;
                                }
                                @media print {
                                  .watermark {
                                    /* Adjust the position and size of the watermark as needed */
                                    top: 50%;
                                    left: 50%;
                                    font-size: 4rem;
                                  }
                                }
                              `}
                        />
                      </div>
                    )}
                  </div>
                  {judgmentTextOpen && (
                    <div className='judgment-text text-justify'
                      dangerouslySetInnerHTML={{ __html: judgementData.judgment }}
                    ></div>
                  )}
                </div>
              </>
            ) : (<p><b className="text-xl">Judgment:</b> N/A</p>)
            }
            <div className='hidden'>
              <div id="html-content" ref={componentRef}>
                <JudgmentPrint judgementData={judgementData} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Judgment;