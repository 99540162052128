import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, TextField, FormControlLabel, Radio, RadioGroup, Autocomplete, Checkbox, Button, createFilterOptions, Dialog, DialogContent, IconButton, Tooltip, Divider } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { attachCacheBustingQueryParam, decryptString, formatDate, isAdmin, isDataOperator } from "../../app.utils";
import { logout } from "../../services/auth.service";
import { constants } from "../../app.constants";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmDialog from "../../dialogs/ConfirmationDialog";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useLoader } from "../../providers";
import { BackButton } from "../../components";

const EditJudgment = () => {
  const editor: any = Editor;
  const [selectedJudges, setSelectedJudges] = useState<any>([]);
  const [selectedCourt, setSelectedCourt] = useState<any>({ id: '', name: '' });
  const [selectedStatutes, setSelectedStatutes] = useState<any>([]);
  const [journals, setJournals] = useState([]);
  const [citationsRef, setCitationsRef] = useState<any>([]);
  const [citations, setCitations] = useState<any>([{ year: '', journal: '', page_no: '', citation: '' }]);
  const [selectedAuthorJudge, setSelectedAuthorJudge] = useState<any>(null);
  const [summaryData, setSummaryData] = useState('');
  const [judgmentData, setJudgmentData] = useState('');
  const [formErrors, setFormErrors] = useState<any>({});
  const [touchedFields, setTouchedFields] = useState<any>({});
  const { showLoader, hideLoader } = useLoader();
  const [statutes, setStatutes] = useState<any>([]);
  const [judges, setJudges] = useState<any>([]);
  const [courts, setCourts] = useState<any>([]);
  const [isNewJudgeDialogOpen, setIsNewJudgeDialogOpen] = useState(false);
  const [newJudge, setNewJudge] = useState('');
  const [newJudgeDesignation, setNewJudgeDesignation] = useState('');
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const [caseData, setCaseData] = useState<any>(null);

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    limit: 10,
  });

  const [formValue, setFormValues] = useState<any>({
    appeal: '',
    linkedAppeal: '',
    appeallant: '',
    respondant: '',
    area: '',
    jurisdiction: '',
    respondantAdvocate: '',
    appeallantAdvocate: '',
    hearingDate: '',
    judgmentDate: '',
    result: '',
    headNotes: '',
  });

  const fetchJournals = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_NEST_URL}/citation-search/journals`,
        {
          headers: {
            Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
          },
        }
      )
      .then((response: any) => {
        setJournals(response.data?.data);
      })
      .catch((error) => {
        // logout; if unauthorised
        if (error.response.status === 401) {
          logout();
        }
        toast.error('Failed to fetch Journals.');
      });
  }

  const fetchJudges = async () => {
    showLoader();
    await axios
      .post(
        `${process.env.REACT_APP_API_NEST_URL}/judge-search/judges-by-courts/`,
        {
          court: selectedCourt.name,
        },
        {
          headers: {
            Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
          },
        }
      )
      .then((response: any) => {
        hideLoader();
        setJudges(response.data);
      })
      .catch((error) => {
        hideLoader();
        // logout; if unauthorised
        if (error.response.status === 401) {
          logout();
        }
        toast.error('Failed to fetch judges.');
      });
  }

  const fetchCourts = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_NEST_URL}/court-search/all`,
        {
          headers: {
            Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
          },
        }
      )
      .then((response: any) => {
        setCourts(response.data);
      })
      .catch((error) => {
        // logout; if unauthorised
        if (error.response.status === 401) {
          logout();
        }
        toast.error('Failed to fetch courts.');
      });
  }

  const fetchStatutes = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_NEST_URL}/statutes-search/all`,
        {
          headers: {
            Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
          },
        }
      )
      .then((response: any) => {
        setStatutes(response.data);
      })
      .catch((error) => {
        // logout; if unauthorised
        if (error.response.status === 401) {
          logout();
        }
        toast.error('Failed to fetch statutes.');
      });
  }

  const fetchCaseData = () => {
    const searchParams = new URLSearchParams(location.search);
    const judgmentId = searchParams.get('judgmentId') || '';

    let getCaseDetailURL = `${process.env.REACT_APP_API_NEST_URL}/case-search/edit-case/${judgmentId}`;
    if (isAdmin() || isDataOperator()) {
      getCaseDetailURL = attachCacheBustingQueryParam(getCaseDetailURL);
    }
    axios
      .get(
        getCaseDetailURL, {
        headers: {
          Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
        },
      }
      )
      .then((res) => {
        if (res.data) {
          setCaseData(res.data)
          populateFormValues(res.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        }
      });
  }

  const populateFormValues = (caseData: any) => {
    if (caseData) {
      setFormValues({
        appeal: caseData.appeal,
        linkedAppeal: '',
        appeallant: caseData.appeallant,
        respondant: caseData.respondant,
        area: caseData.court_area,
        jurisdiction: '',
        respondantAdvocate: caseData.adv_respondant,
        appeallantAdvocate: caseData.adv_appeallant,
        hearingDate: dayjs(caseData.hearing_date),
        judgmentDate: dayjs(caseData.judgment_date),
        result: caseData.result,
        headNotes: caseData.head_notes,
      });
      setCitations(caseData.citationsId);
      setSelectedCourt(caseData.court);
      setSelectedJudges(caseData.judgesId);
      setSelectedStatutes(caseData.statutesId);
      setJudgmentData(caseData.judgment);
      setSummaryData(caseData.case_summary);
      setSelectedAuthorJudge(caseData.author);
      if (caseData.judgment) {
        setCitationsRef(caseData.judgment.match(constants.regexes.citationSearch))
      }
      if (caseData.citationsId.length <= 0) {
        setCitations([{ year: '', journal: '', page_no: '', citation: '' }]);
      }
    }
  }

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    let newErrors: any = {};
    let updatedFormValue = { ...formValue, [name]: value };
    switch (name) {
      case 'appeal':
        if (!value) {
          newErrors.appeal = 'Appeal is required';
        } else {
          delete formErrors.appeal;
        }
        break;
      case 'citationYear':
        if (!value) {
          newErrors.citationYear = 'Citation Year is required';
        } else {
          delete formErrors.citationYear;
        }
        break;
      case 'citationJournal':
        if (!value) {
          newErrors.citationJournal = 'Citation Journal is required';
        } else {
          delete formErrors.citationJournal;
        }
        break;
      case 'citationPageNo':
        if (!value) {
          newErrors.citationPageNo = 'Citation Page No. is required';
        } else {
          delete formErrors.citationPageNo;
        }
        break;
      case 'appeallant':
        if (!value) {
          newErrors.appeallant = 'Appeallant is required';
        } else {
          delete formErrors.appeallant;
        }
        break;
      case 'respondant':
        if (!value) {
          newErrors.respondant = 'Respondant is required';
        } else {
          delete formErrors.respondant;
        }
        break;
      case 'judgmentDate':
        if (!value) {
          newErrors.judgmentDate = 'Judgment Date is required';
        } else {
          delete formErrors.judgmentDate;
        }
        break;
      case 'hearingDate':
        if (!value) {
          newErrors.hearingDate = 'Hearing Datet is required';
        } else {
          delete formErrors.hearingDate;
        }
        break;
      case 'result':
        if (!value) {
          newErrors.result = 'Case Result is required';
        } else {
          delete formErrors.result;
        }
        break;
      case 'respondantAdvocate':
        if (!value) {
          newErrors.result = 'Respondant Advocate is required';
        } else {
          delete formErrors.result;
        }
        break;
      case 'appeallantAdvocate':
        if (!value) {
          newErrors.result = 'Appeallant Advocate is required';
        } else {
          delete formErrors.result;
        }
        break;
      default:
        break;
    }
    setFormValues(updatedFormValue);
    setFormErrors((prevErrors: any) => {
      return { ...prevErrors, ...newErrors };
    });
  };

  const handleCitationChange = (index: any, field: any, value: any) => {
    const updatedCitations = [...citations];
    updatedCitations[index]['citation'] = '';
    updatedCitations[index][field] = value;
    setCitations(updatedCitations);
  };

  const handleCitationRefChange = (index: any, value: any) => {
    const updatedCitations = [...citationsRef]; // Create a copy of the citations array
    updatedCitations[index] = value; // Update the specified property of the citation object
    setCitationsRef(updatedCitations); // Update the state with the updated citations
  };

  const handleAddCitation = () => {
    setCitations([...citations, { year: '', journal: '', page_no: '', citation: '' }]);
  };

  const handleRemoveCitation = (index: any) => {
    if (citations.length === 1) {
      return; // Prevent removing the last citation
    }
    const updatedCitations = [...citations];
    updatedCitations.splice(index, 1);
    setCitations(updatedCitations);
  };

  const getJudgesIds = () => {
    return selectedJudges.map((judge: any) => judge.id);
  }

  const getIdsofSelectedStatutes = () => {
    let tempStatutes: any = [];
    selectedStatutes.map((statute: any) => {
      tempStatutes.push(statute.id);
    });
    return tempStatutes;
  }

  const handleJudgeSelection = (event: any, value: any) => {
    if (!event.target.checked) {
      setSelectedJudges((prevSelectedJudges: any) =>
        prevSelectedJudges.filter((judge: any) => judge.id !== value.id)
      );
    }
  };

  const handleStatuteSelection = (event: any, value: any) => {
    if (!event.target.checked) {
      setSelectedJudges((prevSelectedStatutes: any) =>
        prevSelectedStatutes.filter((statute: any) => statute.id !== value.id)
      );
    }
  };

  const isJudgeSelected = (judge: any) => {
    return selectedJudges.some((selectedJudge: any) => selectedJudge.id === judge.id);
  };

  const isStatuteSelected = (statute: any) => {
    return selectedStatutes.some((selectedStatute: any) => selectedStatute.id === statute.id);
  };

  const handleSummaryTextChange = (html: any) => {
    setSummaryData(html);
  };

  const handleJudgmentTextChange = (html: any) => {
    setJudgmentData(html);
  };

  const setCitationValues = (citation: any, index: any) => {
    handleCitationChange(index, 'citation', `${citation.year} ${citation.journal || ''} ${citation.page_no}`)
    return `${citation.year} ${citation.journal || ''} ${citation.page_no}`;
  }

  const removeCitationRefField = (index: any) => {
    const newArray = [...citationsRef];
    newArray.splice(index, 1);
    setCitationsRef(newArray);
  };

  const handleFieldBlur = (event: any) => {
    const { name } = event.target;
    setTouchedFields((prevTouched: any) => {
      return { ...prevTouched, [name]: true };
    });
  };

  const isFormInvalid = () => {
    if (Object.keys(formErrors).length > 0 || !formValue.appeal ||
      !formValue.appeallant || !formValue.respondant || !formValue.hearingDate ||
      !formValue.judgmentDate ||
      !formValue.respondantAdvocate || !formValue.appeallantAdvocate ||
      !formValue.result || judgmentData.length === 0) {
      return true;
    }
    return false;
  }

  const editCase = () => {
    showLoader();

    const dataToPut = {
      appeal: formValue.appeal,
      appeallant: formValue.appeallant,
      respondant: formValue.respondant,
      judgment_date: formatDate(formValue.judgmentDate),
      hearing_date: formatDate(formValue.hearingDate),
      head_notes: formValue.headNotes,
      result: formValue.result,
      court: selectedCourt.name,
      statutes: getIdsofSelectedStatutes(),
      court_area: formValue.area,
      case_summary: summaryData,
      adv_appeallant: formValue.appeallantAdvocate,
      adv_respondant: formValue.respondantAdvocate,
      judgment: judgmentData,
      citationsId: citations,
      judges: getJudgesIds(),
      author: selectedAuthorJudge,
      hyperlinks: citationsRef,
    }

    axios.post(`${process.env.REACT_APP_API_NEST_URL}/case-search/edit/${caseData.id}`, dataToPut, {
      headers: {
        Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
      },
    }).then((res) => {
      hideLoader();
      toast.success('Case data updated successfully.');
      navigate('/cases/judgment?judgmentId=' + caseData.id);
    }).catch((err) => {
      hideLoader();
      toast.error("Failed to update case data.");
    });
  };

  const fetchData = async () => {
    showLoader();

    const fetchPromises = Promise.all([
      fetchJournals(),
      fetchCourts(),
      fetchStatutes(),
      fetchCaseData(),
    ]);

    await fetchPromises;

    hideLoader();
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedCourt && selectedCourt.id !== '') {
      fetchJudges();
    }
  }, [selectedCourt]);

  useEffect(() => {
    // Define a regular expression pattern to find text between '##TS##' and '##TE##' tags
    const summaryPattern = /##TS##(.*?)##TE##/g;
    const tagsPattern = /(##TS##)|(##TE##)/g;

    // Use the pattern to match and remove text from judgmentData while preserving the rest in summaryData
    const updatedJudgmentData = judgmentData.replace(tagsPattern, '');

    // Use pattern. to find all matches of the pattern and store them in an array
    const matches = [...judgmentData.matchAll(summaryPattern)];

    // Extract the matched text between '##TS##' and '##TE##' tags and join them into a single string
    const detectedSummaryData = matches.map((match) => match[1]).join('</br>');

    // Concatenate detectedSummaryData with the existing summaryData
    const newSummaryData = summaryData + detectedSummaryData;

    detectedSummaryData.replace(/<p><br><\/p>/g, '');
    updatedJudgmentData.replace(/<p><br><\/p>/g, '');

    // Set summaryData and judgmentData accordingly
    setSummaryData(newSummaryData);
    setJudgmentData(updatedJudgmentData);
  }, [judgmentData, summaryData]);

  const saveNewJudge = async () => {
    // Integrate Add new Judge API
    showLoader();
    await axios
      .post(
        `${process.env.REACT_APP_API_NEST_URL}/judge-search/add-judge`,
        {
          name: newJudge,
          designation: newJudgeDesignation,
          court: selectedCourt.name,
        },
        {
          headers: {
            Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
          },
        }
      )
      .then((response: any) => {
        hideLoader();
        fetchJudges();
        setNewJudge('');
        setNewJudgeDesignation('');
        setIsNewJudgeDialogOpen(false);
        toast.success('Judge successfully added! You can now select the newly added judge from the menu.');
      })
      .catch((error) => {
        hideLoader();
        // logout; if unauthorised
        if (error.response.status === 401) {
          logout();
        }
        toast.error('Failed to add new judge.');
      });
  }

  const changeDateToYYYYMMDD = (date: string) => {

    if (date !== '' && date.includes('/')) {

      // Split the date string into its components
      const [day, month, year] = date.split('/');

      // Pad the month and day with leading zeros if necessary
      const formattedMonth = month?.padStart(2, '0');
      const formattedDay = day?.padStart(2, '0');

      // Combine the components into the new date format
      const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
      // return moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD');;

      return formattedDate;

    }

    return date;
  }

  const deleteJudgment = () => {
    showLoader();
    axios.delete(`${process.env.REACT_APP_API_NEST_URL}/case-search/${caseData.id}`, {
      headers: {
        Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
      },
    }).then((res) => {
      hideLoader();
      toast.success('Judgment deleted successfully.');
      navigate('/home');
    }).catch((err) => {
      hideLoader();
      toast.error("Failed to delete judgment.");
    });
  }

  const changeDateToDDMMYYYY = (date: string) => {
    if (date !== '' && date.includes('-')) {
      // Split the date string into its components
      const [year, month, day] = date.split('-');

      // Pad the month and day with leading zeros if necessary
      const formattedMonth = month?.padStart(2, '0');
      const formattedDay = day?.padStart(2, '0');

      // Combine the components into the new date format
      const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;

      return formattedDate;
    }
    return date;
  }

  return (
    <>
      <div className="rounded-container flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <div className="flex flex-row items-center justify-between">
            <p className="text-2xl font-bold">Edit Jugdment</p>
            {(isAdmin() || isDataOperator()) && (
              <>
                <div>
                  <Button
                    type="submit"
                    variant="outlined"
                    onClick={() => setDeleteConfirmation(true)}
                  >
                    Delete Judgment
                  </Button>
                  <ConfirmDialog
                    title="Delete Judgment?"
                    open={deleteConfirmation}
                    setOpen={setDeleteConfirmation}
                    onConfirm={deleteJudgment}
                  >
                    Are you sure you want to delete this Judgment?
                  </ConfirmDialog>
                </div>
              </>
            )}
          </div>
          <Divider />
        </div>

        {/* Case citation */}
        <div className="flex flex-col gap-2">
          <p className="font-bold pt-1">Case Citation(s)</p>
          {citations.map((citation: any, index: any) => (
            <div className="flex flex-row gap-4 items-center" key={index}>
              <div className="flex flex-row gap-4 w-[40rem]">
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    type="number"
                    placeholder="Year"
                    name={`citationYear-${index}`}
                    disabled={citation.id}
                    required
                    value={citation.year}
                    onChange={(e) => handleCitationChange(index, 'year', e.target.value)}
                  />
                </FormControl>
                <FormControl fullWidth size="small">
                  <InputLabel id={`select-journal-${index}`}>Journal</InputLabel>
                  <Select
                    labelId={`select-journal-${index}`}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                    variant="outlined"
                    defaultValue={''}
                    disabled={citation.id}
                    label="Journal"
                    name={`citationJournal-${index}`}
                    required
                    value={citation.journal}
                    onChange={(e) => handleCitationChange(index, 'journal', e.target.value)}
                  >
                    {journals && journals.map((item: any, index) => (
                      <MenuItem value={item.name} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    type="text"
                    placeholder="Page No."
                    disabled={citation.id}
                    name={`citationPageNo-${index}`}
                    required
                    value={citation.page_no}
                    onChange={(e) => handleCitationChange(index, 'page_no', e.target.value)}
                  />
                </FormControl>
              </div>
              <div className="w-[20rem]">
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    type="text"
                    disabled={(!citation.year && !citation.journal && !citation.page_no) || citation.id}
                    name={`citation-${index}`}
                    value={citation.citation || setCitationValues(citation, index)}
                    onChange={(e) => handleCitationChange(index, 'citation', e.target.value || `${citation.year} ${citation.journal || ''} ${citation.page_no}`)}
                  />
                </FormControl>
              </div>
              {index !== citations.length - 1 ? (
                <RemoveCircleOutline className="text-red-500 cursor-pointer" onClick={() => handleRemoveCitation(index)} />
              ) : (<div className="flex flex-row gap-4"><RemoveCircleOutline className="text-red-500 cursor-pointer" onClick={() => handleRemoveCitation(index)} /><AddCircleOutline className="text-[#6c5dd3] cursor-pointer" onClick={handleAddCitation} /></div>)}
            </div>
          ))}
        </div>

        {/* Court */}
        {courts && (
          <>
            <div className={`flex flex-col gap-3 w-[40rem]`}>
              <p className="font-bold">Court <span className="mr-1 text-red-600 font-semibold">*</span></p>

              <FormControl fullWidth size='small'>
                <Autocomplete
                  options={courts}
                  getOptionLabel={(option) => option.name || ''}
                  isOptionEqualToValue={(option: any, value: any) => option.name === value.name}
                  value={selectedCourt}
                  onChange={(event, value) => { setSelectedJudges([]); setSelectedAuthorJudge(null); setSelectedCourt(value) }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={selectedCourt ? "Select Court" : ''}
                      defaultValue={caseData && caseData.court || ''}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} className="border-[0.5px] border-gray-200 py-1 px-2">
                      <p>{option.name}</p>
                    </li>
                  )}
                />
              </FormControl>
            </div>
          </>
        )}

        {/* Area */}
        <div className="flex flex-row gap-6 w-[40rem]">
          <div className="flex flex-col gap-2 w-full">
            <p className="font-bold pt-1">Area</p>
            <FormControl fullWidth>
              <TextField
                size='small'
                placeholder='Enter Area'
                variant='outlined'
                name='area'
                type={'text'}
                required
                onChange={handleInputChange}
                value={formValue.area}
                onBlur={handleFieldBlur}
                error={!!(formErrors.area && touchedFields.area)}
                helperText={touchedFields.area ? formErrors.area : ""}
              />
            </FormControl>
          </div>
          {/* <div className="flex flex-col gap-2 w-full">
              <p className="font-bold pt-1">Jurisdiction</p>
              <FormControl fullWidth>
                <TextField
                  size='small'
                  placeholder='Enter Jurisdiction'
                  variant='outlined'
                  name='jurisdiction'
                  type={'text'}
                  required
                  onChange={handleInputChange}
                  value={formValue.jurisdiction}
                  onBlur={handleFieldBlur}
                  error={!!(formErrors.jurisdiction && touchedFields.jurisdiction)}
                  helperText={touchedFields.jurisdiction ? formErrors.jurisdiction : ""}
                />
              </FormControl>
            </div> */}

        </div>

        <hr />
        {/* Judge */}
        {judges && (
          <>
            <div className="flex flex-row gap-[3.4rem] w-full">
              <div className={`flex flex-col gap-3 w-[40rem]`}>
                <div className="flex flex-row justify-between items-center">
                  <p className="font-bold">Judge(s) <span className="mr-1 text-red-600 font-semibold">*</span></p>
                  <Button
                    type="submit"
                    variant="outlined"
                    onClick={() => { setIsNewJudgeDialogOpen(true) }}
                  >
                    Add New Judge
                  </Button>
                </div>
                <Tooltip title={(selectedCourt && selectedCourt.id === '' || !selectedCourt) ? 'Please select court' : ''}>
                  <FormControl size='small' fullWidth>
                    <Autocomplete
                      disabled={(selectedCourt && selectedCourt.id === '' || !selectedCourt) || judges.length === 0}
                      multiple
                      filterOptions={filterOptions}
                      options={judges}
                      getOptionLabel={(option) => option.name || ''}
                      value={selectedJudges}
                      onChange={(event, value) => setSelectedJudges(value)}
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={selectedJudges.length === 0 ? "Select judges" : ''}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} className="border-[0.5px] border-gray-200 py-1 px-2">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isJudgeSelected(option)}
                                color="primary"
                                onChange={(event) => handleJudgeSelection(event, option)}
                              />
                            }
                            label={option.name}
                          />
                        </li>
                      )}
                    />
                  </FormControl>
                </Tooltip>
              </div>
              {
                selectedJudges.length > 0 && (
                  <div className="flex flex-col gap-2 pt-2">
                    <p className="font-bold">Select Author Judge</p>
                    <FormControl >
                      <RadioGroup
                        value={selectedAuthorJudge}
                        onChange={(event) => {
                          const judgeId = event.target.value;
                          setSelectedAuthorJudge(judgeId);
                        }}
                      >
                        {
                          selectedJudges.map((judge: any) => (
                            <FormControlLabel value={judge.id} control={<Radio sx={{
                              '& .MuiSvgIcon-root': {
                                fontSize: 18,
                              },
                            }} />} label={judge.name} />
                          )
                          )}
                      </RadioGroup>
                    </FormControl>
                  </div>
                )
              }
            </div>
            <hr />
          </>
        )}

        {/* Party Name */}
        <div className="flex flex-col gap-2 w-[40rem]">
          <p className="font-bold">Party Name <span className="mr-1 text-red-600 font-semibold">*</span></p>
          <div className="flex flex-row gap-4 items-center">
            <FormControl fullWidth>
              <TextField
                size='small'
                placeholder='Appeallant Name'
                variant='outlined'
                name='appeallant'
                type={'text'}
                onChange={handleInputChange}
                value={formValue.appeallant}
                onBlur={handleFieldBlur}
                error={!!(formErrors.appeallant && touchedFields.appeallant)}
                helperText={touchedFields.appeallant ? formErrors.appeallant : ""}
              />
            </FormControl>
            <p className="font-bold">Vs</p>
            <FormControl fullWidth>
              <TextField
                size='small'
                placeholder='Respondant Name'
                variant='outlined'
                name="respondant"
                type="text"
                onChange={handleInputChange}
                value={formValue.respondant}
                onBlur={handleFieldBlur}
                error={!!(formErrors.respondant && touchedFields.respondant)}
                helperText={touchedFields.respondant ? formErrors.respondant : ""}
              />
            </FormControl>
          </div>
        </div>

        {/* Case Appeal */}
        <div className="flex flex-row gap-6 items-center">
          <div className="flex flex-col gap-2 w-[40rem]">
            <p className="font-bold pt-1">Case Appeal <span className="mr-1 text-red-600 font-semibold">*</span></p>
            <FormControl fullWidth>
              <TextField
                size='small'
                placeholder='Enter Case Appeal'
                variant='outlined'
                type={'text'}
                required
                name='appeal'
                onChange={handleInputChange}
                value={formValue.appeal}
                onBlur={handleFieldBlur}
                error={!!(formErrors.appeal && touchedFields.appeal)}
                helperText={touchedFields.appeal ? formErrors.appeal : ""}
              />
            </FormControl>
          </div>
        </div>

        {/* Dates */}
        <div className="flex flex-row gap-6 w-[40rem]">
          <div className="flex flex-col gap-2 w-full">
            <p className="font-bold pt-1">Date of Hearing <span className="mr-1 text-red-600 font-semibold">*</span></p>
            <DatePicker
              value={formValue.hearingDate}
              format="YYYY-MM-DD"
              slotProps={{ textField: { size: 'small' } }}
              onChange={(value: any) => setFormValues({ ...formValue, hearingDate: value })}
            />
          </div>
          <div className="flex flex-col gap-2 w-full">
            <p className="font-bold pt-1">Date of Judgment <span className="mr-1 text-red-600 font-semibold">*</span></p>
            <DatePicker
              value={formValue.judgmentDate}
              format="YYYY-MM-DD"
              slotProps={{ textField: { size: 'small' } }}
              onChange={(value: any) => setFormValues({ ...formValue, judgmentDate: value })}
            />
          </div>
        </div>

        {/* Linked Appeal */}
        {/* <div className="flex flex-col gap-2 w-[40rem]">
            <p className="font-bold pt-1">Linked Appeal</p>
            <FormControl fullWidth>
              <TextField
                size='small'
                placeholder='Enter Linked Appeal'
                variant='outlined'
                type={'text'}
                name='linkedAppeal'
                required
                onChange={handleInputChange}
                value={formValue.linkedAppeal}
                onBlur={handleFieldBlur}
                error={!!(formErrors.linkedAppeal && touchedFields.linkedAppeal)}
                helperText={touchedFields.linkedAppeal ? formErrors.linkedAppeal : ""}
              />
            </FormControl>
          </div> */}

        {/* Advocates */}
        <div className="flex flex-row gap-6 w-[40rem]">
          <div className="flex flex-col gap-2 w-full">
            <p className="font-bold pt-1">Appeallant's Advocate <span className="mr-1 text-red-600 font-semibold">*</span></p>
            <FormControl fullWidth>
              <TextField
                size='small'
                placeholder="Enter Appeallant's Advocate"
                variant='outlined'
                name='appeallantAdvocate'
                type={'text'}
                required
                onChange={handleInputChange}
                value={formValue.appeallantAdvocate}
                onBlur={handleFieldBlur}
                error={!!(formErrors.appeallantAdvocate && touchedFields.appeallantAdvocate)}
                helperText={touchedFields.appeallantAdvocate ? formErrors.appeallantAdvocate : ""}
              />
            </FormControl>
          </div>
          <div className="flex flex-col gap-2 w-full">
            <p className="font-bold pt-1">Respondant's Advocate <span className="mr-1 text-red-600 font-semibold">*</span></p>
            <FormControl fullWidth>
              <TextField
                size='small'
                placeholder="Enter Respondant's Advocate"
                variant='outlined'
                name='respondantAdvocate'
                type={'text'}
                required
                onChange={handleInputChange}
                value={formValue.respondantAdvocate}
                onBlur={handleFieldBlur}
                error={!!(formErrors.respondantAdvocate && touchedFields.respondantAdvocate)}
                helperText={touchedFields.respondantAdvocate ? formErrors.respondantAdvocate : ""}
              />
            </FormControl>
          </div>
        </div>

        {/* Case Result */}
        <div className="flex flex-col gap-2 w-[40rem]">
          <p className="font-bold pt-1">Case Result <span className="mr-1 text-red-600 font-semibold">*</span></p>
          <FormControl fullWidth>
            <TextField
              size='small'
              placeholder='Enter Case Result'
              variant='outlined'
              type={'text'}
              required
              name='result'
              onChange={handleInputChange}
              value={formValue.result}
              onBlur={handleFieldBlur}
              error={!!(formErrors.result && touchedFields.result)}
              helperText={touchedFields.result ? formErrors.result : ""}
            />
          </FormControl>
        </div>

        {/* Statutes */}
        {statutes && (<>
          <div className={`flex flex-col gap-3 w-[40rem]`}>
            <p className="font-bold">Statute(s) <span className="mr-1 text-red-600 font-semibold">*</span></p>

            <FormControl fullWidth size='small'>
              <Autocomplete
                multiple
                filterOptions={filterOptions}
                options={statutes}
                getOptionLabel={(option) => option.title || ''}
                value={selectedStatutes}
                onChange={(event, value) => setSelectedStatutes(value)}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={selectedStatutes.length === 0 ? "Select statutes" : ''}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} className="border-[0.5px] border-gray-200 py-1 px-2">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isStatuteSelected(option)}
                          color="primary"
                          onChange={(event) => handleStatuteSelection(event, option)}
                        />
                      }
                      label={option.title}
                    />
                  </li>
                )}
              />
            </FormControl>
          </div>
        </>)}

        {/* Head Notes */}
        <div className="flex flex-col gap-2 w-[40rem]">
          <p className="font-bold pt-1">Head Notes</p>
          <FormControl fullWidth>
            <TextField
              size='small'
              multiline
              rows={5}
              placeholder='Enter Head Notes'
              variant='outlined'
              name='headNotes'
              type={'text'}
              onChange={handleInputChange}
              value={formValue.headNotes}
            />
          </FormControl>
        </div>
        <hr />

        {/* Case Judgment */}
        <div className="flex flex-row gap-4 h-full">
          <div className="flex flex-col gap-2 h-full w-full">
            <div className="flex flex-row justify-between items-center h-full">
              <p className="font-bold">Case Judgment <span className="mr-1 text-red-600 font-semibold">*</span></p>
              <Button
                disabled={judgmentData.length === 0}
                variant="outlined"
                onClick={() => {
                  setCitationsRef(judgmentData.match(constants.regexes.citationSearch));
                }}>
                Search Citations
              </Button>
            </div>
            <CKEditor
              editor={editor}
              data={judgmentData}
              onChange={(event: any, editor: any) => {
                if (editor) {
                  const data = editor.getData();
                  handleJudgmentTextChange(data);
                }
              }}
            />
          </div>
          {judgmentData.length > 0 && citationsRef && citationsRef.length > 0 && (
            <div className="flex flex-col gap-2 min-w-[18rem] border-l-[0.5px] border-l-gray-300 px-3">
              <p className="font-bold">Found Citations:</p>
              <div className="mb-3">
                <hr />
              </div>
              {citationsRef.map((citationRef: any, index: any) => (
                <div className="flex flex-row gap-3 items-center">
                  <FormControl fullWidth key={index}>
                    <TextField
                      size="small"
                      type="text"
                      name={`citation-${index}`}
                      value={citationRef}
                      onChange={(e) => handleCitationRefChange(index, e.target.value)}
                    />
                  </FormControl>
                  <RemoveCircleOutline className="text-red-500 cursor-pointer" onClick={() => removeCitationRefField(index)} />
                </div>
              ))}
            </div>
          )}
        </div>

        <hr />

        {/* Case Summary */}
        {summaryData && summaryData.length > 0 && (
          <>
            <div className="flex flex-col gap-2">
              <p className="font-bold">Case Summary</p>
              <CKEditor
                editor={editor}
                data={summaryData}
                onChange={(event: any, editor: any) => {
                  if (editor) {
                    const data = editor.getData();
                    handleSummaryTextChange(data);
                  }
                }}
              />
            </div>

            <hr /></>
        )}

        {/* Submit Button */}
        <Button
          type="submit"
          className="max-w-max"
          disabled={isFormInvalid()}
          variant="contained"
          color='primary'
          onClick={() => editCase()}
        >
          Save Changes
        </Button>
      </div>
      <Dialog fullWidth open={isNewJudgeDialogOpen} onClose={() => setIsNewJudgeDialogOpen(false)}>
        <div className="flex flex-row bg-gradient-to-b from-[#41090b] to-[#150303] justify-end p-2 gap-2 items-center border-b-[0.1px] border-b-black border-opacity-50">
          <IconButton
            onClick={() => { setIsNewJudgeDialogOpen(false) }}
            size="small"
            className='action'
            sx={{ color: '#6c5dd3' }}>
            <HighlightOffIcon fontSize='small' />
          </IconButton>
        </div>
        <DialogContent>
          <div className="flex flex-col gap-3">
            {/* Court */}
            {courts && (
              <>
                <div className={`flex flex-col gap-3`}>
                  <p className="font-bold">Court <span className="mr-1 text-red-600 font-semibold">*</span></p>
                  <FormControl fullWidth size='small'>
                    <Autocomplete
                      options={courts}
                      getOptionLabel={(option) => option.name || ''}
                      isOptionEqualToValue={(option: any, value: any) => option.name === value.name}
                      value={selectedCourt}
                      onChange={(event, value) => setSelectedCourt(value)}
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={selectedCourt ? "Select Court" : ''}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} className="border-[0.5px] border-gray-200 py-1 px-2">
                          <p>{option.name}</p>
                        </li>
                      )}
                    />
                  </FormControl>
                </div>
              </>
            )}
            <div className="flex flex-col gap-2 w-full">
              <p className="font-bold pt-1">Judge Name  <span className="mr-1 text-red-600 font-semibold">*</span></p>
              <FormControl fullWidth>
                <TextField
                  size='small'
                  placeholder='Enter Judge Name'
                  variant='outlined'
                  name='judgeName'
                  type={'text'}
                  required
                  onChange={(e) => { setNewJudge(e.target.value) }}
                  value={newJudge}
                />
              </FormControl>
            </div>
            <div className="flex flex-col gap-2 w-full">
              <p className="font-bold pt-1">Designation</p>
              <FormControl fullWidth>
                <TextField
                  size='small'
                  placeholder='Enter Judge Designation'
                  variant='outlined'
                  name='judgeDesignation'
                  type={'text'}
                  required
                  onChange={(e) => { setNewJudgeDesignation(e.target.value) }}
                  value={newJudgeDesignation}
                />
              </FormControl>
            </div>
            <Button
              className="w-full mt-4"
              disabled={newJudge === '' || selectedCourt.name === ''}
              variant="contained"
              color='primary'
              onClick={() => { saveNewJudge() }}
            >
              Save
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditJudgment;