import { Divider, IconButton, Pagination, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Role } from "../../enums";
import { withAccess } from "../../services/role-guard.service";
import { Add, Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ConfirmationPrompt, PreviewBlog } from "../../dialogs";
import { isAuthorised } from "../../app.utils";
import { BackButton, SkeletonLoading } from "../../components";
import { useDialog } from "../../providers";
import { deleteBanner, fetchAllBanners } from "../../services/banners.service";

const BannersManagement = () => {
  const { openDialog, closeDialog } = useDialog();
  const navigate = useNavigate();
  const [banners, setBanners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    reloadBanners();
  }, [pageNo]);

  /**
   * Function to handle bookmark page change
   * @param {Event} event
   * @param {number} value
   */
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageNo(value);
  };

  const reloadBanners = () => {
    setIsLoading(true);
    fetchAllBanners(pageNo).then((res) => {
      setBanners(res.data);
      setTotalPages(res.totalPages);
      setIsLoading(false);
    }).catch((err) => {
      toast.error('Failed to fetch banners.');
      setIsLoading(false);
    })
  }

  const handleDeleteBlog = async (banner: any) => {
    openDialog(ConfirmationPrompt, {
      onClose: () => {
        closeDialog();
      },
      title: `Deleting '${banner.title}'`,
      message: 'Are you sure you want to delete this banner?',
      onConfirm: () => {
        deleteBanner(banner.id).then(() => {
          toast.success('Banner deleted successfully.');
          reloadBanners();
        }).catch(() => {
          toast.error('Failed to delete the Banner.');
        })
      }
    });
  }

  return (
    <div className="flex flex-col gap-3 rounded-container">
      <div className='flex flex-row justify-start items-start'>
        <BackButton />
      </div>
      <p className="text-3xl font-bold">Banners Management</p>
      <Divider />
      <div className="flex flex-row justify-between items-end">
        <p className="text-3xl font-bold">Banners</p>
        <div className="flex flex-row gap-1 justify-center items-center px-3 py-2 border border-slate-300 hover:border-primary shadow rounded-lg cursor-pointer max-w-max"
          onClick={
            () => {
              navigate('/manage/banners/add');
            }}>
          <Add color="primary" />
          <p className="text-primary font-bold">Add Banner</p>
        </div>
      </div>

      <div className="flex flex-col justify-between h-full">
        <div className={`h-[calc(100vh-17.5rem)] overflow-auto border rounded-md`}>
          {isLoading ? (<SkeletonLoading isLoading={isLoading} />) : (
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span className="font-bold text-md">Text</span>
                  </TableCell>
                  <TableCell>
                    <span className="font-bold text-md">Type</span>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <span className="font-bold text-md">Status</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {banners && banners.length > 0 ? (
                  banners.map((item: any, index: number) => (
                    <TableRow key={item.id} className="border-b-0 show-actions-on-hover" hover={true}>
                      <TableCell className="max-w-[12rem] truncate">
                        {item.title}
                      </TableCell>
                      <TableCell className="max-w-[12rem] truncate">
                        {item.type}
                      </TableCell>
                      <TableCell className="max-w-[12rem] truncate">
                        <span className={item.isActive ? 'bagde-email-verified-yes' : 'bagde-email-verified-no'}>{item.isActive ? 'Active' : 'In Active'}</span>
                      </TableCell>
                      <TableCell className="max-w-max truncate">
                        <div className="flex flex-row justify-end items-center gap-4">
                          <Tooltip title={'Edit Banner'} placement='bottom'>
                            <span className="action">
                              <IconButton size="small"
                                onClick={() => navigate(`/manage/banners/edit?bannerId=${item.id}`)}
                                sx={{
                                  zIndex: '10',
                                  color: "#D91E25",
                                  ":hover": {
                                    color: "#D91E25",
                                  },
                                }}
                              >
                                <Edit fontSize='small' />
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip title={'Delete Banner'} placement='bottom'>
                            <span className="action">
                              <IconButton size="small"
                                onClick={() => handleDeleteBlog(item)}
                                sx={{
                                  zIndex: '10',
                                  color: "#D91E25",
                                  ":hover": {
                                    color: "#D91E25",
                                  },
                                }}
                              >
                                <Delete fontSize='small' />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={12} align={"center"}>
                      No Banners found!
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </div>
        <div className="flex flex-row justify-end">
          <Stack spacing={2} className="flex flex-row mt-4 justify-end">
            <span>
              <Pagination
                color="primary"
                page={pageNo}
                disabled={!isAuthorised()}
                count={totalPages}
                siblingCount={0}
                boundaryCount={1}
                onChange={handlePageChange}
                shape={"rounded"}
              />
            </span>
          </Stack>
        </div>
      </div>

    </div>
  );
}

export default withAccess(BannersManagement, [Role.ADMIN, Role.DATA_OPERATOR]);