import React from "react";
import { Role } from "../../enums";
import { withAccess } from "../../services/role-guard.service";
import { ManagementSection } from "../../containers";

const Dashboard = () => {
  return (
    <>
      <ManagementSection />
    </>
  );
}

export default withAccess(Dashboard, [Role.ADMIN, Role.DATA_OPERATOR, Role.USER_MANAGER]);