import React, { useEffect, useState } from "react";
import { TextField, Button, FormControl, InputLabel, MenuItem, Select, Dialog, DialogContent, IconButton } from "@mui/material";
import { toast } from "react-toastify";
import { HighlightOff } from "@mui/icons-material";
import { getAllPlans, upgradeUserPlan } from "../services/user.service";

type UpdateUserPlanDialogProps = {
  open: boolean;
  onClose: () => void;
  data: {
    planId: string,
    email: string,
  }
};

export const UpdatedUserPlan = ({ open, onClose, data }: UpdateUserPlanDialogProps) => {

  const [selectedPlanId, setUserPlanId] = useState<any>(data.planId);
  const [selectedPlan, setUserPlan] = useState<any>(null);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    fetchAllPlans();
  }, []);

  const fetchAllPlans = async () => {
    await getAllPlans().then(
      ((res: any) => {
        setPlans(res);
        setUserPlan(findPlanById(selectedPlanId, res));
      })
    ).catch(
      (err) => {
        toast.error("Something went wrong.");
      }
    );
  }

  const handleInputChange = (event: any) => {
    setUserPlanId(event.target.value);
    setUserPlan(findPlanById(event.target.value, plans));
  };

  const submitUpgradePlanForm = () => {
    upgradeUserPlan({ userEmail: data.email, newPlanId: selectedPlanId })
      .then((res: any) => {
        toast.success('Plan Updated successfully.');
        resetForm();
        onClose();
      })
      .catch((err: any) => {
        toast.error("Something went wrong.");
      });
  };

  const resetForm = () => {
    setUserPlan(null);
  }

  const covertToLineByLine = (text: any) => {
    return (
      <ul className="mx-5 list-disc"
        dangerouslySetInnerHTML={{ __html: `<li>${text.replace(/\.\s(?!\s*$)/g, '.</li>\n<li>')}</li>` }}
      ></ul>
    )
  }

  const findPlanById = (id: string, tmpPlans: any) => {
    let filteredPlans = tmpPlans.filter((plan: any) => plan.id === id);
    if (filteredPlans.length > 0) {
      return filteredPlans[0];
    }
    return [];
  }
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <div className="flex flex-row bg-gradient-to-b from-[#41090b] to-[#150303] justify-end p-2 gap-2 items-center border-b-[0.1px] border-b-black border-opacity-50">
        <IconButton
          onClick={() => { resetForm(); onClose(); }}
          size="small"
          className="action"
          sx={{ color: "#D91E25" }}
        >
          <HighlightOff fontSize="small" />
        </IconButton>
      </div>
      <DialogContent>
      <div className="flex flex-col">
        <form className="flex flex-col gap-5 w-full h-full">
          <p className="text-2xl font-bold my-2 text-primary">Change User Plan</p>
          <TextField
            size="small"
            name="userEmail"
            id="outlined-user-email"
            label="Enter user email address"
            value={data.email}
            disabled={true}
          />
          {(plans && plans.length > 0) && (
            <>
              <FormControl fullWidth size='small'>
                <InputLabel id='select-plan'>Select Plan</InputLabel>
                <Select
                  labelId='select-plan'
                  id='select-plan'
                  variant='outlined'
                  name='selectedPlan'
                  value={selectedPlanId}
                  label='Select Plan'
                  onChange={handleInputChange}
                >
                  {plans.map((plan: any) => (
                    <MenuItem value={plan.id} key={plan.id}>
                      {plan.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {
                selectedPlan && (
                  <div className="text-sm break-words bg-yellow-100 border border-yellow-400 rounded py-1 px-2">
                    <p><b>Package Name: </b> {selectedPlan?.name}</p>
                    <p><b>Package Detail:</b></p>
                    <div>
                      {selectedPlan?.details ? (covertToLineByLine(selectedPlan?.details)) : 'N/A'}
                    </div>
                  </div>
                )
              }
            </>
          )}
        </form>
        <div className="mt-3">
          <Button
            className="w-full mt-4"
            disabled={(!selectedPlan)}
            variant="contained"
            color='primary'
            onClick={submitUpgradePlanForm}
          >
            Change plan
          </Button>
        </div>
      </div>
      </DialogContent>
    </Dialog>
  );
};