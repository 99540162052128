import React from 'react';
import { RemoveRedEye } from '@mui/icons-material';
import { formatDateToMonthDayYear } from '../app.utils';

interface BlogCardProps {
  blog: any; // Define a more specific type based on your blog data structure
  onPreview: (id: string) => void;
}

export const BlogCard: React.FC<BlogCardProps> = ({ blog, onPreview }) => (
  <div className="flex flex-col lg:flex-row rounded-md gap-3 bg-stone-50 border border-slate-300 shadow p-3 w-full">
    <img src={blog.thumbnail} alt="Uploaded" className="lg:w-44 h-auto rounded-md" />
    <div className="flex flex-col gap-1 justify-between">
      <div className="flex flex-row gap-4 justify-between items-start">
        <p className="text-xl font-semibold pb-2 line-clamp-2">{blog.title}</p>
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex flex-row items-center gap-1 text-primary">
          <p>{blog.view_count}</p>
          <RemoveRedEye />
        </div>
        <p>Posted On: {formatDateToMonthDayYear(blog.createdAt)}</p>
        <p className="text-primary text-xm hover:underline hover:cursor-pointer" onClick={() => onPreview(blog.id)}>
          Click to view in Detail
        </p>
      </div>
    </div>
  </div>
);
