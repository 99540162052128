import React, { createContext, useContext, useState, ReactNode } from 'react';
import { ContainerLoader } from '../components';

// Define the type for LoaderContext
interface LoaderContextType {
  loading: boolean;
  showLoader: (message?: string) => void;
  hideLoader: () => void;
}

// Create a context for the loader
const LoaderContext = createContext<LoaderContextType | undefined>(undefined);

// Loader Provider component
export const LoaderProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState<string | undefined>();

  const [counter, setCounter] = useState(0);

  // Function to show the loader
  const showLoader = (message?: string) => {
    setCounter((prevCounter) => prevCounter + 1);
    setLoading(true);
    setLoaderMessage(message);
  };

  // Function to hide the loader
  // const hideLoader = () => {
  //   setCounter((prevCounter) => Math.max(prevCounter - 1, 0));
  //   if (counter <= 1) { // Check if counter is less than or equal to 1
  //     setLoading(false);
  //     setLoaderMessage('');
  //   }
  // };
  // Function to hide the loader
  const hideLoader = () => {
    setCounter((prevCounter) => Math.max(prevCounter - 1, 0));
    if (counter <= 1) { // Check if counter is less than or equal to 1
      setTimeout(() => {
        setLoading(false);
        setLoaderMessage('');
      }, 650); // Delay for 650 milliseconds
    } else {
      setLoading(false);
      setLoaderMessage('');
    }
  };

  return (
    <LoaderContext.Provider value={{ loading, showLoader, hideLoader }}>
      {children}
      <ContainerLoader isLoading={loading} text={loaderMessage} />
    </LoaderContext.Provider>
  );
};

// Custom hook to use loader state and functions
export const useLoader = (): LoaderContextType => {
  const context = useContext(LoaderContext);
  if (!context) {
    throw new Error('useLoader must be used within a LoaderProvider');
  }
  return context;
};
