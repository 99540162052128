import { Skeleton } from "@mui/material";
import React from "react";

export const SkeletonLoading = ({ isLoading = false, cols = 4, rows = 13 }) => {
  return (
    <>
      {isLoading && (
        <div className="flex flex-col p-4 w-full">
          {
            Array.from({ length: rows }).map((i) => (
              <div className="flex flex-row gap-1 w-full">
                {
                  Array.from({ length: cols }).map((i) => (
                    <Skeleton className="w-full" height={40} />
                  ))
                }
              </div>
            ))
          }
        </div>
      )}
    </>
  );
};