import React, { useEffect } from 'react';
import { useState } from 'react';
import { Autocomplete, Button, createFilterOptions, Divider, FormControl, TextField } from '@mui/material';
import { useLoader } from '../../providers';
import { BackButton } from '../../components';
import { toast } from 'react-toastify';
import { createCourt } from '../../services/courts.service';
import { fetchHierarchies } from '../../services/hierarchies.service';

const AddCourt = () => {
  const { showLoader, hideLoader } = useLoader();

  const [hierarchies, setHierarchies] = useState<any>([]);

  const [selectedHierarchy, setSelectedHierarchy] = useState<any>({ id: '', name: '' });

  const [formErrors, setFormErrors] = useState<any>({});
  const [touchedFields, setTouchedFields] = useState<any>({});

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    limit: 10,
  });

  const [formValue, setFormValues] = useState({
    courtName: '',
  });

  useEffect(() => {
    fetchHierarchies().then((res) => {
      setHierarchies(res);
    })
  }, []);

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    let newErrors: any = {};
    let updatedFormValue = { ...formValue, [name]: value };
    switch (name) {
      case 'courtName':
        if (!value) {
          newErrors.courtName = 'Court Name is required';
        } else {
          delete formErrors.courtName;
        }
        break;
      default:
        break;
    }
    setFormValues(updatedFormValue);
    setFormErrors((prevErrors: any) => {
      return { ...prevErrors, ...newErrors };
    });
  };

  const handleFieldBlur = (event: any) => {
    const { name } = event.target;
    setTouchedFields((prevTouched: any) => {
      return { ...prevTouched, [name]: true };
    });
  };

  const isFormInvalid = () => {
    return formValue.courtName === '';
  }

  const addCourt = async () => {
    showLoader();
    createCourt({ courtName: formValue.courtName, heirarchy: selectedHierarchy.id }).then((res) => {
      hideLoader();
      if (res.success) {
        toast.success('Court Created Successfully.');
      } else {
        toast.error(res.message);
      }
    }).catch((error) => {
      toast.error('Failed to save court.');
      hideLoader();
    });
  }

  return (
    <div className='flex flex-col gap-4 flex-1 rounded-container'>
      <div className='flex flex-col gap-2'>
        <div className='flex flex-row justify-start items-start'>
          <BackButton />
        </div>
        <p className='text-3xl font-bold'>Add New Court</p>
        <Divider />
      </div>
      <div className='flex flex-col gap-6 w-1/2'>
        <div className='flex flex-col gap-3 w-full'>
          <p className='text-xl font-bold pt-1'>Select Court Heirarchy</p>
          <FormControl fullWidth size='small'>
            <Autocomplete
              filterOptions={filterOptions}
              options={hierarchies}
              getOptionLabel={(option) => option.name || 'Select Hierarchy'}
              value={selectedHierarchy}
              onChange={(event, value) => setSelectedHierarchy(value)}
              size='small'
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={selectedHierarchy && selectedHierarchy.length === 0 ? 'Select Hierarchy' : ''}
                />
              )}
            />
          </FormControl>
        </div>
        <div className='flex flex-col gap-3 w-full'>
          <p className='text-xl font-bold pt-1'>Court Name</p>
          <FormControl fullWidth>
            <TextField
              size='small'
              placeholder='Enter Text'
              variant='outlined'
              name='courtName'
              type={'text'}
              required
              onChange={handleInputChange}
              value={formValue.courtName}
              onBlur={handleFieldBlur}
              error={!!(formErrors.courtName && touchedFields.courtName)}
              helperText={touchedFields.courtName ? formErrors.courtName : ''}
            />
          </FormControl>
        </div>
        {/* Submit Button */}
        <Button
          type='submit'
          className='max-w-max'
          disabled={isFormInvalid()}
          variant='contained'
          color='primary'
          onClick={() => addCourt()}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddCourt;