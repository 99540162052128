// React import
import React, { useEffect, useState } from 'react';

// Material import
import {
  Autocomplete,
  Button,
  FormControl,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';

// Router imports
import { useLocation, useNavigate } from 'react-router-dom';

// Contants import
import { constants } from '../../app.constants';

// Container import

// Component import

// Service import
import { logout } from '../../services/auth.service';

// Third party imports
import axios from 'axios';
import { toast } from 'react-toastify';
import { concatenateStrings, decryptString, formatDate, isAuthorised } from '../../app.utils';
import { Launch } from '@mui/icons-material';
import { useLoader } from '../../providers';
import { getAllJudgmentsByJudgeAndCourt } from '../../services/judge.service';
import { RecordCount, SaveSearchButton } from '../../components';


const JudgmentsByCourt = () => {
  const { showLoader, hideLoader } = useLoader();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [judge, setJudge] = useState<any>('');
  const [judges, setJudges] = useState([]);
  const [Court, setCourt] = useState('');
  const [Courts, setCourts] = useState([]);
  const [casesPageNo, setCasesPageNo] = useState(1);

  const [allJudgments, setAllJudgments] = useState([]);
  const [totalAllJudgmentPages, setTotalAllJudgmentPages] = useState(1);
  const [totalAllJudgments, setTotalAllJudgments] = useState(0);

  useEffect(() => {
    fetchCourts();
  }, []);

  const fetchCourts = async () => {
    showLoader();
    await axios
      .get(
        `${process.env.REACT_APP_API_NEST_URL}/court-search/all`,
        {
          headers: {
            Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
          },
        }
      )
      .then((response: any) => {
        setCourts(response.data);
        if (response.data.length > 0 && searchParams.get('court')) {
          const courtParam = searchParams.get('court')?.toString();
          handleChangeCourt(courtParam);
        }
        hideLoader();
      })
      .catch((error) => {
        hideLoader();
        // logout; if unauthorised
        if (error.response.status === 401) {
          logout();
        }
        toast.error('Failed to fetch courts.');
      });
  }

  const handleChangeCourt = (courtId: any) => {
    resetSelections();
    setCourt(courtId);
    setJudge('');
    setJudges([]);
    showLoader();
    axios
      .post(
        `${process.env.REACT_APP_API_NEST_URL}/judge-search/judges-by-courts/`,
        {
          court: courtId,
        },
        {
          headers: {
            Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
          },
        }
      )
      .then((response) => {
        setJudges(response.data);
        if (response.data.length > 0 && searchParams.get('judge')) {
          const judgeParam = searchParams.get('judge')?.toString();
          handleChangeJudge(judgeParam, courtId);
        }
        hideLoader();
      })
      .catch((error) => {
        hideLoader();
        if (error.response.status === 401) {
          logout();
        }
        toast.error('Failed to fetch judges of selected court.');
      });
  };

  const handleChangeJudge = (event: any, selectedCourt = Court) => {
    resetSelections();
    setJudge(event);
    fetchAllJudgments(event, selectedCourt);
  };

  const fetchAllJudgments = async (selectedJudge = judge, selectedCourt = Court, selectedPageNo = casesPageNo) => {
    showLoader();
    await getAllJudgmentsByJudgeAndCourt({
      judge: selectedJudge,
      court: selectedCourt,
      pageNo: selectedPageNo,
      pageSize: constants.pageSize,
    }).then(
      (res) => {
        if (res.data && res.totalPages && res.totalRecords) {
          if (res.data.length === 0) {
            toast.warning('No judgments found for the selected Judge.');
            setAllJudgments([]);
            setTotalAllJudgmentPages(0);
            setTotalAllJudgments(0);
          } else {
            setAllJudgments(res.data);
            setTotalAllJudgmentPages(res.totalPages);
            setTotalAllJudgments(res.totalRecords);
          }
        }
        hideLoader();
      }
    ).catch((error) => {
      hideLoader();
    })
  }

  /**
   * Function to handle page change
   * @param {Event} event
   * @param {number} value
   */
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    searchParams.set('casesPageNo', value.toString());
    navigate({ search: searchParams.toString() });
    setCasesPageNo(value);
  };

  const openJudgment = (item: any) => {
    const url = '/cases/judgment?judgmentId=' + item?._id?.$oid;
    window.open(url, '_blank');
  };

  useEffect(() => {
    if (judge) {
      fetchAllJudgments();
    }
  }, [judge, casesPageNo]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const casesPageNoParam = !isAuthorised() ? 1 : Number.parseInt(searchParams.get('casesPageNo') || '1');
    setCasesPageNo(casesPageNoParam);
  }, [location.search]);

  const resetSelections = () => {
    setTotalAllJudgmentPages(1);
    setCasesPageNo(1);
    setAllJudgments([]);
  }

  return (
    <div className='flex flex-col gap-4 w-full'>
      <div className='flex flex-col gap-5 rounded-container'>
        <div className='flex flex-row justify-between items-center'>
          <p className='text-2xl font-bold'>Search Judgments by Court</p>
          <SaveSearchButton />
        </div>
        <div className='flex flex-row w-100 gap-4'>
          <FormControl fullWidth size='small'>
            <Autocomplete
              size='small'
              disablePortal
              disabled={Courts.length === 0}
              value={Courts.length > 0 ? Courts.find((c: any) => c.name === Court) : ''}
              onChange={(e: any, selectedValue: any) => {
                if (selectedValue) {
                  searchParams.delete('judge');
                  searchParams.set('court', selectedValue.name.toString());
                  navigate({ search: searchParams.toString() });
                  handleChangeCourt(selectedValue.name.toString());
                } else {
                  searchParams.delete('court');
                  setCourt('');
                }
              }}
              options={Courts}
              getOptionLabel={(option: any) => option.name || ''}
              renderOption={(props: any, option: any) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={Courts.length === 0 ? 'No courts found' : 'Select Court'}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth size='small'>
            <Autocomplete
              size='small'
              disablePortal
              disabled={judges.length === 0}
              value={judges.length > 0 ? judges.find((c: any) => c.name === judge) : ''}
              onChange={(e: any, selectedValue: any) => {
                if (selectedValue) {
                  searchParams.set('judge', selectedValue.name.toString());
                  navigate({ search: searchParams.toString() });
                  handleChangeJudge(selectedValue.name.toString());
                } else {
                  searchParams.delete('judge');
                  setJudge('');
                }
              }}
              options={judges}
              getOptionLabel={(option: any) => option.name || ''}
              renderOption={(props: any, option: any) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={Court !== '' && judges.length === 0 ? 'No judges found' : 'Select Judge'}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </FormControl>
        </div>
      </div>
      {(Court === '' || judge === '') ? (
        <div className='rounded-container'>
          <div>
            <p className=''>Please select Judge to view Judgments.</p>
          </div>
        </div>
      ) : (
        <div className='flex flex-col gap-4 justify-between rounded-container'>
          <div className='flex flex-row justify-between items-center pb-0'>
            <p className='text-2xl font-bold'>Judgments</p>
          </div>
          <div className={`overflow-auto border rounded-md ${!isAuthorised() ? 'h-[calc(100vh-21.5rem)]' : 'h-[calc(100vh-18.5rem)]'}`}>
            <Table aria-label='simple table' size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span className='font-bold text-md'>Party Name</span>
                  </TableCell>
                  <TableCell>
                    <span className='font-bold text-md'>Appeal</span>
                  </TableCell>
                  <TableCell>
                    <span className='font-bold text-md'>Reported as</span>
                  </TableCell>
                  <TableCell>
                    <span className='font-bold text-md'>Court</span>
                  </TableCell>
                  <TableCell className='min-w-[10rem]'>
                    <span className='font-bold text-md'>Judgment Date</span>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <span className='font-bold text-md'>Result</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allJudgments.length > 0 ?
                  allJudgments.map((item: any, index) => (
                    <TableRow key={item._id?.$oid} className='border-b-0 show-actions-on-hover' hover={true}>
                      <TableCell>
                        <div className='flex flex-row items-center gap-1'>
                          <Tooltip title={(item.appeallant || 'N/A') + ' vs ' + (item.respondant || 'N/A')} placement='bottom-start'>
                            <p className='max-w-[28rem] truncate'>
                              {(item.appeallant || 'N/A') + ' vs ' + (item.respondant || 'N/A')}
                            </p>
                          </Tooltip>
                          {
                            (isAuthorised() || index <= 2) && (
                              <Tooltip title={'View Case details'}>
                                <div className='cursor-pointer action' onClick={(e) => {
                                  openJudgment(item);
                                }}>
                                  <Launch className='text-primary' sx={{ fontSize: '1.2rem' }} />
                                </div>
                              </Tooltip>
                            )
                          }
                        </div>
                      </TableCell>
                      <TableCell>
                        <Tooltip placement='bottom-start' title={item.appeal}>
                          <p className='max-w-[18rem] truncate'>
                            {item.appeal || 'N/A'}
                          </p>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip placement='bottom-start' title={concatenateStrings(item.citationNames) || 'N/A'}>
                          <p className='max-w-[18rem] truncate'>
                            {item.reported ? (
                              concatenateStrings(item.citationNames)
                            ) : ('Unreported')}
                          </p>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip placement='bottom-start' title={item.court}>
                          <p className='max-w-[18rem] truncate'>
                            {item.court || 'N/A'}
                          </p>
                        </Tooltip>
                      </TableCell>
                      <TableCell >
                        {formatDate(item.judgment_date) || 'N/A'}
                      </TableCell>
                      <TableCell className='min-w-[16rem]'>
                        {item.result || 'N/A'}
                      </TableCell>
                      <TableCell align='right'>
                        <Tooltip title={(!isAuthorised() && index > 2) ? constants.tooltips.upgradePlan : ''} placement='bottom-start'>
                          <span>
                            <Button
                              disabled={!isAuthorised() && index > 2}
                              className='w-[5.6rem] action'
                              variant='contained'
                              size='small'
                              color='primary'
                              onClick={(e) => {
                                openJudgment(item);
                              }}
                            >
                              View Detail
                            </Button>
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )) : (
                    <TableRow>
                      <TableCell colSpan={6} align={'center'}>
                        No Judgments found!
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </div>
          <div className='flex flex-row justify-between items-end'>
            <RecordCount pageNo={casesPageNo} pageSize={Number.parseInt(constants.pageSize)} totalRecords={totalAllJudgments} />
            <Stack spacing={2} className='flex flex-row justify-end'>
              <Tooltip title={(!isAuthorised()) ? constants.tooltips.upgradePlan : ''} placement='left'>
                <span>
                  <Pagination
                    color="primary"
                    disabled={!isAuthorised()}
                    page={casesPageNo}
                    count={totalAllJudgmentPages}
                    siblingCount={0}
                    boundaryCount={1}
                    onChange={handlePageChange}
                    shape={'rounded'}
                  />
                </span>
              </Tooltip>
            </Stack>
          </div>
        </div>
      )}
    </div>
  );
};

export default JudgmentsByCourt;