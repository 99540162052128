import axios from "axios";
import { decryptString } from "../app.utils";
import { Role } from "../enums";

const host = process.env.REACT_APP_API_NEST_URL || 'localhost:3001';

const API_URL = `http://${host}/auth`;

export const signIn = async (userData: any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_NEST_URL}/auth/signin`,
      userData,
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSigninMethods = async (userData: any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_NEST_URL}/auth/methods`,
      userData,
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const authOrg = async (organizationID: string) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_NEST_URL}/auth/org`,
      { orgId: organizationID }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const signUp = async (userData: any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_NEST_URL}/auth/signup`,
      userData,
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const logout = () => {
  localStorage.clear();
  window.location.href = '/';
}

export const isAuthenticated = () => {
  const authenticated = decryptString(localStorage.getItem('isAuthenticated'));
  const isVerified = decryptString(localStorage.getItem('isVerified'));
  return authenticated && isVerified;
}

export const currentUserRole = (): Role => {
  const userRole = decryptString(localStorage.getItem('role'));
  if (userRole) {
    return userRole as Role;
  }
  return Role.FREE;
}

export const isAdmin = () => {
  return Role.ADMIN === currentUserRole();
}

export const isDataOperator = () => {
  return Role.DATA_OPERATOR === currentUserRole();
}

export const isUserManager = () => {
  return Role.USER_MANAGER === currentUserRole();
}

export const isOrgUser = () => {
  return Role.ORGANIZATION === currentUserRole();
}

export const isPremium = () => {
  return Role.PREMIUM === currentUserRole();
}

export const isFreeUser = () => {
  return Role.FREE === currentUserRole();
}

export const isOutOfOrganization = () => {
  return isOrgUser() && localStorage.getItem('outOfOrganization') === 'true';
}

export const isAuthorised = () => {
  if (isFreeUser() || isOutOfOrganization()) {
    return false;
  } else if (isPremium() || isAdmin() || isDataOperator() || isUserManager() || isOrgUser()) {
    return true;
  } else {
    logout();
  }
}

export const sendVerificationEmail = async (userData: any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_NEST_URL}/auth/send-verification-email`,
      userData,
      {
        headers: {
          Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};