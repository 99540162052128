import React from 'react';
import { currentUserRole } from '../services/auth.service';
import { Role } from '../enums';

// Type definition for TabProps
type TabProps = {
  label: string;
  isActive: boolean;
  onClick: () => void;
};

// Tab component
const Tab: React.FC<TabProps> = ({ label, isActive, onClick }) => {
  const activeClass = isActive ? 'bg-primary text-white font-semibold' : 'bg-white text-gray-500';
  return (
    <span className={`py-1 px-4 max-w-max text-center cursor-pointer font-normal text-lg rounded-md ${activeClass}`} onClick={onClick}>
      {label}
    </span>
  );
};

// Type definition for TabListProps
type TabListProps = {
  tabs: {
    id: string;
    label: string;
    visibleTo?: Role[]; // Optional role property to specify which role can see the tab
  }[];
  activeTab: string;
  onTabChange: (tabId: string) => void;
};

// TabList component
export const TabList: React.FC<TabListProps> = ({ tabs, activeTab, onTabChange }) => {
  const userRole = currentUserRole();

  return (
    <div className="flex flex-row justify-between items-center w-full text-lg font-bold p-3 rounded-container">
      <div className="flex flex-row gap-1">
        {tabs.filter(tab => !tab.visibleTo || tab.visibleTo.includes(userRole)).map(tab => (
          <Tab
            key={tab.id}
            label={tab.label}
            isActive={tab.id === activeTab}
            onClick={() => {
              onTabChange(tab.id);
            }}
          />
        ))}
      </div>
    </div>
  );
};
