import React from 'react';
import { currentUserRole, isAuthenticated, logout } from "./auth.service";
import { Role } from "../enums";

const hasAccess = (accessAllowedTo: Role[]): boolean => {
  return accessAllowedTo.includes(currentUserRole());
}

export const withAccess = (Component: any, accessAllowedTo: Role[]) => {
  return function WithWrapper(props: any) {
    const hasPermission = hasAccess(accessAllowedTo);
    if (isAuthenticated() && hasPermission) {
      return <Component {...props} />
    } else {
      window.location.href = '/';
      return null;
    }
  }
}