import React from "react";
import { useState, useEffect } from "react";
import { constants } from "../app.constants";

export const FeaturesScroller = ({ width = 1500 }) => {

  const [currentIndex, setCurrentIndex] = useState(0);
  const { applicationFeatures } = constants;

  useEffect(() => {
    const interval = setInterval(() => {
      goToNext();
    }, 4000); // Change slide every 4 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [currentIndex]); // Dependency array includes currentIndex to reset interval on index change

  const goToPrevious = () => {
    const newIndex = currentIndex === 0 ? applicationFeatures.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const newIndex = currentIndex === applicationFeatures.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToIndex = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className="carousel-container" style={{ width }}>
      <div
        className="carousel-wrapper"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {applicationFeatures.map((feature, index) => (
          <div className="carousel-slide px-10" key={index}>
            <div className='grid grid-cols-2 gap-12 p-12 w-full'>
              <img className='rounded-lg border border-primary shadow-lg' src={feature.img} alt={feature.title} />
              <div className='flex flex-col gap-4 justify-start items-start text-left'>
                <p className='font-bold gradient-text-red text-xl'>{feature.title}</p>
                <p>{feature.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <button className="arrow prev" onClick={goToPrevious}>&lt;</button>
      <button className="arrow next" onClick={goToNext}>&gt;</button>
      <div className="indicator-container">
        {applicationFeatures.map((_, index) => (
          <div
            key={index}
            className={`indicator ${currentIndex === index ? 'selected' : ''}`}
            onClick={() => goToIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};