import { Autocomplete, Button, Divider, FormControl, InputAdornment, InputLabel, MenuItem, Pagination, Select, Stack, TextField, Tooltip, createFilterOptions } from "@mui/material";
import React, { useEffect, useState } from "react";
import { decryptString, formatDate, isAuthorised } from "../../app.utils";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { logout } from "../../services/auth.service";
import { toast } from "react-toastify";
import { KeyboardArrowLeft, KeyboardArrowRight, RemoveCircleOutline, Search } from "@mui/icons-material";
import { constants } from "../../app.constants";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import { useLoader } from "../../providers";
import { SaveSearchButton, SearchResultCard } from "../../components";

const SearchResult = () => {
  const { showLoader, hideLoader } = useLoader();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hierarchy, setHeirarchy] = useState('');
  const [court, setCourt] = useState('');
  const [judge, setJudge] = useState('');
  const [pageNo, setPageNo] = useState(1);
  const [sortBy, setSortBy] = useState('r');
  const [cases, setCases] = useState<any>([]);
  const [hierarchies, setHierarchies] = useState<any>([]);
  const [courts, setCourts] = useState<any>({});
  const [judges, setJudges] = useState<any>({});
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [openFilters, setOpenFilters] = React.useState(true);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (!isAuthorised()) {
      searchParams.set('casesPageNo', '1');
      navigate({ search: searchParams.toString() });
    }
    const searchTermParam = searchParams.get('searchTerm') || '';
    setSearchTerm(searchTermParam);
    setSearchText(searchTermParam);
    const casesPageNoParam = Number.parseInt(searchParams.get('casesPageNo') || '1');
    setPageNo(casesPageNoParam);
    setSearchTerm(searchTermParam);
    const hierarchyParam = searchParams.get('hierarchy') || '';
    setHeirarchy(hierarchyParam);
    const courtParam = searchParams.get('court') || '';
    setCourt(courtParam);
    const judgeParam = searchParams.get('judge') || '';
    setJudge(judgeParam);
    fetchCasesES(searchTermParam, casesPageNoParam, judgeParam, hierarchyParam, courtParam);
  }, [location.search, sortBy]);

  useEffect(() => {
    const searchTermParam = searchParams.get('searchTerm') || '';
    fetchHierarchies(searchTermParam);
    fetchCourts(searchTermParam);
    fetchJudges(searchTermParam);
  }, [searchTerm]);

  useEffect(() => {
    fetchJudges();
  }, [court]);

  useEffect(() => {
    fetchCourts();
  }, [hierarchy]);

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    limit: 10,
  });

  const toggleDrawer = () => {
    setOpenFilters(!openFilters);
  };

  const handleStartDateChange = (value: any) => {
    setStartDate(value);
  }

  const handleEndDateChange = (value: any) => {
    setEndDate(value);
  }

  const fetchCasesES = async (searchTermVal = searchTerm, currentPageNo = pageNo, selectedJudge = judge, selectedHierarchy = hierarchy, selectedCourt = court) => {
    if (searchTermVal !== '') {
      let requestBody: any = {
        searchTerm: searchTermVal,
        pageNo: currentPageNo || 1,
        hierarchyFilter: selectedHierarchy,
        judgeFilter: selectedJudge,
        courtFilter: selectedCourt,
        totalCount: '25',
        sortOrder: sortBy === 'r' ? '' : sortBy,
      };
      if (startDate || endDate) {
        requestBody['dateFilter'] = {};
        if (startDate) {
          requestBody['dateFilter']['from'] = formatDate(startDate.toString());
        }
        if (endDate) {
          requestBody['dateFilter']['to'] = formatDate(endDate.toString());
        }
      }
      showLoader();
      await axios
        .post(
          `${process.env.REACT_APP_API_NEST_URL}/case-search/elastic-search`,
          requestBody,
          {
            headers: {
              Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
            },
          }
        )
        .then((res) => {
          if (res.data) {
            setCases(res.data.data)
            setCases(res.data?.data || []);
            setTotalRecords(res.data.totalCount || 0);
            setTotalPages((res.data.totalPages || 0))
          }
        })
        .catch((err) => {
          toast.error('Failed to search cases.');
          if (err.response.status === 401) {
            logout();
          }
        }).finally(() => hideLoader());
    }
  }

  /**
   * Function to handle page change
   * @param {Event} event
   * @param {number} value
   */
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    searchParams.set('casesPageNo', value.toString());
    navigate({ search: searchParams.toString() });
    setPageNo(value);
  };

  const fetchJudges = async (searchTermParam = searchTerm, selectedCourt = court) => {
    if (searchTermParam !== "") {
      showLoader();
      await axios
        .post(
          `${process.env.REACT_APP_API_NEST_URL}/case-search/filter-judges-es`,
          {
            searchTerm: searchTermParam,
            courtId: selectedCourt,
          },
          {
            headers: {
              Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
            },
          }
        )
        .then((res) => {
          setJudges(res.data);
        })
        .catch((err) => {
          toast.error('Failed to fetch judges.');
          if (err.response.status === 401) {
            logout();
          }
        }).finally(
          () => hideLoader()
        );
    }
  }

  const fetchCourts = async (searchTermParam = searchTerm) => {
    if (searchTermParam !== "") {
      showLoader();
      await axios
        .post(
          `${process.env.REACT_APP_API_NEST_URL}/case-search/filter-courts-es`,
          {
            searchTerm: searchTermParam,
            hierarchyId: hierarchy,
          },
          {
            headers: {
              Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
            },
          }
        )
        .then((res) => {
          setCourts(res.data);
        })
        .catch((err) => {
          toast.error('Failed to fetch courts.');
          if (err.response.status === 401) {
            logout();
          }
        }).finally(
          () => hideLoader()
        );
    }
  }

  const fetchHierarchies = async (searchTermParam = searchTerm) => {
    if (searchTermParam !== "") {
      showLoader();
      await axios
        .post(
          `${process.env.REACT_APP_API_NEST_URL}/case-search/filter-hierarchy-es`,
          {
            searchTerm: searchTermParam,
          },
          {
            headers: {
              Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
            },
          }
        )
        .then((res) => {
          if (res.data) {
            setHierarchies(res.data);
          }
        })
        .catch((err) => {
          toast.error('Failed to fetch hierarchies.');
          if (err.response.status === 401) {
            logout();
          }
        }).finally(
          () => hideLoader()
        );
    }
  }

  const resetSelection = () => {
    setCourt('');
    setJudge('');
    setHeirarchy('');
    searchParams.delete('court');
    searchParams.delete('judge');
    searchParams.delete('hierarchy');
    setStartDate(null);
    setEndDate(null)
    setCases([]);
    resetPagination();
  }

  const resetPagination = () => {
    setPageNo(1);
    searchParams.set('casesPageNo', '1');
    setCases([])
    navigate({ search: searchParams.toString() });
  }

  const resetSearch = () => {
    setCases([]);
    setSearchTerm('');
    setSearchText('');
    setCourt('');
    setJudge('');
    setHeirarchy('');
    searchParams.delete('court');
    searchParams.delete('judge');
    searchParams.delete('hierarchy');
    searchParams.delete('searchTerm');
    resetPagination()
  }

  return (
    <div className="flex flex-row gap-4">
      <div className="flex flex-row relative w-full">
        <div className="flex flex-col gap-4 h-full w-full">
          <div className="flex flex-col gap-2 w-full rounded-container">
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row gap-1 items-center">
                <p className='text-xl font-bold items-center'>Search Judgments</p>
                <Button color="primary" onClick={resetSearch} endIcon={<RemoveCircleOutline />}>
                  Clear
                </Button>
              </div>
              <SaveSearchButton />
            </div>
            <form className="flex flex-row gap-3 text-lg font-bold"
              onSubmit={(e: any) => {
                e.preventDefault();
                if (searchText !== searchTerm) {
                  resetSearch();
                  searchParams.set('searchTerm', searchText.toString());
                  navigate({ search: searchParams.toString() });
                  setSearchTerm(searchText);
                }
              }}>
              <TextField
                value={searchText}
                fullWidth
                size="small"
                type="text"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search color="primary" className="cursor-pointer" onClick={(e: any) => {
                        if (searchText !== searchTerm) {
                          e.preventDefault();
                          resetSearch();
                          searchParams.set('searchTerm', searchText.toString());
                          navigate({ search: searchParams.toString() });
                          setSearchTerm(searchText);
                        }
                      }} />
                    </InputAdornment>
                  ),
                }}
                placeholder="Enter keywords or phrases. For exact matches, please use quotes."
                onChange={(e) => setSearchText(e.target.value)}
              />
            </form>
          </div>
          {searchTerm !== '' ? (
            <>
              <div className="flex flex-row gap-4 w-full">
                <div className="flex w-full flex-col rounded-xl shadow-xl bg-white">
                  <div className="flex flex-row justify-between items-center p-3">
                    <div className="flex flex-row items-center justify-between w-full">
                      <div className="flex flex-row items-center gap-2">
                        <p className="font-semibold bg-primary text-white py-1 px-[15px] text-sm rounded-[0.65rem] leading-[1.75rem]">Judgments</p>
                        <Button color="primary" onClick={toggleDrawer} variant="outlined">
                          {openFilters ? 'Hide' : 'Show'} Filters
                        </Button>
                      </div>
                      <FormControl size="small" sx={{ minWidth: 120 }}>
                        <InputLabel id="sortBy-label">Sort By</InputLabel>
                        <Select value={sortBy}
                          labelId="sortBy-label"
                          label="sortBy"
                          onChange={(e: any) => { setSortBy(e.target.value); resetPagination() }}>
                          <MenuItem value={'r'}>Relevance</MenuItem>
                          <MenuItem value={'asc'}>Date</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <Divider />
                  <div className={`flex flex-col p-4 gap-2 overflow-auto ${!isAuthorised() ? 'h-[calc(100vh-20rem)]' : 'h-[calc(100vh-17.5rem)]'}`}>
                    {cases.length > 0 ? cases.map((item: any, index: number) => (
                      <div>
                        <SearchResultCard caseDetail={item} isCaseSummaryNull={true} index={index} searchTerm={searchTerm} />
                      </div>
                    )) : (
                      <div className='flex flex-col justify-center items-center flex-1'>
                        <p className="text-2xl text-center text-gray-700">Sorry, no results were found for the specified search term and filters.<br />Please refine your search to see results.</p>
                      </div>
                    )}
                  </div>
                  <Divider />
                  <div className="py-3">
                    <Stack spacing={2} className='flex flex-row justify-center items-center'>
                      <Tooltip title={(!isAuthorised()) ? constants.tooltips.upgradePlan : ''} placement='left'>
                        <span>
                          <Pagination
                            color="primary"
                            page={pageNo}
                            disabled={!isAuthorised()}
                            count={totalPages || 1}
                            siblingCount={1}
                            boundaryCount={0}
                            onChange={handlePageChange}
                            shape={'rounded'}
                          />
                        </span>
                      </Tooltip>
                    </Stack>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className='flex flex-col rounded-container flex-1'>
              <div>
                <p className='text-sm'>Please enter search term to view matched cases.</p>
              </div>
            </div>
          )}
        </div>
        {searchTerm !== '' && (
          <div className='absolute -right-2 top-5 cursor-pointer' onClick={toggleDrawer}>
            {!openFilters ? (<KeyboardArrowLeft className='border bg-white border-primary text-primary rounded-full shadow-md' fontSize='small' />) : (<KeyboardArrowRight className='border bg-white border-primary text-primary rounded-full shadow-md' fontSize='small' />)}
          </div>
        )}
      </div>
      {searchTerm !== '' && openFilters && (
        <div className="flex flex-col gap-2 w-[25rem] h-full p-5 rounded-container">
          <div className="flex flex-row gap-1 items-center justify-between">
            <p className='text-2xl font-bold items-center'>Filters</p>
            <Button color="primary" onClick={resetSelection} endIcon={<RemoveCircleOutline />}>
              Clear
            </Button>
          </div>
          <div className="mt-1 mb-3">
            <Divider />
          </div>
          <div className="flex flex-col gap-6 justify-center items-center">
            <FormControl fullWidth size='small'>
              <Autocomplete
                size='small'
                disablePortal
                disabled={!!(!hierarchy && (court || judge))}
                value={hierarchies.length > 0 ? hierarchies.find((c: any) => c.id === hierarchy) : ''}
                onChange={(e: any, selectedValue: any) => {
                  if (selectedValue) {
                    searchParams.set('hierarchy', selectedValue.id.toString());
                  } else {
                    searchParams.delete('hierarchy');
                    setHeirarchy('');
                  }
                  searchParams.delete('judge');
                  searchParams.delete('court');
                  setCourt('');
                  setJudge('');
                  resetPagination();
                }}
                options={hierarchies}
                getOptionLabel={(option: any) => (option.name + ` (${option.count})`) || ''}
                renderOption={(props: any, option: any) => {
                  return (
                    // <li {...props} key={option.id}>
                    //   {option.name} ({option.count})
                    // </li>
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Hierarchy"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth size='small'>
              <Autocomplete
                size='small'
                disablePortal
                disabled={!!(!court && judge)}
                value={courts.length > 0 ? courts.find((c: any) => c.id === court) : ''}
                onChange={(e: any, selectedValue: any) => {
                  if (selectedValue) {
                    searchParams.set('court', selectedValue.id.toString());
                  } else {
                    searchParams.delete('court');
                    setCourt('');
                  }
                  setJudge('')
                  searchParams.delete('judge');
                  resetPagination()
                }}
                options={courts}
                getOptionLabel={(option: any) => (option.name + ` (${option.count})`) || ''}
                renderOption={(props: any, option: any) => {
                  return (
                    // <li {...props} key={option.id}>
                    //   {option.name} ({option.count})
                    // </li>
                    <li {...props} key={option.id}>
                    {option.name}
                  </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Court"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth size='small'>
              <Autocomplete
                size='small'
                disablePortal
                disabled={!!(hierarchy && !court)}
                filterOptions={filterOptions}
                value={judges.length > 0 ? judges.find((c: any) => c.id === judge) : ''}
                onChange={(e: any, selectedValue: any) => {
                  if (selectedValue) {
                    searchParams.set('judge', selectedValue.id.toString());
                  } else {
                    searchParams.delete('judge');
                    setJudge('');
                  }
                  resetPagination()
                }}
                options={judges}
                getOptionLabel={(option: any) => (option.name + ` (${option.count})`) || ''}
                renderOption={(props: any, option: any) => {
                  return (
                    // <li {...props} key={option.id}>
                    //   {option.name} ({option.count})
                    // </li>
                    <li {...props} key={option.id}>
                    {option.name}
                  </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Judge"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </FormControl>
            <div className="flex flex-col gap-2 w-full border-y border-slate-200 pt-3">
              <p className="font-bold">Start Date:</p>
              <DatePicker
                value={startDate}
                format="YYYY-MM-DD"
                slotProps={{ textField: { size: 'small' } }}
                onChange={handleStartDateChange}
              />
              <p className="font-bold">End Date:</p>
              <DatePicker
                value={endDate}
                format="YYYY-MM-DD"
                slotProps={{ textField: { size: 'small' } }}
                onChange={handleEndDateChange}
              />
              <div className="my-2">
                <Divider />
              </div>
              <Button
                fullWidth
                variant='contained'
                color='primary'
                onClick={() => {
                  fetchCasesES();
                }}
              >
                Search
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchResult;