import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className='flex flex-col py-[5rem] lg:px-[15rem] px-[5rem] w-full h-full bg-white text-lg'>
      <div className='flex flex-col items-center w-full mt-[1rem] mb-[3rem]'>
        <p className='text-6xl font-bold'>Privacy Policy</p>
        <p className='mt-2'>Last Updated: [1st July 2023]</p>
      </div>
      <div className='flex flex-col gap-4'>
        <p>Your privacy is important to us at <a className='font-bold text-primary cursor-pointer hover:underline' onClick={() => { window.open('/') }}>eastlaw.pk</a> ('the Platform'). This Privacy Policy ('Policy') explains how we collect, use, and protect your personal information when you use our Platform. By accessing or using the Platform, you agree to the practices outlined in this Policy. If you do not agree with this Policy, please do not use the Platform.</p>
        <p className='text-2xl font-bold my-3'>1. Information We Collect</p>
        <div className='flex flex-col gap-1'>
          <span className='text-xl font-bold'>1.1. Personal Information:</span>
          <p className='pl-[2.5rem]'>We may collect personal information, including but not limited to your name, email address, contact details, and payment information when you create an account or subscribe to our services.</p>
        </div>
        <div className='flex flex-col gap-1'>
          <span className='text-xl font-bold'>1.2. User Contributions:</span>
          <p className='pl-[2.5rem]'>If you contribute content to the Platform, such as legal research or case summaries, we may collect and store this information.</p>
        </div>
        <p className='text-2xl font-bold my-3'>2. Use of Information</p>
        <div className='flex flex-col gap-1'>
          <span className='text-xl font-bold'>1.2. User Contributions:</span>
          <p className='pl-[2.5rem]'>If you contribute content to the Platform, such as legal research or case summaries, we may collect and store this information.</p>
        </div>
        <div className='flex flex-col gap-1'>
          <span className='text-xl font-bold'>2.1. Service Provision:</span>
          <p className='pl-[2.5rem]'>We use your personal information to provide you with access to our legal research services, including subscriptions, and to communicate with you regarding your account and our services.</p>
        </div>
        <div className='flex flex-col gap-1'>
          <span className='text-xl font-bold'>2.2. User Contributions:</span>
          <p className='pl-[2.5rem]'>User-contributed content may be used, modified, and distributed on the Platform as outlined in our Terms of Use.</p>
        </div>
        <div className='flex flex-col gap-1'><span className='text-xl font-bold'>2.3. Improvement:</span>
          <p className='pl-[2.5rem]'>We may use anonymized data and aggregated information to improve our services and user experience.</p>
        </div>
        <p className='text-2xl font-bold my-3'>3. Data Security</p>
        <p>3.1. We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.</p>
        <p>3.2. However, please note that no data transmission over the internet is completely secure, and we cannot guarantee the absolute security of your information.</p>
        <p className='text-2xl font-bold my-3'>4. Third-Party Services</p>
        <p>4.1. We may use third-party services, such as payment processors, to process payments for subscriptions. These third parties have their own privacy policies, and we recommend reviewing their policies as well.</p>
        <p className='text-2xl font-bold my-3'>5. Cookies and Analytics</p>
        <p>5.1. We may use cookies and similar tracking technologies to collect information about your usage of the Platform for analytics and personalized content.</p>
        <p className='text-2xl font-bold my-3'>6. Access and Control of Your Information</p>
        <p>6.1. You may review, update, or delete your personal information by accessing your account settings.</p>
        <p>6.2. If you wish to delete your account, please contact us at [Contact Email]. Please note that we may retain certain information for legal, regulatory, and business purposes.</p>
        <p className='text-2xl font-bold my-3'>7. Updates to the Privacy Policy</p>
        <p>7.1. We reserve the right to modify, update, or change this Privacy Policy at any time. Users will be notified of any material changes, and continued use of the Platform after such changes constitutes acceptance of the updated Policy.</p>
        <p className='text-2xl font-bold my-3'>8. Contact Information</p>
        <p>8.1. For any questions or concerns regarding this Privacy Policy or the Platform's privacy practices, please contact us at <a className='font-bold text-primary cursor-pointer hover:underline'>info@eastlaw.pk</a></p>
        <p className='mt-4'>By using the Platform, you acknowledge that you have read, understood, and agree to this Privacy Policy.</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;