import React, { useEffect, useState } from "react";
import { Bookmark, BookmarkAddOutlined, HighlightOff } from "@mui/icons-material";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { useLoader } from "../providers";
import { BookmarkTypes } from "../app.enums";
import { decryptString } from "../app.utils";
import { logout } from "../services/auth.service";
import { DocumentViewer, PDFViewer } from "../components";

type DepartmentDocumentPreviewDialogProps = {
  open: boolean;
  onClose: () => void;
  departmentData: {
    selectedFile: string,
    selectedFileId: string,
    selectedDepartTitle: string,
  }
};

export const DepartmentDocumentPreview = ({ open, onClose, departmentData }: DepartmentDocumentPreviewDialogProps) => {

  const { showLoader, hideLoader } = useLoader();
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [bookmark, setBookmark] = useState('');
  const [docData, setDocData] = useState<any>(null);

  useEffect(() => {
    showLoader();
    getDepartmentFile().then((res: any) => {
      if (res.data) {
        setDocData(departmentData.selectedFile.substring(departmentData.selectedFile.length - 3) === 'pdf' ? { blob: res.data, isPdf: true } : { blob: res.data, isPdf: false });
      } else {
        toast.error('Failed to fetch department file.');
        onClose();
      }
    }).catch((error) => {
      toast.error('Failed to fetch department file.');
      onClose();
    }).finally(() => {
      hideLoader();
    });
    getBookmarkState();
  }, []);

  const getDepartmentFile = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_NEST_URL}/department-search/department-file/${departmentData.selectedFileId}`,
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
        },
      });
      if (response) {
        console.log('see this statute ==> ', response);
        return response;
      }
    } catch (error: any) {
      if (error.response.status === 401) {
        logout();
      }
      return error;
    }
  }

  const bookmarkDepartmentFile = () => {
    showLoader();
    axios.post(`${process.env.REACT_APP_API_NEST_URL}/bookmark`,
      {
        header: departmentData.selectedDepartTitle,
        detail: departmentData.selectedFile,
        targetId: departmentData.selectedFileId,
        userId: decryptString(localStorage.getItem('userId')),
        typeName: BookmarkTypes.DEPARTMENT,
      }, {
      headers: {
        Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
      },
    }).then((res) => {
      toast.success('Bookmark created successfully.');
      hideLoader();
    }).catch((err) => {
      hideLoader();
      toast.error("Failed to bookmark statute.");
    });
  };

  const deleteBookmark = async () => {
    const userId = decryptString(localStorage.getItem('userId'));
    const URL = `${process.env.REACT_APP_API_NEST_URL}/bookmark?userId=${userId}&bookmarkIds=${bookmark}`;
    showLoader();
    const response = await axios.delete(URL, {
      headers: {
        Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
      },
    }).catch(err => {
      toast.error('Failed to delete bookmark.');
      hideLoader();
    });
    if (response) {
      toast.success('Bookmark removed successfully.');
      setIsBookmarked(false);
      hideLoader();
    }
  };

  const getBookmarkState = async () => {
    showLoader();
    await axios.get(`${process.env.REACT_APP_API_NEST_URL}/bookmark?targetId=${departmentData.selectedFileId}&typeName=${BookmarkTypes.DEPARTMENT}&userId=${decryptString(localStorage.getItem('userId'))}`, {
      headers: {
        Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
      },
    }).catch(err => {
      setIsBookmarked(false);
      hideLoader();
    }).then(response => {
      hideLoader();
      if (response) {
        if (response.data.data[0]) {
          response.data.data.map(
            (item: any) => {
              if (item.detail === departmentData.selectedFile) {
                setIsBookmarked(true);
                setBookmark(response.data.data[0].id);
              } else {
                setIsBookmarked(false);
              }
            }
          );
        } else {
          setIsBookmarked(false);
        }
      }
    });
  }

  return (
    <Dialog
      maxWidth={false}
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <div className='flex flex-row bg-gradient-to-b from-[#41090b] to-[#150303] justify-between p-2 gap-2 items-center border-b-[0.1px] border-b-black border-opacity-50'>
        {/* Todo: Will replace This header with file name */}
        <p className='font-bold text-primary mx-3'>Department Document</p>
        <div className='flex flex-row gap-2 items-center justify-center'>
          {isBookmarked ? (
            <IconButton
              sx={{ color: '#D91E25' }}
              onClick={() => {
                deleteBookmark();
              }}
              size='small'
              className='action'
              color='primary'>
              <Bookmark fontSize='small' />
            </IconButton>
          ) : (
            <IconButton
              sx={{ color: '#D91E25' }}
              onClick={() => {
                bookmarkDepartmentFile();
              }}
              size='small'
              className='action'
              color='primary'>
              <BookmarkAddOutlined fontSize='small' />
            </IconButton>
          )}
          <IconButton
            sx={{ color: '#D91E25' }}
            onClick={onClose}
            size='small'
            className='action'
            color='primary'>
            <HighlightOff fontSize='small' />
          </IconButton>
        </div>
      </div>
      <DialogContent>
        {docData ? (docData.isPdf ? (
          <PDFViewer blob={docData.blob} />
        ) : (
          <DocumentViewer blob={docData.blob} />
        )) : null}
      </DialogContent>
    </Dialog>
  );
};