import React from "react";
import { useState } from "react";
import { Button, Divider, FormControl, MenuItem, Select, TextField } from "@mui/material";
import { useLoader } from "../../providers";
import { constants } from "../../app.constants";
import { BackButton } from "../../components";
import { createBanner } from "../../services/banners.service";
import { toast } from "react-toastify";

const AddBanner = () => {
  const { showLoader, hideLoader } = useLoader();

  const [formErrors, setFormErrors] = useState<any>({});
  const [touchedFields, setTouchedFields] = useState<any>({});

  const [formValue, setFormValues] = useState({
    bannerText: '',
    bannerType: constants.bannerTypes[0].id,
  });

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    let newErrors: any = {};
    let updatedFormValue = { ...formValue, [name]: value };
    switch (name) {
      case 'bannerText':
        if (!value) {
          newErrors.bannerText = 'Banner Text is required';
        } else {
          delete formErrors.bannerText;
        }
        break;
      default:
        break;
    }
    setFormValues(updatedFormValue);
    setFormErrors((prevErrors: any) => {
      return { ...prevErrors, ...newErrors };
    });
  };

  const handleFieldBlur = (event: any) => {
    const { name } = event.target;
    setTouchedFields((prevTouched: any) => {
      return { ...prevTouched, [name]: true };
    });
  };

  const isFormInvalid = () => {
    return formValue.bannerText === '';
  }

  const uploadBlog = async () => {
    showLoader();
    createBanner({ title: formValue.bannerText, description: formValue.bannerText, type: formValue.bannerType }).then((res) => {
      hideLoader();
      if (res.success) {
        toast.success('Banner Created Successfully.');
      } else {
        toast.error(res.message);
      }
    }).catch((error) => {
      toast.error('Failed to save banner.');
      hideLoader();
    });
  }

  return (
    <div className="flex flex-col gap-4 flex-1 rounded-container">
      <div className="flex flex-col gap-2">
        <div className='flex flex-row justify-start items-start'>
          <BackButton />
        </div>
        <p className="text-3xl font-bold">Add New Banner</p>
        <Divider />
      </div>
      <div className="flex flex-col gap-6 w-1/2">
        <div className="flex flex-col gap-3 w-full">
          <p className="text-xl font-bold pt-1">Banner Text</p>
          <FormControl fullWidth>
            <TextField
              size='small'
              placeholder='Enter Text'
              variant='outlined'
              name='bannerText'
              type={'text'}
              required
              onChange={handleInputChange}
              value={formValue.bannerText}
              onBlur={handleFieldBlur}
              error={!!(formErrors.bannerText && touchedFields.bannerText)}
              helperText={touchedFields.bannerText ? formErrors.bannerText : ""}
            />
          </FormControl>
        </div>
        <div className="flex flex-col gap-3 w-full">
          <p className="text-xl font-bold pt-1">Banner Type</p>
          <FormControl fullWidth size='small'>
            <Select
              variant='outlined'
              value={formValue.bannerType}
              name='bannerType'
              required
              onChange={handleInputChange}
              onBlur={handleFieldBlur}
              error={!!(formErrors.bannerType && touchedFields.bannerType)}
            >
              {constants.bannerTypes.map((type) => (
                <MenuItem key={type.id} value={type.id}>{type.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {/* Submit Button */}
        <Button
          type="submit"
          className="max-w-max"
          disabled={isFormInvalid()}
          variant="contained"
          color='primary'
          onClick={() => uploadBlog()}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddBanner;