import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useScrollToTop } from '../../app.hooks';
import { fetchAllBlogsByCategory } from '../../services/blogs.service';
import { BlogSection, SkeletonCardLoading } from '../../components';


const LegalAwarenessNewsList: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  useScrollToTop();

  const navigate = useNavigate();
  const [newsBlogs, setNewsBlogs] = useState<any[]>([]);
  const [highCourtBlogs, setHighCourtBlogs] = useState<any[]>([]);
  const [supremeCourtBlogs, setSupremeCourtBlogs] = useState<any[]>([]);

  useEffect(() => {
    reloadBlogs();
  }, []);

  const reloadBlogs = () => {
    setIsLoading(true);

    const fetchNewsBlogs = fetchAllBlogsByCategory('latest-news', 1, 5);
    const fetchHighCourtBlogs = fetchAllBlogsByCategory('high-courts', 1, 5);
    const fetchSupremeCourtBlogs = fetchAllBlogsByCategory('supreme-courts', 1, 5);

    Promise.all([fetchNewsBlogs, fetchHighCourtBlogs, fetchSupremeCourtBlogs])
      .then(([newsRes, highCourtRes, supremeCourtRes]) => {
        setNewsBlogs(newsRes.data);
        setHighCourtBlogs(highCourtRes.data);
        setSupremeCourtBlogs(supremeCourtRes.data);
      })
      .catch((err) => {
        toast.error('Failed to fetch blogs.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePreviewBlog = (blogId: string) => {
    navigate(`/legal-news/view?blogId=${blogId}`);
  };

  const handleSeeMore = (category: string) => {
    navigate(`/legal-news/category?category=${category}`);
  };

  return (
    <div className="flex flex-col bg-white w-screen min-h-screen">
      <div className="flex flex-col gap-4 w-full">
        <div className="flex flex-col gap-8 bg-gradient-to-t from-[#41090b] to-[#150303] h-96 items-center justify-center">
          <p className="text-6xl font-bold text-white text-center">Legal Awareness</p>
        </div>
        {isLoading ? (
          <div className="px-20 pt-5">
            <SkeletonCardLoading rows={3} height={300} />
          </div>
        ) : (
          <div className="flex flex-col gap-4 p-5 lg:p-20">
            <p className="gradient-text-red text-center text-3xl md:text-5xl font-bold py-5">
              Checkout our latest Legal Awareness posts
            </p>
            <BlogSection
              title="Latest News"
              blogs={newsBlogs}
              onPreview={handlePreviewBlog}
              onSeeMore={() => handleSeeMore('latest-news')}
              seeMoreAfter={4}
            />
            <BlogSection
              title="High Courts"
              blogs={highCourtBlogs}
              onPreview={handlePreviewBlog}
              onSeeMore={() => handleSeeMore('high-courts')}
              seeMoreAfter={4}
            />
            <BlogSection
              title="Supreme Courts"
              blogs={supremeCourtBlogs}
              onPreview={handlePreviewBlog}
              onSeeMore={() => handleSeeMore('supreme-courts')}
              seeMoreAfter={4}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default LegalAwarenessNewsList;
