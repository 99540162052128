import axios from "axios";
import { decryptString } from "../app.utils";
import { logout } from "./auth.service";

/**
 * Method for getting statutes
 * formData {formData}
 * @returns {Object}
 */
export const registerOrganization = async (file: string | Blob) => {
  try {
    const formData = new FormData();
    formData.append('file', file)
    const response = await axios.post(`${process.env.REACT_APP_API_NEST_URL}/organization/register`,
        formData, {
        headers: {
            Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`,
            'Content-Type': 'multipart/form-data'
        },
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status === 401) {
      logout();
    }
    return error;
  }
}
