import axios from "axios";
import { attachCacheBustingQueryParam, decryptString } from "../app.utils";
import { logout } from "./auth.service";

export const upgradeUserPlan = async (data: any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_NEST_URL}/users/upgrade`,
      data,
      {
        headers: {
          Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response.status === 401) {
      logout();
    }
    throw error;
  }
};

export const upgradeUserPlanRequestEmail = async (name: string, selectedPlan: string) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_NEST_URL}/users/plan-upgrade-request`,
      {
        name,
        planId: selectedPlan,
      },
      {
        headers: {
          Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (error?.response?.status === 401) {
      logout();
    }
    throw error;
  }
};

export const getAllPlans = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_NEST_URL}/users/plans`,
      {
        headers: {
          Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllUsers = async (searchQuery = '', userType = '', pageNo = 1, pageSize = 100) => {
  try {
    const url = attachCacheBustingQueryParam(`${process.env.REACT_APP_API_NEST_URL}/users?searchQuery=${searchQuery}&role=${userType}&pageNo=${pageNo}&pageSize=${pageSize}`);
    const response = await axios.get(url,
      {
        headers: {
          Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};