import { Publish, Edit, RemoveRedEye, Delete } from "@mui/icons-material";
import { Divider, IconButton, Pagination, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isAuthorised } from "../../app.utils";
import { ConfirmationPrompt, PreviewBlog } from "../../dialogs";
import { Role } from "../../enums";
import { useDialog } from "../../providers";
import { fetchAllBlogs, deleteBlog } from "../../services/blogs.service";
import { withAccess } from "../../services/role-guard.service";
import { BackButton, SkeletonLoading } from "../../components";

const LegalNewsManagement = () => {
  const { openDialog, closeDialog } = useDialog();
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalBlogs, setTotalBlogs] = useState(1);

  useEffect(() => {
    reloadBlogs();
  }, [pageNo]);

  /**
   * Function to handle bookmark page change
   * @param {Event} event
   * @param {number} value
   */
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageNo(value);
  };

  const reloadBlogs = () => {
    setIsLoading(true);
    fetchAllBlogs(pageNo).then((res) => {
      setBlogs(res.data);
      setTotalPages(res.totalPages);
      setTotalBlogs(res.totalRecords);
      setIsLoading(false);
    }).catch((err) => {
      toast.error('Failed to fetch blogs.');
      setIsLoading(false);
    })
  }

  const handleDeleteBlog = async (blog: any) => {
    openDialog(ConfirmationPrompt, {
      onClose: () => {
        closeDialog();
      },
      title: `Deleting '${blog.title}'`,
      message: 'Are you sure you want to delete this blog?',
      onConfirm: () => {
        deleteBlog(blog.id).then(() => {
          toast.success('Blog deleted successfully.');
          reloadBlogs();
        }).catch(() => {
          toast.error('Failed to delete the Blog.');
        })
      }
    });
  }

  const handlePreviewBlog = (blog: any) => {
    openDialog(PreviewBlog, {
      onClose: () => {
        closeDialog();
      },
      blogTitle: blog.title,
      blogContent: blog.blog_content,
      blogThumb: blog.thumbnail,
    });
  }

  return (
    <div className="flex flex-col gap-3 rounded-container">
      <div className="flex flex-col gap-2">
        <div className='flex flex-row justify-start items-start'>
          <BackButton />
        </div>
        <p className="text-3xl font-bold">Legal News Management</p>
        <Divider />
      </div>
      <div className="flex flex-row justify-between items-end">
        <p className="text-2xl font-bold">Listed Posts</p>
        <div className="flex flex-row gap-1 justify-center items-center px-3 py-2 border border-slate-300 hover:border-primary shadow rounded-lg cursor-pointer max-w-max"
          onClick={
            () => {
              navigate('/manage/legal-news/add');
            }}>
          <Publish color="primary" />
          <p className="text-primary font-bold">New Post</p>
        </div>
      </div>
      <div className="flex flex-col justify-between h-full">
        <div className={`h-[calc(100vh-17rem)] overflow-auto border rounded-md`}>
          {isLoading ? (<SkeletonLoading isLoading={isLoading} />) : (
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span className="font-bold text-md">Title</span>
                  </TableCell>
                  <TableCell>
                    <span className="font-bold text-md">Status</span>
                  </TableCell>
                  <TableCell>
                    <span className="font-bold text-md">Total Views</span>
                  </TableCell>
                  <TableCell colSpan={3}>
                    <span className="font-bold text-md">Posted On</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {blogs && blogs.length > 0 ? (
                  blogs.map((item: any, index: number) => (
                    <TableRow key={item.id} className="border-b-0 show-actions-on-hover" hover={true}>
                      <TableCell className="max-w-[12rem] truncate">
                        {item.title || 'N/A'}
                      </TableCell>
                      <TableCell className="max-w-[12rem] truncate">
                        <span className={item.active ? 'bagde-email-verified-yes' : 'bagde-email-verified-no'}>{item.active ? 'Active' : 'In Active'}</span>
                      </TableCell>
                      <TableCell className="max-w-[12rem] truncate">
                        {item.view_count || '0'}
                      </TableCell>
                      <TableCell className="max-w-max">
                        {item.createdAt?.split("T")[0] || 'N/A'}
                      </TableCell>
                      <TableCell className="max-w-max truncate">
                        <div className="flex flex-row justify-end items-center gap-4">
                          <Tooltip title={'Edit Blog'} placement='bottom'>
                            <span className="action">
                              <IconButton size="small"
                                onClick={() => navigate(`/manage/legal-news/edit?blogId=${item.id}`)}
                                sx={{
                                  zIndex: '10',
                                  color: "#D91E25",
                                  ":hover": {
                                    color: "#D91E25",
                                  },
                                }}
                              >
                                <Edit fontSize='small' />
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip title={'View Blog'} placement='bottom'>
                            <span className="action">
                              <IconButton size="small"
                                onClick={() => handlePreviewBlog(item)}
                                sx={{
                                  zIndex: '10',
                                  color: "#D91E25",
                                  ":hover": {
                                    color: "#D91E25",
                                  },
                                }}
                              >
                                <RemoveRedEye fontSize='small' />
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip title={'Delete Blog'} placement='bottom'>
                            <span className="action">
                              <IconButton size="small"
                                onClick={() => handleDeleteBlog(item)}
                                sx={{
                                  zIndex: '10',
                                  color: "#D91E25",
                                  ":hover": {
                                    color: "#D91E25",
                                  },
                                }}
                              >
                                <Delete fontSize='small' />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={12} align={"center"}>
                      No Blogs found!
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </div>
        <div className="flex flex-row justify-end">
          <Stack spacing={2} className="flex flex-row mt-4 justify-end">
            <span>
              <Pagination
                color="primary"
                page={pageNo}
                disabled={!isAuthorised()}
                count={totalPages}
                siblingCount={0}
                boundaryCount={1}
                onChange={handlePageChange}
                shape={"rounded"}
              />
            </span>
          </Stack>
        </div>
      </div>

    </div>
  );
}

export default withAccess(LegalNewsManagement, [Role.ADMIN, Role.DATA_OPERATOR]);