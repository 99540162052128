import axios from "axios";
import { decryptString } from "../app.utils";

export const createBanner = async (requestBody: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_NEST_URL}/banners/create`,
      requestBody, {
      headers: {
        Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`,
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status === 403) {
      throw (new Error('Unauthorized Access'));
    } else {
      throw (new Error('Failed to save banner'));
    }
  }
}

export const fetchAllBanners = async (pageNumber: number) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_NEST_URL}/banners?pageNo=${pageNumber}&pageSize=${100}`,
      {
        headers: {
          Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response.status === 403) {
      throw (new Error('Unauthorized Access'));
    } else {
      throw (new Error('Failed to fetch banners'));
    }
  }
}

export const fetchActiveBanners = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_NEST_URL}/banners/active`,
      {
        headers: {
          Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response.status === 403) {
      throw (new Error('Unauthorized Access'));
    } else {
      throw (new Error('Failed to fetch banners'));
    }
  }
}

export const fetchBannerById = async (blogId: string , isAuthorized = false) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_NEST_URL}/banners/${blogId}?isAuthorized=${isAuthorized}`,
      {
        headers: {
          Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response.status === 403) {
      throw (new Error('Unauthorized Access'));
    } else {
      throw (new Error('Failed to fetch banner'));
    }
  }
}

export const deleteBanner = async (blogId: string) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_NEST_URL}/banners/${blogId}`,
      {
        headers: {
          Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response.status === 403) {
      throw (new Error('Unauthorized Access'));
    } else {
      throw (new Error('Failed to delete banner'));
    }
  }
}

export const updateBanner = async (blogId: string, requestBody: any) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_NEST_URL}/banners/${blogId}`,
      requestBody, {
      headers: {
        Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`,
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status === 403) {
      throw (new Error('Unauthorized Access'));
    } else {
      throw (new Error('Failed to update banner'));
    }
  }
}