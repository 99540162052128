import axios from "axios";
import { decryptString } from "../app.utils";

export const fetchHierarchies = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_NEST_URL}/court-search/fetch-hierarchies`,
    {
      headers: {
        Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`,
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status === 403) {
      throw (new Error('Unauthorized Access'));
    } else {
      throw (new Error('Failed to fetch hierarchies'));
    }
  }
}