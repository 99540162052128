import React, { ReactNode } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

type ConfirmationPrompProps = {
  title: string;
  message?: string;
  component?: ReactNode;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const ConfirmationPrompt = (props: ConfirmationPrompProps) => {
  const { title, message, open, onClose, onConfirm, component } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">
        <p className="font-bold">{title}</p>
      </DialogTitle>
      <DialogContent>
        <p>{message}</p>
        {component}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={onClose}
          color="primary"
        >
          No
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={() => {
            onClose();
            onConfirm();
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};