import axios from "axios";
import { decryptString } from "../app.utils";

export const getPracticeAreas = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_NEST_URL}/statutes-search/practice-area`,
      {
        headers: {
          Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}