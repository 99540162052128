// React imports
import React, { useEffect, useState } from 'react';

// Material imports
import {
  Button as Button,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';

// Third party imports
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// Constants imports
import { constants } from '../../app.constants';

// Util imports
import { concatenateStrings, decryptString, formatDate, isAuthorised } from '../../app.utils';

// Service imports
import { logout } from '../../services/auth.service';
import { Launch } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { useLoader } from '../../providers';
import { RecordCount, SaveSearchButton, SkeletonLoading, TabList } from '../../components';

const Cases = () => {
  // initializing hooks
  const { showLoader, hideLoader } = useLoader();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Declaring and initializing states
  const [cases, setCases] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchCitationYear, setSearchCitationYear] = useState('');
  const [searchCitationJournal, setSearchCitationJournal] = useState('');
  const [searchCitationJournalCat, setSearchCitationJournalCat] = useState('');
  const [searchCitationPageNo, setSearchCitationPageNo] = useState('');
  const [isCaseSearching, setIsCaseSearching] = useState(false);
  const [journals, setJournals] = useState([]);
  const [selectedTab, setSelectedTab] = useState('searchByCitation');

  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  const handleStartDateChange = (value: any) => {
    setStartDate(value);
  }

  const handleEndDateChange = (value: any) => {
    setEndDate(value);
  }

  const pageSize = constants.pageSize;
  const tabs = [
    { id: 'searchByCitation', label: 'Judgments by Citation' },
    { id: 'searchByDate', label: 'Latest Judgments' },
  ];

  // Fetch hierarchies on component init
  useEffect(() => {
    if (!searchParams.get('selectedTab')) {
      searchParams.set('selectedTab', 'searchByCitation');
      navigate({ search: searchParams.toString() });
    }
    fetchJournals();
  }, []);

  // Handle search params from the history to populate the selected fields
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const casesPageNoParam = !isAuthorised() ? 1 : Number.parseInt(searchParams.get('casesPageNo') || '1');
    const selectedTabParam = searchParams.get('selectedTab');
    if (selectedTabParam) {
      setSelectedTab(selectedTabParam);
      switch (selectedTabParam) {
        case 'searchByCitation': {
          navigate({ search: searchParams.toString() });
          const citationYear = (searchParams.get('citationYear') || '').toString();
          const citationJournal = (searchParams.get('citationJournal') || '').toString();
          const citationPageNo = (searchParams.get('citationPageNo') || '').toString();
          const citationJournalCat = (searchParams.get('citationJournalCat') || '').toString();
          setSearchCitationYear(citationYear);
          setSearchCitationJournal(citationJournal);
          setSearchCitationPageNo(citationPageNo);
          setSearchCitationJournalCat(citationJournalCat);
          if (citationYear !== '' || citationJournal !== '' || citationPageNo !== '') {
            setPageNo(casesPageNoParam);
            searchCasesByCitation(citationYear, citationJournal, citationPageNo, casesPageNoParam, citationJournalCat);
          }
          break;
        }
        case 'searchByDate': {
          navigate({ search: searchParams.toString() });
          searchCasesByDate();
          setPageNo(casesPageNoParam);
          break;
        }
      }
    }
  }, [location.search]);

  const handleTabChange = (tabId: string) => {
    resetCases();
    setPageNo(1);
    setStartDate(null);
    setEndDate(null);
    searchParams.set('casesPageNo', '1');
    searchParams.set('selectedTab', tabId);
    searchParams.delete('citationYear');
    searchParams.delete('citationJournal');
    searchParams.delete('citationPageNo');
    setSearchCitationYear('');
    setSearchCitationJournal('');
    setSearchCitationPageNo('');
    navigate({ search: searchParams.toString() });
    setSelectedTab(tabId);
  };

  // Function to reset cases selection
  const resetCases = () => {
    setCases([]);
    setTotalPages(1);
    setTotalRecords(0);
  }

  // Open judgment dialog
  const openJudgment = (item: any) => {
    const url = '/cases/judgment?judgmentId=' + item?._id?.$oid;
    window.open(url, '_blank');
  };

  /**
   * Function to handle page change
   * @param {Event} event
   * @param {number} value
   */
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    searchParams.set('casesPageNo', value.toString());
    navigate({ search: searchParams.toString() });
    setPageNo(value);
  };

  const searchCasesByDate = (casesPageNo = pageNo) => {
    let requestBody: any = {
      pageNo: casesPageNo,
      requestedCount: pageSize,
    }
    if (startDate) {
      requestBody['from'] = formatDate(startDate.toString());
    }
    if (endDate) {
      requestBody['to'] = formatDate(endDate.toString());
    }
    setIsCaseSearching(true);
    axios.post(`${process.env.REACT_APP_API_NEST_URL}/case-search/filter-date-es`,
      requestBody, {
      headers: {
        Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
      },
    }).then((response: any) => {
      setIsCaseSearching(false);
      if (response.data) {
        if (response.data?.data.length === 0) {
          toast.warning('No judgments found');
          resetCases();
        } else {
          let fetchedCases = response.data?.data.filter(
            (caseDetail: any) => (caseDetail.id !== null)
          );
          setCases(fetchedCases);
          setTotalPages(response.data?.totalPages);
          setTotalRecords(response.data?.totalRecords);
        }
      }
    })
      .catch((error) => {
        setIsCaseSearching(false);
        if (error.response.status === 401) {
          logout();
        }
        toast.error('Failed to fetch judgments.');
      });
  };

  /**
   * Function for searching cases for the selected citation
   * @param citationYear
   * @param citationJournel
   * @param citationPageNo
   * @param casesPageNo
   */
  const searchCasesByCitation = (citationYear = searchCitationYear, citationJournel = searchCitationJournal, citationPageNo = searchCitationPageNo, casesPageNo = pageNo, citationJournalCat = searchCitationJournalCat) => {
    if (citationYear !== '' || citationJournel !== '' || citationPageNo !== '') {
      setIsCaseSearching(true);
      axios
        .get(
          `${process.env.REACT_APP_API_NEST_URL}/citation-search/case-by-citation`,
          {
            params: {
              citationYear,
              citationJournel,
              citationPageNo,
              citationCategory: citationJournalCat,
              pageNo: casesPageNo,
              pageSize,
            },
            headers: {
              Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
            },
          }
        )
        .then((response: any) => {
          setIsCaseSearching(false);
          if (response.data) {
            if (response.data?.totalPages !== 0 && response.data?.totalPages < casesPageNo) {
              searchParams.set('casesPageNo', '1');
              navigate({ search: searchParams.toString() });
              setPageNo(1);
            } else {
              if (response.data?.data.length === 0) {
                toast.warning('No judgments found for this citation.');
                resetCases();
              } else {
                setCases(response.data?.data);
                setTotalPages(response.data?.totalPages);
                setTotalRecords(response.data?.totalRecords);
              }
            }
          }
        })
        .catch((error) => {
          setIsCaseSearching(false);
          // logout; if unauthorised
          if (error.response.status === 401) {
            logout();
          }
          toast.error('Failed to fetch judgments.');
        });
    }
  };

  const fetchJournals = () => {
    showLoader();
    axios
      .get(
        `${process.env.REACT_APP_API_NEST_URL}/citation-search/journals?isReported=true`,
        {
          headers: {
            Authorization: `Bearer ${decryptString(localStorage.getItem('token'))}`
          },
        }
      )
      .then((response: any) => {
        hideLoader();
        setJournals(response.data?.data);
      })
      .catch((error) => {
        hideLoader();
        // logout; if unauthorised
        if (error.response.status === 401) {
          logout();
        }
        toast.error('Failed to fetch judgments.');
      });
  }

  return (
    <div className='flex flex-col w-full h-full gap-4 relative' key={location.pathname + location.search}>
      <TabList tabs={tabs} activeTab={selectedTab} onTabChange={handleTabChange} />
      {selectedTab === 'searchByCitation' && (
        <div className='flex flex-col gap-4 rounded-container'>
          <div className='flex flex-row justify-between items-center'>
            <div className='flex flex-row justify-start items-center gap-2'>
              <span className='text-2xl font-bold'>
                Search Judgments by Citation
              </span>
            </div>
            <SaveSearchButton />
          </div>
          <div className='flex flex-col w-100 gap-4'>
            <div className='flex flex-row w-100 gap-4'>
              <FormControl fullWidth>
                <TextField size='small'
                  type='number'
                  placeholder='Year'
                  value={searchCitationYear}
                  onChange={(e) => setSearchCitationYear(e.target.value)}
                />
              </FormControl>
              <FormControl fullWidth size='small'>
                <InputLabel id='select-journal'>
                  Journal
                </InputLabel>
                <Select
                  labelId='select-journal'
                  MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                  variant='outlined'
                  defaultValue={''}
                  value={journals?.find((journal: any) => journal.name === searchCitationJournal) || ''}
                  label='Journal'
                  placeholder='ajkdhas'
                  onChange={(e: any) => {
                    setSearchCitationJournal((e.target.value).name);
                  }}
                >
                  {journals.map((item: any, index) => (
                    <MenuItem value={item} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <TextField size='small'
                  type='text'
                  placeholder='Page No.'
                  value={searchCitationPageNo}
                  onChange={(e) => setSearchCitationPageNo(e.target.value)}
                />
              </FormControl>
              <div className='min-w-[9rem] max-w-[10rem]'>
                <Button
                  className='w-full h-full'
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    searchParams.set('citationYear', searchCitationYear.toString());
                    searchParams.set('citationJournal', searchCitationJournal.toString());
                    searchParams.set('citationPageNo', searchCitationPageNo.toString());
                    searchParams.set('casesPageNo', '1');
                    navigate({ search: searchParams.toString() });
                  }}
                >
                  Search
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {selectedTab === 'searchByDate' && (
        <div className='flex flex-col gap-4 rounded-container'>
          <div className='flex flex-row justify-between items-center'>
            <div className='flex flex-row justify-start items-center gap-2'>
              <span className='text-2xl font-bold'>
                Latest Judgments
              </span>
            </div>
            <SaveSearchButton />
          </div>
          <div className="flex flex-row gap-4 w-full items-end">
            <DatePicker
              label="Start Date"
              value={startDate}
              format="YYYY-MM-DD"
              slotProps={{ textField: { size: 'small' } }}
              onChange={handleStartDateChange}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              format="YYYY-MM-DD"
              slotProps={{ textField: { size: 'small' } }}
              onChange={handleEndDateChange}
            />
            <Button
              className='w-32 h-10'
              variant='contained'
              color='primary'
              onClick={() => {
                setPageNo(1);
                searchCasesByDate();
              }}
            >
              Search
            </Button>
          </div>
        </div>
      )}
      <div className='flex flex-col gap-4 w-full rounded-container'>
        <p className='text-2xl font-bold'>Judgments</p>
        <div className='flex flex-col justify-between w-full h-full'>
          <div className={`overflow-auto border w-full rounded-md ${!isAuthorised() ? 'h-[calc(100vh-25.75rem)]' : 'h-[calc(100vh-23.5rem)]'}`}>
            {isCaseSearching ? (<SkeletonLoading isLoading={isCaseSearching} />) : (
              <Table aria-label='simple table' size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <span className='font-bold text-md'>Party Name</span>
                    </TableCell>
                    <TableCell>
                      <span className='font-bold text-md'>Appeal</span>
                    </TableCell>
                    <TableCell>
                      <span className='font-bold text-md'>Reported as</span>
                    </TableCell>
                    <TableCell>
                      <span className='font-bold text-md'>Court</span>
                    </TableCell>
                    <TableCell className='min-w-[10rem]'>
                      <span className='font-bold text-md'>Judgment Date</span>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <span className='font-bold text-md'>Result</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cases.length > 0 ? (
                    cases.map((item: any, index) => (
                      <TableRow key={item._id?.$oid} className='border-b-0 show-actions-on-hover' hover={true}>
                        <TableCell>
                          <div className='flex flex-row items-center gap-1'>
                            <Tooltip title={(item.appeallant || 'N/A') + ' vs ' + (item.respondant || 'N/A')} placement='bottom-start'>
                              <p className='max-w-[25rem] truncate'>
                                {(item.appeallant || 'N/A') + ' vs ' + (item.respondant || 'N/A')}
                              </p>
                            </Tooltip>
                            {
                              (isAuthorised() || index <= 2) && (
                                <Tooltip title={'View Case details'}>
                                  <div className='cursor-pointer action' onClick={(e) => {
                                    openJudgment(item);
                                  }}>
                                    <Launch className='text-primary' sx={{ fontSize: '1.2rem' }} />
                                  </div>
                                </Tooltip>
                              )
                            }
                          </div>
                        </TableCell>
                        <TableCell>
                          <Tooltip placement='bottom-start' title={item.appeal}>
                            <p className='max-w-[18rem] truncate'>
                              {item.appeal || 'N/A'}
                            </p>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Tooltip placement='bottom-start' title={concatenateStrings(item.citationNames) || 'N/A'}>
                            <p className='max-w-[18rem] truncate'>
                              {item.reported ? (
                                concatenateStrings(item.citationNames)
                              ) : ('Unreported')}
                            </p>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Tooltip placement='bottom-start' title={item.court}>
                            <p className='max-w-[18rem] truncate'>
                              {item.court || 'N/A'}
                            </p>
                          </Tooltip>
                        </TableCell>
                        <TableCell >
                          {formatDate(item.judgment_date) || 'N/A'}
                        </TableCell>
                        <TableCell className='min-w-[10rem]'>
                          {item.result || 'N/A'}
                        </TableCell>
                        <TableCell align='right'>
                          <Tooltip title={(!isAuthorised() && index > 2) ? constants.tooltips.upgradePlan : ''} placement='bottom-start'>
                            <span>
                              <Button
                                disabled={!isAuthorised() && index > 2}
                                className='w-[5.7rem] action'
                                variant='contained'
                                size='small'
                                color='primary'
                                onClick={(e) => {
                                  openJudgment(item);
                                }}
                              >
                                View Detail
                              </Button>
                            </span>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))) : (
                    <TableRow>
                      <TableCell colSpan={6} align={'center'}>
                        No Judgments found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </div>
          <div className='flex flex-row justify-end items-center'>
            <div className='mt-4'>
              <RecordCount pageNo={pageNo} pageSize={Number.parseInt(pageSize)} totalRecords={totalRecords} />
            </div>
            <Stack spacing={2} className='flex flex-row mt-4 justify-end'>
              <Tooltip title={(!isAuthorised()) ? constants.tooltips.upgradePlan : ''} placement='left'>
                <span>
                  <Pagination
                    color="primary"
                    page={pageNo}
                    disabled={!isAuthorised()}
                    count={totalPages}
                    siblingCount={0}
                    boundaryCount={1}
                    onChange={handlePageChange}
                    shape={'rounded'}
                  />
                </span>
              </Tooltip>
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cases;